var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").controller("ContatosController", [
    "$rootScope",
    "$scope",
    "$http",
    "$locale",
    "$filter",
    "FormEngageService",
    "ContatoStatusLabel",
    "DateRangePicker",
    function ($rootScope, $scope, $http, $locale, $filter, FormEngageService, ContatoStatusLabel, DateRangePicker) {
        var textArea = document.querySelector("#expandTextArea");
        textArea.addEventListener("keyup", resize);
        textArea.addEventListener("click", resize);
        textArea.addEventListener("change", resize);
        function resize() {
            textArea.style.height = "auto"; //needed when you remove content so we reduce the height
            textArea.style.height = textArea.scrollHeight + "px";
        }
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $scope.showDetailsArrow =
            $rootScope.permissoes.contatos.edit ||
                $rootScope.permissoes.contatos.sendToSegmentation ||
                $rootScope.permissoes.contatos.convert ||
                $rootScope.permissoes.contatos.contatos ||
                $rootScope.permissoes.contatos.sendToIntegration;
        $locale.NUMBER_FORMATS.GROUP_SEP = ".";
        $rootScope.consultarStatusUsuario();
        var _pagina = -100;
        $scope.desativaCheckTodos = false;
        var _campoOrdem = "percAcerto";
        var _ordem = -1;
        var _listaFiltro = "-";
        var _limite = 100;
        var _limiteRegistros = 10002;
        $scope.buscaContatoSeg = false;
        $scope.totalSelecionados = 0;
        var _countDiv = 1;
        $scope.maxRegistrosPlanilha = 10000;
        $scope.nomeBtnAvancar = "AVANÇAR";
        $scope.nomeBtnAvancarContatos = "AVANÇAR";
        $rootScope.showBtnVoltarContatos = false;
        $rootScope.showBtnAvancarContatos = false;
        $scope.passo = 1;
        $scope.semBuscaContatoCad = false;
        $scope.totalCadenciaSelecionadas = 0;
        $scope.totalSegmentacoesSelecionadas = "";
        $scope.nomeSegmentacoesSelecionadas = "";
        $rootScope.popupDetalhesCadencia = false;
        $rootScope.subMenuCadenciaDeContatos = false;
        $scope.semContatosCadencia = false;
        $scope.semContatosSeg = false;
        $scope.popupExportarContatos = false;
        $scope.trocarOrdem = true;
        $scope.salvarQuantidadeContatosMixPanel = null;
        $scope.precisaConsularSegmentacoes = true;
        var _checkCadenciaTodos = false;
        $scope.segmentacaoFiltroContatos = "";
        $scope.nomeSegDuplicarPara = "";
        $("#abrirImageSeta").hide();
        $("#idFundoPopupContatos").hide();
        $("#fundoPopupNovaSegmentacao").hide();
        $scope.filtroStatus = "-";
        $scope.filtroConfiabilidade = "T";
        if ($rootScope.administrativoOuVisao)
            $scope.filtroUsuario = "-";
        else
            $scope.filtroUsuario = $rootScope.Login._id;
        $scope.filtroContatos = false;
        $scope.filtroSegmentacao = "-";
        $scope.filtroContato = "-";
        $scope.buscarContatos = "";
        $scope.buscarHabilitado = false;
        $scope.buscarSegmentacao = "";
        $scope.mostrarAcoes = false;
        $scope.acao = "a";
        $scope.destino = "d";
        $scope.especificacao = "e";
        $scope.importacaoOK = false;
        $scope.qtdContatosImportados = "";
        $rootScope.showBtnAvancarContatos = false;
        $rootScope.viewBtnAvancar = false;
        $scope.listaSegmentacoes = [];
        $scope.listaSegmentacoesComContato = [];
        $scope.listaUsuarios = [];
        $scope.valorSegmentacao = "";
        $rootScope.mostrarMenu = true;
        $scope.popupRemove = false;
        $scope.popupDuplicarPara = false;
        $rootScope.mostrarConsumoCredito = false;
        $rootScope.mostrarImpossibilidadeValidacao = false;
        $rootScope.editarDataDisparo = false;
        $rootScope.editarTemplateDisparo = false;
        $rootScope.btnEditarDataTemplate = false;
        $scope.popupFiltrarCadencias = false;
        $scope.popupNovaSegmentacao = false;
        $rootScope.popupInformativo = false;
        $rootScope.habilitarBtnEditarContato = false;
        $scope.semFiltroContatoCad = false;
        $scope.respostaPos = false;
        $scope.respostaNeu = false;
        $scope.respostaNeg = false;
        $scope.subMenuDeContatos = false;
        $rootScope.subMenuContatosEspecifica = false;
        $scope.mostrarConsumoCredito1 = false;
        $scope.arquivoInvalido = false;
        $scope.arquivoInvalidoTextoEspecial = false;
        $scope.arquivoInvalidoLimiteRegistros = false;
        $rootScope.subMenuContatos = false;
        $scope.semContatos = false;
        $scope.semContatosBusca = false;
        $scope.semContatosFiltro = false;
        $scope.primeiraValidacao = false;
        $scope.segundaValidacao = false;
        $scope.terceiraValidacao = false;
        $scope.applicationInbound = "";
        $scope.applicationPreVendas = "";
        $scope.applicationCRM = "";
        $scope.enviarInbound = false;
        $scope.enviarPreVendas = false;
        $scope.enviarCRM = false;
        $scope.menuNavAberto = true;
        var _contatoDetalhes = null;
        var _editarContato = null;
        var _clickCheckTodos = false;
        var _clickCheckTodosSegmtacoes = false;
        var origemContatoEditar = null;
        $scope.botaoBuscarVisivel = false;
        $scope.contatosInteresseEnviarCrm = [];
        var _matchDoCampos = "";
        var _listaAlfabeto = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
        ];
        var _nomeDoArquivo = "";
        var _extensaoArquivo = "";
        $scope.inpBuscarContatos = "";
        $scope.escondeNomeFiltros = true;
        $scope.avisoSelecao = "";
        $scope.escondeAvisoSelecao = true;
        $scope.descricaoLink = "";
        $scope.todosEstavamSelecionados = false;
        $scope.usuarioLogadoTemPermissaoAdministrador =
            $rootScope.permissoes.tipoUsuario == "A";
        $scope.botaoAvancarCriarContatoDesabilitado = false;
        $("#dvMensagemEmpresa").hide();
        $("#dvMensagemSegmentacao").hide();
        $("#dvMensagemSegmentacaomap").hide();
        $("#dvMensagemNome").hide();
        $("#dvMensagemSobrenome").hide();
        $("#nomedoArquivoExcelX").hide();
        $("#dvMensagemEmail").hide();
        $("#abrirSubMenucontatosSeg").hide();
        $("#dvMensagemNomeSegmentacao").hide();
        $rootScope.redirectContatos();
        setTimeout(function () {
            if ($("#receberam")[0].value == "")
                $("#receberam").candlestick("reset");
            if ($("#abriram")[0].value == "")
                $("#abriram").candlestick("reset");
            if ($("#clicaram")[0].value == "")
                $("#clicaram").candlestick("reset");
            if ($("#responderam")[0].value == "")
                $("#responderam").candlestick("reset");
            $("#receberam").candlestick({
                swipe: false,
                on: "s",
                off: "n",
                default: "",
            });
            $("#abriram").candlestick({
                swipe: false,
                on: "s",
                off: "n",
                default: "",
            });
            $("#clicaram").candlestick({
                swipe: false,
                on: "s",
                off: "n",
                default: "",
            });
            $("#responderam").candlestick({
                swipe: false,
                on: "s",
                off: "n",
                default: "",
                afterSetting: function () {
                    if ($("#responderam")[0].value == "s") {
                        $("#divBtnFiltroRespostas").fadeIn();
                    }
                    else
                        $("#divBtnFiltroRespostas").fadeOut();
                },
            });
        }, 10);
        $scope.consultarContatos = function () {
            var statusSelecionado = "";
            if ($scope.contatoFiltroCad === "M") {
                statusSelecionado = ["M", "SM", "NM", "FM"];
            }
            else {
                statusSelecionado = $scope.contatoFiltroCad
                    ? $scope.contatoFiltroCad
                    : "";
            }
            $scope.initLoader();
            if (_pagina == -100)
                $scope.showBrowserContatos = [];
            _pagina = _pagina + 100;
            $http({
                method: "GET",
                url: "/contatos/consultarContatosAgrupado",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: $scope.usuarioFiltro
                        ? "U"
                        : $rootScope.permissoes.tipoUsuario,
                    buscaTexto: $scope.inpBuscarContatos,
                    skip: _pagina,
                    limit: _limite,
                    ordem: _campoOrdem,
                    ordemAscDesc: _ordem,
                    dataInicio: $scope.dataInicioFiltroContatos
                        ? new Date($scope.dataInicioFiltroContatos.toDateString())
                        : "",
                    dataFim: $scope.dataFinalFiltroContatos
                        ? new Date($scope.dataFinalFiltroContatos.toDateString())
                        : "",
                    dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                        ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                        ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    status: statusSelecionado,
                    objectId: $scope.usuarioFiltro,
                    objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                    semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                    objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                    semCadencia: $scope.cadenciaFiltroSeg == "n/a",
                    alta: $("#cbAltaContatosInput").is(":checked"),
                    media: $("#cbMediaContatosInput").is(":checked"),
                    baixa: $("#cbBaixaContatosInput").is(":checked"),
                    sem: $("#cbSemContatosInput").is(":checked"),
                    receberam: $("#receberam")[0].value,
                    abriram: $("#abriram")[0].value,
                    clicaram: $("#clicaram")[0].value,
                    responderam: $("#responderam")[0].value,
                },
            }).then(function (rConsulta) {
                if (rConsulta.data.length > 0) {
                    $scope.esconderEmptyStates();
                    $rootScope.loaderAtivo = false;
                    $("#pagContatos").removeClass("blur");
                    $("#menuPrincipal").removeClass("blur");
                    rConsulta.data.forEach(function (contato) {
                        $scope.showBrowserContatos.push(contato);
                        contato.txtMais = "mais";
                        contato.controleInfos = 0;
                        contato.conSelecionado = "";
                        contato.mostrarFacebook = false;
                        contato.mostrarLinkedin = false;
                        contato.mostrarGoogle = false;
                        contato.mostrarInstagram = false;
                        contato.mostrarTwitter = false;
                        contato.origemContatoDC = false;
                        contato.origemContatoIL = false;
                        contato.origemContatoIM = false;
                        contato.origemContatoIC = false;
                        contato.origemContatoE = false;
                        contato.origemContatoDL = false;
                        contato.origemContatoMarketing = false;
                        if (contato.facebook != null || contato.facebook != undefined) {
                            contato.mostrarFacebook = true;
                        }
                        else {
                            contato.mostrarFacebook = false;
                        }
                        if (contato.googlePlus != null || contato.googlePlus != undefined) {
                            contato.mostrarGoogle = true;
                        }
                        else {
                            contato.mostrarGoogle = false;
                        }
                        if (contato.twitter != null || contato.twitter != undefined) {
                            contato.mostrarTwitter = true;
                        }
                        else {
                            contato.mostrarTwitter = false;
                        }
                        if (contato.instagram != null || contato.instagram != undefined) {
                            contato.mostrarInstagram = true;
                        }
                        else {
                            contato.mostrarInstagram = false;
                        }
                        if (contato.linkedin != null || contato.linkedin != undefined) {
                            contato.linkedin == "-"
                                ? (contato.mostrarLinkedin = false)
                                : (contato.mostrarLinkedin = true);
                        }
                        else {
                            contato.mostrarLinkedin = false;
                        }
                        if (contato.origem == "Capturas") {
                            contato.origemContatoDC = true;
                            $scope.origemContatoGeralDC = "Descobrir contato";
                        }
                        if (contato.origem == "I") {
                            contato.origemContatoIL = true;
                            $scope.origemContatoGeralLista = "Importação de lista";
                        }
                        if (contato.origem == "M") {
                            contato.origemContatoIM = true;
                            $scope.origemContatoGeralManual = "Importação manual";
                        }
                        if (contato.origem == "C") {
                            contato.origemContatoIC = true;
                            $scope.origemContatoGeralIC = "Extensão";
                        }
                        if (contato.origem == "E") {
                            contato.origemContatoE = true;
                            $scope.origemContatoGeralE = "Extensão";
                        }
                        if (contato.origem == "CH") {
                            contato.origemContatoE = true;
                            $scope.origemContatoGeralE = "Chat";
                        }
                        if (contato.origem == "DL") {
                            contato.origemContatoDL = true;
                            $scope.origemContatoGeralDL = "Engage";
                        }
                        if (contato.origem === "Marketing - Webhook") {
                            contato.origemContatoMarketing = true;
                            $scope.origemContatoGeralMarketing = "Marketing";
                        }
                    });
                    if ($scope.contatoId) {
                        var contato = rConsulta.data.find(function (contato) {
                            return $scope.contatoId.toString() == contato._id.toString();
                        });
                        _contatoDetalhes = contato;
                        $scope.listaSegmentacoesShow = contato.aliasSegmentacoes;
                        $scope.totalSegmentacoesSelecionadas = 0;
                        $("#segmentacaoCheck").prop("checked", false);
                        _clickCheckTodosSegmtacoes = false;
                    }
                    if (_clickCheckTodos) {
                        limparSelecaoContatos();
                    }
                }
                else {
                    if ($scope.showBrowserContatos.length <= 0) {
                        if ($scope.buscarHabilitado) {
                            $scope.semContatosBusca = true;
                            $scope.buscaOuFiltro = "Sua busca";
                            $scope.semContatosFiltro = false;
                            $scope.semContatos = false;
                        }
                        else if ($scope.filtroContatos) {
                            $scope.semContatosFiltro = true;
                            $scope.buscaOuFiltro = "Seu filtro";
                            $scope.semContatosBusca = false;
                            $scope.semContatos = false;
                        }
                        else {
                            $scope.semContatos = true;
                            $scope.semContatosFiltro = false;
                            $scope.semContatosBusca = false;
                        }
                    }
                }
                $scope.consultarTotais();
            });
        };
        $scope.consultarTotais = function () {
            $("#loaderContatos").addClass("loaderTotal");
            $("#totalContatos").addClass("zeraOpacidade");
            $scope.desativaCheckTodos = true;
            $("#idSpanCheckTodos").css("pointer-events", "none");
            $http({
                method: "GET",
                url: "/contatos/consultarContatosTotal",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: $scope.usuarioFiltro
                        ? "U"
                        : $rootScope.permissoes.tipoUsuario,
                    objectId: $scope.usuarioFiltro,
                    totalEspecifico: {
                        tela: "Contatos",
                        tipoDaChave: $scope.usuarioFiltro
                            ? "U"
                            : $rootScope.permissoes.tipoUsuario,
                        objectId: $scope.usuarioFiltro,
                        buscaTexto: $scope.inpBuscarContatos,
                        dataInicio: $scope.dataInicioFiltroContatos
                            ? new Date($scope.dataInicioFiltroContatos.toDateString())
                            : "",
                        dataFim: $scope.dataFinalFiltroContatos
                            ? new Date($scope.dataFinalFiltroContatos.toDateString())
                            : "",
                        dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                            ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                            ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                        status: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                        objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                        semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                        alta: $("#cbAltaContatosInput").is(":checked"),
                        media: $("#cbMediaContatosInput").is(":checked"),
                        baixa: $("#cbBaixaContatosInput").is(":checked"),
                        sem: $("#cbSemContatosInput").is(":checked"),
                        receberam: $("#receberam")[0].value,
                        abriram: $("#abriram")[0].value,
                        clicaram: $("#clicaram")[0].value,
                        responderam: $("#responderam")[0].value,
                    },
                },
            }).then(function (rConsulta) {
                $("#loaderContatos").removeClass("loaderTotal");
                $("#totalContatos").removeClass("zeraOpacidade");
                $scope.desativaCheckTodos = false;
                $("#idSpanCheckTodos").css("pointer-events", "auto");
                if (rConsulta.data.length > 0)
                    $scope.total = rConsulta.data[0].Total;
                else
                    $scope.total = "0";
                $rootScope.loaderAtivo = false;
                $("#pagContatos").removeClass("blur");
                $("#menuPrincipal").removeClass("blur");
            });
        };
        $scope.alterarOrdenacaoContatos = function () {
            $scope.ordenarContatos(_campoOrdem);
            $scope.abrirOrdenarContato();
        };
        $scope.consultarSegmentacoes = function (_nomeNovaSeg) {
            $http({
                method: "GET",
                url: "/segmentacoes/consultarSegmentacoesUsuario",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                },
            }).then(function (rConsultaSegmentacoes) {
                $scope.listaSegmentacoes = rConsultaSegmentacoes.data;
                $scope.listaSegmentacoes.sort(function (a, b) {
                    return a._id.nomeSegmentacao < b._id.nomeSegmentacao
                        ? -1
                        : a._id.nomeSegmentacao > b._id.nomeSegmentacao
                            ? 1
                            : 0;
                });
                if (_nomeNovaSeg) {
                    for (var index = 0; index < $scope.listaSegmentacoes.length; index++) {
                        if (_nomeNovaSeg == $scope.listaSegmentacoes[index].nome) {
                            var _segmentacao = $scope.listaSegmentacoes[index];
                            $scope.valorSegmentacao = _segmentacao._id;
                        }
                    }
                    $scope.selecionarSegmentacaoDuplicarPara(_segmentacao);
                    $scope.abrirSegmentacaoDuplicarPara();
                }
            });
        };
        $scope.consultarUsuarios = function () {
            if ($scope.listaUsuarios.length == 0) {
                if ($rootScope.permissoes.tipoUsuario != "U") {
                    $http({
                        method: "GET",
                        url: "/filtros/usuarios",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                    }).then(function (response) {
                        response.data.forEach(function (time) {
                            time.loginsAtivos.forEach(function (usuario) {
                                $scope.listaUsuarios.push(usuario);
                                if (usuario.fotoPerfil)
                                    usuario.fotoPerfil =
                                        "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                            usuario._id.toString() +
                                            "/" +
                                            usuario.fotoPerfil;
                                else
                                    usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            });
                            $scope.selecionarUsuarioPadrao();
                        });
                        $scope.listaUsuarios.sort(function (a, b) {
                            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        });
                        if ($scope.listaUsuarios.length >= 3)
                            $("#idFiltroUsuariosContatos").css("margin-bottom", "-267px");
                        else if ($scope.listaUsuarios.length == 2)
                            $("#idFiltroUsuariosContatos").css("margin-bottom", "-205px");
                    });
                }
                else {
                    var usuario = {
                        nome: $rootScope.nomeUsuarioLogado,
                        _id: $rootScope.DadosCadastrais.objectIdLogin,
                    };
                    $scope.listaUsuarios.push(usuario);
                    $scope.selecionarUsuarioPadrao();
                }
            }
        };
        $(document).on("mouseup", function (e) {
            var filtro = $("#filtrosContato");
            var data = $(".dr-picker");
            if (filtro.has(e.target).length === 0 &&
                data.has(e.target).length === 0) {
                $("#filtrosContato").hide();
                $("#posicaoContato").hide();
                data.hide();
            }
        });
        $scope.contadorSelecionados = function () {
            $scope.totalSelecionados = $scope.showBrowserContatos.filter(function (obj) {
                return obj.checkSelecionado;
            }).length;
            if ($scope.totalSelecionados > 1) {
                $scope.nomeSelecionado = "selecionados";
                $("#abrirSubMenuIntegracao").addClass("positionSubMenuIntegracao");
                $("#abrirSubMenuIntegracao").removeClass("positionSubMenuIntegracaoEditar");
            }
            else {
                $scope.nomeSelecionado = "selecionado";
                $("#abrirSubMenuIntegracao").addClass("positionSubMenuIntegracaoEditar");
                $("#abrirSubMenuIntegracao").removeClass("positionSubMenuIntegracao");
            }
            if ($scope.totalSelecionados < $scope.showBrowserContatos.length) {
                $("#checkContatos").prop("checked", false);
                _clickCheckTodos = false;
            }
            else if ($scope.totalSelecionados == $scope.showBrowserContatos.length &&
                $scope.total == $scope.totalSelecionados) {
                $("#checkContatos").prop("checked", true);
                _clickCheckTodos = true;
            }
            if (_clickCheckTodos) {
                $scope.totalSelecionados = $scope.total;
            }
            $scope.salvarQuantidadeContatosMixPanel = $scope.totalSelecionados;
            trocaAvisoSelecao("todos");
        };
        $scope.selecionarTodosCheckbox = function () {
            $scope.clickCheckTodos("contatos");
            $("#checkContatos").prop("checked", true);
        };
        $scope.linkAvisoSelecao = function (link) {
            if (link === "Limpar seleção") {
                $scope.clickCheckTodos("contatos");
                limparSelecaoContatos();
            }
            else {
                $("#checkContatos").prop("checked", true);
                $scope.clickCheckTodos("contatos");
            }
        };
        function trocaAvisoSelecao(tipoSelecao) {
            $scope.avisoSelecao =
                $scope.totalSelecionados + " contatos selecionados.";
            $scope.descricaoLink = "Limpar seleção";
            $scope.escondeAvisoSelecao = false;
            if ($scope.totalSelecionados === 1) {
                $scope.avisoSelecao =
                    $scope.totalSelecionados + " contato selecionado.";
            }
            if ($scope.totalSelecionados !== $scope.total) {
                $scope.descricaoLink =
                    "Clique aqui para selecionar todos os " + $scope.total + " contatos";
            }
            if ($scope.todosEstavamSelecionados &&
                $scope.totalSelecionados !== $scope.total) {
                $scope.avisoSelecao =
                    $scope.totalSelecionados +
                        " contatos nesta página estão selecionados.";
                if ($scope.totalSelecionados === 1) {
                    $scope.avisoSelecao =
                        $scope.totalSelecionados +
                            " contato nesta página está selecionado.";
                }
            }
            if ($scope.totalSelecionados === 0) {
                $scope.escondeAvisoSelecao = true;
            }
        }
        function limparSelecaoContatos() {
            if ($scope.showBrowserContatos) {
                $scope.showBrowserContatos.forEach(function (item) {
                    _clickCheckTodos = false;
                    item.checkSelecionado = false;
                    $scope.escondeAvisoSelecao = false;
                    $scope.todosEstavamSelecionados = false;
                });
                $scope.contadorSelecionados();
            }
        }
        $scope.clickCheckTodos = function (pDados) {
            if (pDados == "contatos") {
                $scope.todosEstavamSelecionados = true;
                if (_clickCheckTodos) {
                    $scope.todosEstavamSelecionados = false;
                }
                _clickCheckTodos = !_clickCheckTodos;
                $scope.showBrowserContatos.forEach(function (item) {
                    item.checkSelecionado = _clickCheckTodos;
                });
                $scope.contadorSelecionados();
            }
            if (pDados == "cadencias") {
                _checkCadenciaTodos = !_checkCadenciaTodos;
                $scope.listaCadenciasShow.forEach(function (item) {
                    item.checkCadenciaSelecionado = _checkCadenciaTodos;
                });
                $scope.contadorCadenciasSelecionados();
            }
            if (pDados == "segmentacoes") {
                _clickCheckTodosSegmtacoes = !_clickCheckTodosSegmtacoes;
                $scope.listaSegmentacoesShow.forEach(function (item) {
                    item.checkSelecionado = _clickCheckTodosSegmtacoes;
                });
                $scope.contadorSegmentacoesSelecionados();
            }
        };
        $scope.clickCheck = function () {
            $scope.contadorSelecionados();
        };
        $scope.clickCheckCadencia = function (piDisparos) {
            if (!piDisparos.checkCadenciaSelecionado)
                piDisparos.checkCadenciaSelecionado = false;
            else
                piDisparos.checkCadenciaSelecionado = true;
            $scope.contadorCadenciasSelecionados();
        };
        $scope.contadorCadenciasSelecionados = function () {
            $scope.totalCadenciaSelecionadas = $scope.listaCadenciasShow.filter(function (obj) {
                return obj.checkCadenciaSelecionado;
            }).length;
            if ($scope.totalCadenciaSelecionadas > 1) {
                $scope.nomeBtnRemoverContato = "das cadências";
                $scope.nomeCadenciaSelecionado = "cadências selecionadas";
            }
            else {
                $scope.nomeBtnRemoverContato = "da cadência";
                $scope.nomeCadenciaSelecionado = "cadência selecionada";
            }
            if ($scope.totalCadenciaSelecionadas < $scope.listaCadenciasShow.length) {
                $("#checkCadenciasContatos").prop("checked", false);
                _checkCadenciaTodos = false;
            }
            else if (($scope.totalCadenciaSelecionadas = $scope.listaCadenciasShow.length)) {
                $("#checkCadenciasContatos").prop("checked", true);
                _checkCadenciaTodos = true;
            }
        };
        var _arraySequenciasCadencia = [];
        var _sequenciaCadenciaPopup = 0;
        var _dadosSequenciaCadencia = null;
        $scope.abrirPopupDetalhesCadencia = function (pItemCadencia, pSequencia) {
            _arraySequenciasCadencia = $scope.listaCadenciasShow[0].disparoemails;
            _sequenciaCadenciaPopup = pSequencia;
            _cadenciaSelecionada = pItemCadencia;
            $scope.mostrarDadosPopupSequenciaCadencia();
            $(".dvDetalhes").addClass("blur");
            $rootScope.popupDetalhesCadencia = true;
        };
        $scope.avancarPopupSequenciaContato = function (pAdicionaOuDiminui) {
            if (pAdicionaOuDiminui == "me")
                _sequenciaCadenciaPopup--;
            else if (pAdicionaOuDiminui == "ma")
                _sequenciaCadenciaPopup++;
            $scope.mostrarDadosPopupSequenciaCadencia();
        };
        $scope.mostrarDadosPopupSequenciaCadencia = function () {
            _dadosSequenciaCadencia =
                _arraySequenciasCadencia[_sequenciaCadenciaPopup - 1];
            $scope.sequenciaSelecionadaPopupContato = _sequenciaCadenciaPopup;
            $scope.totalSequenciaSelecionadaPopupContato =
                $scope.listaCadenciasShow[0].disparoemails.length;
            $scope.totalRegistrosPopupContatos = _arraySequenciasCadencia.length;
            $scope.dataAberturaDetalhes = _dadosSequenciaCadencia.dataAbertura;
            $scope.dataEnvioDetalhes = _dadosSequenciaCadencia.dataEnvio;
            $scope.qtdAberturasDetalhes = _dadosSequenciaCadencia.quantidadeAberturas;
            $scope.dataRespostaDetalhes = _dadosSequenciaCadencia.dataResposta;
            $scope.statusEnvioSelecionada = _dadosSequenciaCadencia.statusEnvio;
            $rootScope.editarDataDisparo = false;
            $rootScope.editarTemplateDisparo = false;
            $rootScope.btnEditarDataTemplate = false;
            if (_arraySequenciasCadencia.statusResposta == "N")
                $scope.ativarClassificacaoNao();
            else if (_arraySequenciasCadencia.statusResposta == "P")
                $scope.ativarClassificacaoSim();
            else
                $scope.ativarClassificacaoNeutra();
        };
        $scope.alterarDataDisaro = function () {
            $rootScope.editarDataDisparo = true;
            $rootScope.btnEditarDataTemplate = true;
        };
        $scope.alterarTemplateDisaro = function () {
            $rootScope.editarTemplateDisparo = true;
            $rootScope.btnEditarDataTemplate = true;
        };
        $scope.editarDisparoFuturo = function () {
            $rootScope.editarDataDisparo = false;
            $rootScope.editarTemplateDisparo = false;
            $rootScope.btnEditarDataTemplate = false;
        };
        $scope.fecharPopupDetalhesCadencia = function () {
            $(".dvDetalhes").removeClass("blur");
            $rootScope.popupDetalhesCadencia = false;
        };
        $scope.ativarClassificacaoSim = function () {
            $("#bolinhaSim")
                .removeClass("corImgClasResp")
                .addClass("corImgOriginalResp");
            $("#bolinhaNeutra")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
            $("#bolinhaNao")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
        };
        $scope.ativarClassificacaoNeutra = function () {
            $("#bolinhaNeutra")
                .removeClass("corImgClasResp")
                .addClass("corImgOriginalResp");
            $("#bolinhaSim")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
            $("#bolinhaNao")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
        };
        $scope.ativarClassificacaoNao = function () {
            $("#bolinhaNao")
                .removeClass("corImgClasResp")
                .addClass("corImgOriginalResp");
            $("#bolinhaNeutra")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
            $("#bolinhaSim")
                .removeClass("corImgOriginalResp")
                .addClass("corImgClasResp");
        };
        $scope.clickAll = function (pCadenciaContato) {
            $scope.ItemContato.forEach(function (item) {
                item.check = $("#selectAll").is(":checked");
            });
        };
        $scope.excluirContato = function (pDados) {
            $scope.fecharSubMenu();
            $scope.popupRemove = true;
            $scope.digiteExcluir = "";
            $("#idFundoPopupContatos").fadeIn();
            if (pDados == "G") {
                var qtdSelecionadosExcluir = $scope.showBrowserContatos.filter(function (obj) {
                    return obj.checkSelecionado;
                }).length;
                if (_clickCheckTodos) {
                    $scope.qtdSelecionadosExcluir = $scope.total;
                }
                else {
                    $scope.qtdSelecionadosExcluir = qtdSelecionadosExcluir;
                }
                if (qtdSelecionadosExcluir == "1")
                    $scope.txtSelecionadoExlcuir = "";
                else
                    $scope.txtSelecionadoExlcuir = "s";
            }
            else if (pDados == "E") {
                $scope.qtdSelecionadosExcluir = 1;
                $scope.txtSelecionadoExlcuir = "";
                $("#abrirSubMenuContatosEspecifico").hide();
            }
            setTimeout(function () {
                $("#idInptExcluir").focus();
            }, 90);
        };
        /**
         * Valida o estado do atributo disable para o input de email de acordo com as informações do contato
         * @param {object} contato objeto contendo as informações do contato
         */
        $scope.validarEmailInput = function (contato) {
            if (contato.status === ContatoStatusLabel.DOMINIO_BLOQUEADO) {
                $scope.emailInput = {
                    disable: true,
                    message: "Esse contato está na lista de restrição e portanto o e-mail não pode ser alterado.",
                };
                return;
            }
            contato.aliasDisparosemails.forEach(function (envio) {
                $scope.emailInput = {
                    disable: envio.statusEnvio === "E",
                    message: envio.statusEnvio === "E"
                        ? "Esse contato já foi abordado e portanto o e-mail não pode ser alterado."
                        : "",
                };
            });
        };
        $scope.editarContato = function (pDados, editarContatoGeral) {
            origemContatoEditar = null;
            $scope.tipoAdicionar = "m";
            $scope.tituloAdicionar = "Editar contato";
            $scope.descricaoAdicionar = "Edite os dados do contato";
            $scope.contatoIncluidoOuEditado = "Contato editado";
            $scope.criacaoOuEdicao = "Edição";
            _countDiv = 3;
            $("#formNovoContatoManual").fadeIn();
            $("#voltarCriacaoManual").hide();
            $("#passo1").show();
            $("#passo2").hide();
            $("#passo3").hide();
            $("#dvPassoPassoGeral2").hide();
            $("#dvPassoPassoGeral3").show();
            $rootScope.habilitarBtnEditarContato = true;
            $rootScope.viewBtnAvancar = false;
            $scope.nomeBtnAvancar = "FECHAR";
            $scope.nomeBotaoAvancarCriacao = "AVANÇAR";
            $scope.mostrarBotaoAdicionarNovoContato = false;
            $("#dvTraco3")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanFinalizacaoManual")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            if (pDados == "G") {
                _editarContato = editarContatoGeral;
                $scope.editarContatoDetalhe = false;
                $scope.editarContatoGeral = true;
                $scope.validarEmailInput(editarContatoGeral);
                origemContatoEditar = editarContatoGeral.origem;
                $scope.nomeNovoContato = editarContatoGeral.nome;
                $scope.sobrenomeNovoContato = editarContatoGeral.sobrenome;
                $scope.emailNovoContato = editarContatoGeral.email;
                $scope.empresaNovoContato = editarContatoGeral.aliasEmpresas[0].empresa;
                $scope.cargoNovoContato = editarContatoGeral.cargo;
                $scope.notas = editarContatoGeral.notas;
                $scope.departamentoNovoContato = editarContatoGeral.departamento;
                $scope.telefoneNovoContato = editarContatoGeral.telefone;
                $scope.linkedinNovoContato = editarContatoGeral.linkedin;
                $scope.twitterNovoContato = editarContatoGeral.twitter;
                $scope.facebookNovoContato = editarContatoGeral.facebook;
                $scope.instagramNovoContato = editarContatoGeral.instagram;
                $scope.dominioEmpresaNovoContato =
                    editarContatoGeral.aliasEmpresas[0].dominio;
                $scope.porteEmpresaNovoContato =
                    editarContatoGeral.aliasEmpresas[0].porte;
                $scope.segmentoEmpresaNovoContato =
                    editarContatoGeral.aliasEmpresas[0].segmento;
                $scope.cidadeEmpresaNovoContato =
                    editarContatoGeral.aliasEmpresas[0].cidade;
                $scope.estadoEmpresaNovoContato =
                    editarContatoGeral.aliasEmpresas[0].estado;
                $scope.telefonesNovaEmpresa = editarContatoGeral.aliasEmpresas[0]
                    .telefones
                    ? editarContatoGeral.aliasEmpresas[0].telefones
                    : [];
                $scope.empresaDoBancoFora = editarContatoGeral.aliasEmpresas[0].empresa;
            }
            else if (pDados == "E") {
                $scope.editarContatoDetalhe = true;
                $scope.editarContatoGeral = false;
                $scope.validarEmailInput(_contatoDetalhes);
                origemContatoEditar = _contatoDetalhes.origem;
                $scope.nomeNovoContato = _contatoDetalhes.nome;
                $scope.sobrenomeNovoContato = _contatoDetalhes.sobrenome;
                $scope.emailNovoContato = _contatoDetalhes.email;
                $scope.empresaNovoContato = _contatoDetalhes.aliasEmpresas[0].empresa;
                $scope.cargoNovoContato = _contatoDetalhes.cargo;
                $scope.notas = _contatoDetalhes.notas;
                $scope.departamentoNovoContato = _contatoDetalhes.departamento;
                $scope.telefoneNovoContato = _contatoDetalhes.telefone;
                $scope.linkedinNovoContato = _contatoDetalhes.linkedin;
                $scope.twitterNovoContato = _contatoDetalhes.twitter;
                $scope.facebookNovoContato = _contatoDetalhes.facebook;
                $scope.instagramNovoContato = _contatoDetalhes.instagram;
                $scope.dominioEmpresaNovoContato =
                    _contatoDetalhes.aliasEmpresas[0].dominio;
                $scope.porteEmpresaNovoContato =
                    _contatoDetalhes.aliasEmpresas[0].porte;
                $scope.segmentoEmpresaNovoContato =
                    _contatoDetalhes.aliasEmpresas[0].segmento;
                $scope.cidadeEmpresaNovoContato =
                    _contatoDetalhes.aliasEmpresas[0].cidade;
                $scope.estadoEmpresaNovoContato =
                    _contatoDetalhes.aliasEmpresas[0].estado;
                $scope.telefonesNovaEmpresa = _contatoDetalhes.aliasEmpresas[0]
                    .telefones
                    ? _contatoDetalhes.aliasEmpresas[0].telefones
                    : [];
                $scope.criacaoOuEdicao = "Edição";
            }
        };
        $scope.fecharPopupRemover = function (pFechar) {
            if (pFechar) {
                var contatos = [];
                var nomeContatosArray = [];
                mixpanel.track("Contatos | Excluiu contatos", {
                    Local_IP: $rootScope.ip,
                });
                if (_contatoDetalhes) {
                    contatos.push(_contatoDetalhes);
                    nomeContatosArray.push(_contatoDetalhes.nomeCompleto);
                }
                else {
                    $scope.showBrowserContatos
                        .filter(function (obj) {
                        return obj.checkSelecionado;
                    })
                        .forEach(function (contato) {
                        contatos.push(contato);
                        nomeContatosArray.push(contato.nomeCompleto);
                    });
                }
                $scope.initLoader();
                $http({
                    method: "POST",
                    url: "/contatos/removerContato",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        todos: _clickCheckTodos,
                        tipoDaChave: $scope.usuarioFiltro
                            ? "U"
                            : $rootScope.permissoes.tipoUsuario,
                        objectId: $scope.usuarioFiltro,
                        objectIdContato: contatos.map(function (m) {
                            return m._id;
                        }),
                        buscaTexto: $scope.inpBuscarContatos,
                        dataInicio: $scope.dataInicioFiltroContatos
                            ? new Date($scope.dataInicioFiltroContatos.toDateString())
                            : "",
                        dataFim: $scope.dataFinalFiltroContatos
                            ? new Date($scope.dataFinalFiltroContatos.toDateString())
                            : "",
                        dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                            ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                            ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        status: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                        objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                        semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                        objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                        alta: $("#cbAltaContatosInput").is(":checked"),
                        media: $("#cbMediaContatosInput").is(":checked"),
                        baixa: $("#cbBaixaContatosInput").is(":checked"),
                        sem: $("#cbSemContatosInput").is(":checked"),
                        receberam: $("#receberam")[0].value,
                        abriram: $("#abriram")[0].value,
                        clicaram: $("#clicaram")[0].value,
                        responderam: $("#responderam")[0].value,
                    },
                }).then(function () {
                    $scope.totalSelecionados = 0;
                });
                setTimeout(function () {
                    _pagina = -100;
                    $scope.removerFiltros();
                    $scope.zerarCamposOrdenacao();
                }, 650);
            }
            $scope.popupRemove = false;
            $("#menuPrincipal").removeClass("blur");
            $("#pagContatos").removeClass("blur");
            $("#idFundoPopupContatos").fadeOut();
            if (_contatoDetalhes && pFechar) {
                $scope.fecharDetalhes();
            }
        };
        $(document).mouseup(function (e) {
            var subMenu = $("#abrirSubMenucontatosSeg");
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $scope.salvarVisitante = function () {
            $rootScope.popupSalvarVisitantes = true;
            $("#visitantesbox").addClass("blur");
            $("#menuPrincipal").addClass("blur");
            $("#sub-navbar").addClass("blur");
        };
        $scope.abrirFiltrar = function () {
            if ($rootScope.administrativoOuVisao) {
                $scope.usuarioFiltro = "";
            }
            else {
                $scope.usuarioFiltro = $rootScope.DadosCadastrais.objectIdLogin;
            }
            if (!$scope.listaUsuarios.length)
                $scope.consultarUsuarios();
            $("#filtrosContato").show();
            $scope.popupFiltrar = true;
            setTimeout(function () {
                if ($("#receberam")[0].value == "")
                    $("#receberam").candlestick("reset");
                if ($("#abriram")[0].value == "")
                    $("#abriram").candlestick("reset");
                if ($("#clicaram")[0].value == "")
                    $("#clicaram").candlestick("reset");
                if ($("#responderam")[0].value == "")
                    $("#responderam").candlestick("reset");
                $("#receberam").candlestick({
                    swipe: false,
                    on: "s",
                    off: "n",
                    default: "",
                });
                $("#abriram").candlestick({
                    swipe: false,
                    on: "s",
                    off: "n",
                    default: "",
                });
                $("#clicaram").candlestick({
                    swipe: false,
                    on: "s",
                    off: "n",
                    default: "",
                });
                $("#responderam").candlestick({
                    swipe: false,
                    on: "s",
                    off: "n",
                    default: "",
                    afterSetting: function () {
                        if ($("#responderam")[0].value == "s") {
                            $("#divBtnFiltroRespostas").fadeIn();
                        }
                        else
                            $("#divBtnFiltroRespostas").fadeOut();
                    },
                });
            }, 10);
        };
        $scope.consultarSegmentacoesComContatos = function () {
            if ($scope.precisaConsularSegmentacoes) {
                $scope.loaderSegmentacoesFiltro = true;
                var contatoSelecionado = "";
                try {
                    contatoSelecionado = $scope.usuarioFiltro;
                }
                catch (e) {
                    contatoSelecionado = "";
                }
                $scope.listaSegmentacoesComContato = [];
                $http({
                    method: "GET",
                    url: "/segmentacoes/consultarSegmentacaoAgrupandoContatos",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        tipoDaChave: contatoSelecionado != ""
                            ? "U"
                            : $rootScope.permissoes.tipoUsuario,
                        objectId: contatoSelecionado,
                        integracaoSite: true,
                    },
                }).then(function (rConsultaSegmentacoes) {
                    $scope.listaSegmentacoesComContato = rConsultaSegmentacoes.data;
                    $scope.listaSegmentacoesComContato.sort(function (a, b) {
                        return a._id.nomeSegmentacao < b._id.nomeSegmentacao
                            ? -1
                            : a._id.nomeSegmentacao > b._id.nomeSegmentacao
                                ? 1
                                : 0;
                    });
                    $scope.loaderSegmentacoesFiltro = false;
                    $scope.precisaConsularSegmentacoes = false;
                    if ($scope.listaSegmentacoesComContato.length >= 3)
                        $("#idFiltroSegmentacao")
                            .css("height", "217px")
                            .css("margin-bottom", "-217px");
                    else {
                        var tamanhoAlterado = 89 + $scope.listaSegmentacoesComContato.length * 42;
                        $("#idFiltroSegmentacao")
                            .css("height", tamanhoAlterado + "px")
                            .css("margin-bottom", "-" + tamanhoAlterado + "px");
                    }
                });
            }
        };
        $scope.marcarFiltrarRespostas = function (pTipo) {
            if (pTipo == "p") {
                $scope.respostaPos = !$scope.respostaPos;
                if ($scope.respostaPos) {
                    $("#imgBttnFiltrarRespostasPos").attr("src", "".concat($rootScope.bucket, "/positiva-i.png"));
                    $(".bttnRespostasPosOut").addClass("bttnRespostasPosIn");
                }
                else {
                    $("#imgBttnFiltrarRespostasPos").attr("src", "".concat($rootScope.bucket, "/positiva-o.png"));
                    $(".bttnRespostasPosOut").removeClass("bttnRespostasPosIn");
                }
            }
            else if (pTipo == "e") {
                $scope.respostaNeu = !$scope.respostaNeu;
                if ($scope.respostaNeu) {
                    $("#imgBttnFiltrarRespostasNeu").attr("src", "".concat($rootScope.bucket, "/neutra-i.png"));
                    $(".bttnRespostasNeuOut").addClass("bttnRespostasNeuIn");
                }
                else {
                    $("#imgBttnFiltrarRespostasNeu").attr("src", "".concat($rootScope.bucket, "/neutra-o.png"));
                    $(".bttnRespostasNeuOut").removeClass("bttnRespostasNeuIn");
                }
            }
            if (pTipo == "n") {
                $scope.respostaNeg = !$scope.respostaNeg;
                if ($scope.respostaNeg) {
                    $("#imgBttnFiltrarRespostasNeg").attr("src", "".concat($rootScope.bucket, "/negativa-i.png"));
                    $(".bttnRespostasNegOut").addClass("bttnRespostasNegIn");
                }
                else {
                    $("#imgBttnFiltrarRespostasNeg").attr("src", "".concat($rootScope.bucket, "/negativa-o.png"));
                    $(".bttnRespostasNegOut").removeClass("bttnRespostasNegIn");
                }
            }
        };
        $scope.fecharPopupFiltrar = function () {
            $scope.popupFiltrar = false;
            $("#idPopupFiltrar").hide();
            $("#menuPrincipal").removeClass("blur");
            $("#pagContatos").removeClass("blur");
        };
        $scope.filtrar = function () {
            $scope.botaoBuscarVisivel = false;
            limparSelecaoContatos();
            $("#menuPrincipal").addClass("blur");
            $("#pagContatos").addClass("blur");
            $scope.totalSelecionados = 0;
            $scope.buscarContatos = "";
            $scope.inpBuscarContatos = "";
            $scope.filtroContatos = true;
            $("#checkContatos").prop("checked", false);
            _clickCheckTodos = false;
            _pagina = -100;
            $scope.consultarContatos();
            $scope.totalSelecionados = 0;
            $scope.popupFiltrar = false;
            $scope.semContatosFiltro = false;
            $scope.semContatosBusca = false;
            $("#idPopupFiltrar").hide();
            if ($("#sltDataFiltroTelaContatos")[0].value == "" &&
                ($scope.contatoFiltroCad == null ||
                    $scope.contatoFiltroCad == undefined) &&
                ($scope.usuarioFiltro == "" ||
                    (!$rootScope.administrativoOuVisao &&
                        $scope.usuarioFiltro ==
                            $rootScope.DadosCadastrais.objectIdLogin)) &&
                ($scope.segmentacaoFiltro == null ||
                    $scope.segmentacaoFiltro == undefined) &&
                ($scope.cadenciaFiltroSeg == null ||
                    $scope.cadenciaFiltroSeg == undefined) &&
                $("#sltDataFiltroTelaContatosDataSegmentacao")[0].value == "" &&
                $("#sltDataFiltroTelaContatosDataEnvioProgramacao")[0].value == "" &&
                !$("#cbAltaContatosInput").is(":checked") &&
                !$("#cbMediaContatosInput").is(":checked") &&
                !$("#cbBaixaContatosInput").is(":checked") &&
                !$("#cbSemContatosInput").is(":checked") &&
                $("#abriram")[0].value == "" &&
                $("#clicaram")[0].value == "" &&
                $("#receberam")[0].value == "" &&
                $("#responderam")[0].value == "") {
                $scope.filtroHabilitado = false;
                $("#idBtnFiltroContatos")
                    .removeClass()
                    .addClass("btnVoltarPadroNovoFiltro");
                $("#idImgFiltrarContatos").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
            }
            else {
                $scope.filtroHabilitado = true;
                $("#idBtnFiltroContatos")
                    .removeClass()
                    .addClass("btnFiltroTempNovoHabilitado");
                $("#idImgFiltrarContatos").attr("src", "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
            }
            $scope.montarNomeFiltros();
        };
        $scope.clickCheckSegmentacao = function (iSegmentacao) {
            if (!iSegmentacao.checkSelecionado) {
                iSegmentacao.checkSelecionado = false;
                _clickCheckTodosSegmtacoes = false;
            }
            else {
                iSegmentacao.checkSelecionado = true;
            }
            $scope.contadorSegmentacoesSelecionados();
        };
        $scope.contadorSegmentacoesSelecionados = function () {
            $scope.totalSegmentacoesSelecionadas =
                $scope.listaSegmentacoesShow.filter(function (obj) {
                    return obj.checkSelecionado;
                }).length;
            if ($scope.totalSegmentacoesSelecionadas > 1) {
                $scope.nomeSegmentacoesSelecionadas = "segmentações selecionadas";
                $scope.removercontatoSegmentacao =
                    "contatos das segmentações selecionadas";
                $scope.essasSegmentacoes = "essas segmentações";
                $scope.dessasSegmentacoes = "dessas segmentações";
                $("#abrirSubMenucontatosSeg").addClass("subMenuCadenciasContatosSeg");
                $("#abrirSubMenucontatosSeg").removeClass("subMenuCadenciasContatosSegSemPlural");
            }
            else if ($scope.totalSegmentacoesSelecionadas == 1) {
                $scope.nomeSegmentacoesSelecionadas = "segmentação selecionada";
                $scope.removercontatoSegmentacao = "contato da segmentação selecionada";
                $scope.essasSegmentacoes = "essa segmentação";
                $scope.dessasSegmentacoes = "dessa segmentação";
                $("#abrirSubMenucontatosSeg").removeClass("subMenuCadenciasContatosSeg");
                $("#abrirSubMenucontatosSeg").addClass("subMenuCadenciasContatosSegSemPlural");
            }
            else {
                $scope.nomeSegmentacoesSelecionadas = "";
                $scope.totalSegmentacoesSelecionadas = "";
            }
            if ($scope.totalSegmentacoesSelecionadas <
                $scope.listaSegmentacoesShow.length) {
                $("#segmentacaoCheck").prop("checked", false);
                _clickCheckTodosSegmtacoes = false;
            }
            else if (($scope.totalSegmentacoesSelecionadas =
                $scope.listaSegmentacoesShow.length)) {
                $("#segmentacaoCheck").prop("checked", true);
                _clickCheckTodosSegmtacoes = true;
            }
        };
        $scope.detalhesContato = function (pItemContato) {
            if (!$rootScope.permissoes.contatos.details) {
                return;
            }
            _contatoDetalhes = null;
            $scope.limparDadosContato();
            $scope.nomeContatoShow = pItemContato.nomeCompleto;
            _contatoDetalhes = pItemContato;
            $scope.nome = _contatoDetalhes.nome;
            $scope.contatoId = _contatoDetalhes._id;
            $scope.sobrenome = _contatoDetalhes.sobrenome;
            $scope.email = _contatoDetalhes.email;
            $scope.statusSigle = _contatoDetalhes.status_sigle;
            $scope.cargo = _contatoDetalhes.cargo;
            $scope.contatoSalvoPor =
                _contatoDetalhes.aliasDadoscadastrais[0].nome +
                    " " +
                    _contatoDetalhes.aliasDadoscadastrais[0].sobrenome;
            $scope.dataInclusaoContato = getTimestamp(_contatoDetalhes._id);
            $scope.emailContatoSelecionado = _contatoDetalhes.email;
            $scope.cargoContatoSelecionado = _contatoDetalhes.cargo;
            $scope.departamentoContatoSelecionado = _contatoDetalhes.departamento;
            $scope.notas = _contatoDetalhes.notas;
            $scope.urlPublicaRamperMarketing =
                _contatoDetalhes.urlPublicaRamperMarketing || "";
            try {
                $scope.emailEmpresaContatoSelecionado =
                    _contatoDetalhes.aliasEmpresas[0].email[0];
            }
            catch (e) {
                $scope.emailEmpresaContatoSelecionado = "";
            }
            $scope.temLista = true;
            try {
                $scope.nomeDaLista = _contatoDetalhes.aliasListas[0].nome;
            }
            catch (e) {
                $scope.nomeDaLista = "";
                $scope.temLista = false;
            }
            try {
                $scope.linkDaLista =
                    _contatoDetalhes.aliasListas[0].linkDownloadListaOriginal;
            }
            catch (e) {
                $scope.linkDaLista = "";
                $scope.temLista = false;
            }
            try {
                $scope.parametrosDaLista = _contatoDetalhes.aliasListas[0].parametros
                    .validarEmailNoRamper
                    ? ", validar e-mails"
                    : _contatoDetalhes.aliasListas[0].parametros.bloquearDisparosDoRamper
                        ? ", bloquear disparos"
                        : _contatoDetalhes.aliasListas[0].parametros.descobrirEmailNoRamper
                            ? ", descobrir e-mails"
                            : ", não validar, não bloquear e não descobrir e-mails";
                $scope.parametrosDaLista =
                    " " +
                        $scope.parametrosDaLista.substring(2, $scope.parametrosDaLista.length);
            }
            catch (e) {
                $scope.parametrosDaLista = "";
                $scope.temLista = false;
            }
            try {
                $scope.dadosForm = FormEngageService.getDadosForm(_contatoDetalhes.dadosForm);
            }
            catch (e) {
                $scope.dadosForm = null;
            }
            try {
                $scope.outrosDadosForm = FormEngageService.getOutrosDadosForm(_contatoDetalhes.dadosForm);
                $("#outrosDadosForm").collapse("hide");
                $(".setaOutrasInformacoes").removeClass("setaRotate");
            }
            catch (e) {
                $scope.outrosDadosForm = null;
            }
            $scope.temLista = $scope.temLista && _contatoDetalhes.origemContatoIL;
            $scope.siteContatoSelecionado = _contatoDetalhes.aliasEmpresas[0].dominio;
            $scope.telefoneEmpresaContatoSelecionado = _contatoDetalhes
                .aliasEmpresas[0].telefones
                ? _contatoDetalhes.aliasEmpresas[0].telefones[0]
                : "";
            if (_contatoDetalhes.telefone && _contatoDetalhes.telefone != "")
                $scope.telefone = _contatoDetalhes.telefone;
            if (!_contatoDetalhes.facebook ||
                _contatoDetalhes.facebook == "" ||
                _contatoDetalhes.facebook == "-") {
                $("#imgFacebookGr").hide();
                $("#imgFacebookWr").show();
            }
            else {
                $("#imgFacebookGr").show();
                $("#imgFacebookWr").hide();
                $("#imgFacebookGr").attr("href", "https://" + _contatoDetalhes.facebook.replace("https://", ""));
            }
            if (!_contatoDetalhes.twitter ||
                _contatoDetalhes.twitter == "" ||
                _contatoDetalhes.twitter == "-") {
                $("#imgTwiterGr").hide();
                $("#imgTwiterWr").show();
            }
            else {
                $("#imgTwiterGr").show();
                $("#imgTwiterWr").hide();
                $("#imgTwiterGr").attr("href", "https://" + _contatoDetalhes.twitter.replace("https://", ""));
            }
            if (!_contatoDetalhes.linkedin ||
                _contatoDetalhes.linkedin == "" ||
                _contatoDetalhes.linkedin == "-") {
                $("#imgLinkedinGr").hide();
                $("#imgLinkedinWr").show();
            }
            else {
                $("#imgLinkedinGr").show();
                $("#imgLinkedinWr").hide();
                $("#imgLinkedinGr").attr("href", "https://" + _contatoDetalhes.linkedin.replace("https://", ""));
            }
            if (!_contatoDetalhes.googlePlus ||
                _contatoDetalhes.googlePlus == "" ||
                _contatoDetalhes.googlePlus == "-") {
                $("#imgGoogleGr").hide();
                $("#imgGoogleWr").show();
            }
            else {
                $("#imgGoogleGr").show();
                $("#imgGoogleWr").hide();
                $("#imgGoogleGr").attr("href", "https://" + _contatoDetalhes.googlePlus.replace("https://", ""));
            }
            if (!_contatoDetalhes.instagram ||
                _contatoDetalhes.instagram == "" ||
                _contatoDetalhes.instagram == "-") {
                $("#imgInstagramGr").hide();
                $("#imgInstagramWr").show();
            }
            else {
                $("#imgInstagramGr").show();
                $("#imgInstagramWr").hide();
                $("#imgInstagramGr").attr("href", "https://" + _contatoDetalhes.instagram.replace("https://", ""));
            }
            if (_contatoDetalhes.origem == "Capturas")
                $scope.origemContato = "Descobrir contato";
            else if (_contatoDetalhes.origem == "I")
                $scope.origemContato = "Importação de lista";
            else if (_contatoDetalhes.origem == "M")
                $scope.origemContato = "Inclusão manual";
            else if (_contatoDetalhes.origem == "C")
                $scope.origemContato = "Extensão";
            else if (_contatoDetalhes.origem == "E")
                $scope.origemContato = "Inteligência comercial da Ramper";
            else if (_contatoDetalhes.origem == "CH")
                $scope.origemContato = "Chat";
            else if (_contatoDetalhes.origem == "DL")
                $scope.origemContato = "Engage";
            else if (_contatoDetalhes.origem === "Marketing - Webhook")
                $scope.origemContato = "Marketing";
            $scope.produto = getProdutoOrigemContato($scope.origemContato);
            $scope.departamento = _contatoDetalhes.departamento;
            $scope.empresa = _contatoDetalhes.aliasEmpresas[0].empresa;
            $scope.segmento = _contatoDetalhes.aliasEmpresas[0].segmento;
            $scope.porte = _contatoDetalhes.aliasEmpresas[0].porte;
            $scope.pais = "";
            $scope.estado = _contatoDetalhes.aliasEmpresas[0].estado;
            $scope.cidade = _contatoDetalhes.aliasEmpresas[0].cidade;
            $scope.site = _contatoDetalhes.aliasEmpresas[0].site;
            $scope.anoFundacao = _contatoDetalhes.aliasEmpresas[0].anoFundacao;
            $scope.listaCadenciasShow = _contatoDetalhes.aliasDisparos;
            $scope.listaDisparosShow = _contatoDetalhes.aliasDisparosemails;
            $scope.listaSegmentacoesShow = _contatoDetalhes.aliasSegmentacoes;
            $scope.empresaDoBancoInterno = _contatoDetalhes.aliasEmpresas[0].empresa;
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            $scope.clickMenuDetalhes("d");
            $("#dvDetalhesGeral").fadeIn();
        };
        $scope.retornarUsuarioSegmentacao = function (pSegmentacao) {
            var usuario = "";
            try {
                var dadosCadastrais = _contatoDetalhes.aliasDadoscadastraisSegmentacao.find(function (x) {
                    return x.objectIdLogin == pSegmentacao.objectIdLogin;
                });
                usuario = dadosCadastrais.nome + " " + dadosCadastrais.sobrenome;
            }
            catch (e) {
                usuario = "";
            }
            return usuario;
        };
        $scope.adicionarContato = function () {
            $("#segmentacaoAddContato").val("");
            $scope.tipoAdicionar = "";
            $scope.tituloAdicionar = "Adicionar contatos";
            $scope.descricaoAdicionar =
                "Capture, importe ou crie novos contatos manualmente e valide via Ramper.";
            $("#dvAdicionarContatos").fadeIn();
        };
        $scope.fecharAdicionar = function () {
            $("#dvAdicionarContatos").fadeOut();
        };
        $scope.mostrarValidacaoEmail = function () {
            if (!$rootScope.mostrarConsumoCredito &&
                $rootScope.creditosInicio.creditosLegadoDisponiveis + $rootScope.creditosInicio.creditosDisponiveis <= 0) {
                $rootScope.mostrarImpossibilidadeValidacao = true;
                $("#validarEmail").prop("checked", false);
                return null;
            }
            $rootScope.mostrarConsumoCredito = !$rootScope.mostrarConsumoCredito;
            $rootScope.mostrarImpossibilidadeValidacao = false;
        };
        $scope.mostrarValidacaoEmail1 = function () {
            $("#validarEmail2").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        $scope.mostrarValidacaoEmail2 = function () {
            $("#validarEmail1").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        $scope.mostrarValidacaoEmail3 = function () {
            $("#validarEmail1").prop("checked", false);
            $("#validarEmail2").prop("checked", false);
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        $scope.adiconarContatosNextStep = function (pIouM) {
            $scope.tipoAdicionar = pIouM;
            if (pIouM == "i") {
                if (!$scope.listaSegmentacoes.length ||
                    $scope.listaSegmentacoes.length == 0)
                    $scope.consultarSegmentacoes();
                $scope.carregarDragAndDropExcel();
                $scope.tituloAdicionar = "Importar contatos";
                $scope.descricaoAdicionar =
                    "Insira um arquivo com uma lista de contatos a serem adicionados no Ramper.";
                $scope.passo = 1;
                $("#formNovoContatoLista").fadeIn();
                $scope.abrirTelas();
            }
            else if (pIouM == "m") {
                $scope.tituloAdicionar = "Criar contato manualmente";
                $scope.descricaoAdicionar = "Insira os dados do contato";
                $scope.contatoIncluidoOuEditado = "Contato incluído";
                $scope.criacaoOuEdicao = "Criação";
                $scope.mostrarBotaoAdicionarNovoContato = true;
                _countDiv = 1;
                $scope.showEtapa(_countDiv);
                $("#formNovoContatoManual").fadeIn();
                $("#voltarCriacaoManual").hide();
                $scope.consultarCreditosEAtualizar();
                if (!$scope.listaSegmentacoes.length ||
                    $scope.listaSegmentacoes.length == 0)
                    $scope.consultarSegmentacoes();
            }
        };
        $scope.abrirTelas = function () {
            $("#PassoContatos2").hide();
            $("#PassoContatos3").hide();
            $("#PassoContatos4").hide();
        };
        $scope.atualizarInfosContato = function (pDados) {
            if (pDados == "nome") {
                $("#txtNomeContato").removeClass("inptComErro");
                $("#dvMensagemNome").fadeOut();
            }
            if (pDados == "sobrenome") {
                $("#txtSobrenomeContato").removeClass("inptComErro");
                $("#dvMensagemSobrenome").fadeOut();
            }
            if (pDados == "email") {
                $("#txtEmailContato").removeClass("inptComErro");
                $("#dvMensagemEmail").fadeOut();
            }
            if (pDados == "empresa") {
                $("#txtEmpresaContato").removeClass("inptComErro");
                $("#dvMensagemEmpresa").fadeOut();
            }
        };
        $scope.procurarEmpresaContatoTime = function (pEdicao, pEditouEmail) {
            try {
                var dominio = $scope.emailNovoContato.substring($scope.emailNovoContato.indexOf("@") + 1, $scope.emailNovoContato.length);
                $scope.dominioPopUp = dominio;
                $http({
                    method: "get",
                    url: "/empresas/procurarEmpresaContatoTime",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        dominio: dominio,
                    },
                }).then(function (rEmpresa) {
                    if (rEmpresa.data) {
                        $scope.empresaNovoContato = rEmpresa.data.empresa;
                        $scope.pesquisaEmpresa = rEmpresa.data.empresa;
                    }
                });
            }
            catch (e) { }
        };
        $scope.tirarMsgErroAddContato = function () {
            if ($scope.segmentacaoAddContato != null) {
                $("#segmentacaoAddContato").removeClass("corTextoErro");
                $("#dvMensagemSegmentacao").fadeOut();
            }
        };
        $scope.procurarContatoPorTime = function (pEdicao, pEditouEmail) {
            $http({
                method: "get",
                url: "/contatos/procurarContatoPorTime",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    email: $scope.emailNovoContato,
                },
            }).then(function (rContato) {
                if (rContato.data.length > 0) {
                    $scope.nomeUsuarioDonoContato = rContato.data[0]._id.nome;
                    $scope.botaoAvancarCriarContatoDesabilitado = false;
                    $rootScope.popupInformativo = true;
                    $("#menuPrincipal2").addClass("blur");
                    $(".dvDetalhesDetalhes").addClass("blur");
                    $(".dvPassoPasso").addClass("blur");
                }
                else {
                    _countDiv++;
                    if (pEdicao) {
                        $scope.divFechar();
                    }
                    else {
                        $("#voltarCriacaoManual").hide();
                        $scope.nomeBtnAvancar = "FECHAR";
                        $scope.showEtapa(_countDiv);
                    }
                    $scope.salvarContato(pEdicao, pEditouEmail);
                }
            });
        };
        $scope.consumirCreditoCriacaoManual = function () {
            $http({
                method: "POST",
                url: "/v2/usuario/".concat($rootScope.DadosCadastrais.objectIdLogin, "/debitar-creditos"),
                data: {
                    creditos: 1
                }
            })
                .then(function () {
                $scope.consultarCreditosEAtualizar();
            })
                .catch(function (error) {
                return null;
            });
        };
        $scope.consultarCreditosEAtualizar = function () {
            $http({
                method: "GET",
                url: "/v2/usuario/".concat($rootScope.DadosCadastrais.objectIdLogin, "/creditos-disponiveis")
            })
                .then(function (response) {
                $rootScope.creditosInicio = response.data;
            })
                .catch(function (error) {
                return null;
            });
        };
        $scope.salvarContato = function (pEdicao, pEditouEmail) {
            var dominio = $scope.emailNovoContato.substring($scope.emailNovoContato.indexOf("@") + 1, $scope.emailNovoContato.length);
            pEdicao
                ? mixpanel.track("Contatos | Editou contato", {
                    Local_IP: $rootScope.ip,
                })
                : mixpanel.track("Contatos | Adicionou contato manualmente", {
                    Local_IP: $rootScope.ip,
                });
            $http({
                method: "POST",
                url: "/contatos/salvarContato",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    id: pEdicao
                        ? _editarContato
                            ? _editarContato._id
                            : _contatoDetalhes._id
                        : null,
                    email: $scope.emailNovoContato,
                    empresa: $scope.empresaNovoContato,
                    valido: !$scope.mostrarConsumoCredito,
                    nome: $scope.nomeNovoContato,
                    sobrenome: $scope.sobrenomeNovoContato,
                    cargo: $scope.cargoNovoContato,
                    site: dominio,
                    dominio: dominio,
                    departamento: $scope.departamentoNovoContato,
                    dataInclusao: null,
                    dataAlteracao: null,
                    status: pEditouEmail
                        ? "N"
                        : $rootScope.mostrarConsumoCredito
                            ? "SM"
                            : "N",
                    origem: origemContatoEditar ? origemContatoEditar : "M",
                    percAcerto: pEdicao
                        ? pEditouEmail
                            ? 0
                            : _editarContato
                                ? _editarContato.percAcerto
                                : _contatoDetalhes.percAcerto
                        : 0,
                    telefone: $scope.telefoneNovoContato,
                    linkedin: $scope.linkedinNovoContato,
                    facebook: $scope.facebookNovoContato,
                    twitter: $scope.twitterNovoContato,
                    googlePlus: $scope.googlePlusNovoContato,
                    instagram: $scope.instagramNovoContato,
                    dominioEmpresa: $scope.dominioEmpresaNovoContato,
                    porteEmpresa: $scope.porteEmpresaNovoContato,
                    segmentoEmpresa: $scope.segmentoEmpresaNovoContato,
                    cidadeEmpresa: $scope.cidadeEmpresaNovoContato,
                    estadoEmpresa: $scope.estadoEmpresaNovoContato,
                },
                data: {
                    notas: $scope.notas,
                    telefonesEmpresa: $scope.telefonesNovaEmpresa,
                },
            })
                .then(function (response) {
                $scope.botaoAvancarCriarContatoDesabilitado = false;
                $scope.fecharPopupDuplicarPara(true, response.data._id);
            })
                .catch(function (error) {
                $scope.botaoAvancarCriarContatoDesabilitado = false;
                return null;
            });
            if (!pEdicao && $rootScope.mostrarConsumoCredito) {
                $scope.consumirCreditoCriacaoManual();
            }
        };
        $scope.excluirContatoDaSegmentacao = function () {
            var segmentacoes = $scope.listaSegmentacoesShow
                .filter(function (segmentacao) {
                return segmentacao.checkSelecionado;
            })
                .map(function (segmentacao) {
                return segmentacao._id;
            });
            $http({
                method: "POST",
                url: "/segmentacoesDetalhes/removerSegmentacoesDetalhes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    todos: _clickCheckTodosSegmtacoes,
                    objectIdContato: _contatoDetalhes._id,
                    objectIdSegmentacoes: segmentacoes,
                    buscaTexto: $scope.inpBuscarContatos,
                },
            }).then(function () {
                mixpanel.track("Contatos | Removeu contato da segmentação", {
                    Local_IP: $rootScope.ip,
                });
                $scope.listaSegmentacoesShow = $scope.listaSegmentacoesShow.filter(function (itemSegmentacao) {
                    return !segmentacoes.includes(itemSegmentacao._id);
                });
                $scope.contadorSegmentacoesSelecionados();
                $scope.clickMenuDetalhes("s");
            });
            $scope.fecharPopupRemoverSeg();
        };
        $("#ordemSegmentacoes").on("change", function () {
            if ($("#ordemSegmentacoes")[0].selectedOptions[0].text == "A-Z" ||
                $("#ordemSegmentacoes")[0].selectedOptions[0].text == "Menor data")
                $scope.reverse = false;
            else
                $scope.reverse = true;
            $scope.propertyName = $("#ordemSegmentacoes")[0].selectedOptions[0].value;
            $scope.$apply();
        });
        $scope.popupRemoverSeg = function () {
            $scope.popupRemoveContatoSeg = true;
            $("#idFundoPopupContatos").fadeIn();
            $scope.subMenuContatosSeg = false;
            $("#abrirSubMenucontatosSeg").hide();
        };
        $scope.fecharPopupRemoverSeg = function () {
            $scope.popupRemoveContatoSeg = false;
            $("#idFundoPopupContatos").fadeOut();
        };
        $scope.verificarIntegracao = function () {
            $scope.applicationInbound = "";
            $scope.applicationPreVendas = "";
            $scope.applicationCRM = "";
            $scope.enviarInbound = false;
            $scope.enviarPreVendas = false;
            $scope.enviarCRM = false;
            $http({
                method: "GET",
                url: "/integracoes/consultarIntegracoes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    status: "A",
                },
            }).then(function (rVerificarSeTemIntegracao) {
                if (rVerificarSeTemIntegracao.data &&
                    rVerificarSeTemIntegracao.data.length > 0) {
                    rVerificarSeTemIntegracao.data.forEach(function (iIntegracao) {
                        if (iIntegracao.application == "R") {
                            $scope.integracaoInbound = iIntegracao;
                            $scope.applicationInbound = iIntegracao.nomeAplicao;
                            $scope.enviarInbound = true;
                        }
                        else if (iIntegracao.application == "E") {
                            $scope.integracaoPreVendas = iIntegracao;
                            $scope.applicationPreVendas = iIntegracao.nomeAplicao;
                            $scope.enviarPreVendas = true;
                        }
                        else {
                            $scope.integracaoCRM = iIntegracao;
                            $scope.applicationCRM = iIntegracao.nomeAplicao;
                            $scope.enviarCRM = true;
                        }
                    });
                }
            });
        };
        $scope.getContactsToCRMIntegrate = function () {
            var contacts = [];
            if (_contatoDetalhes) {
                contacts.push(_contatoDetalhes);
            }
            else {
                if ($scope.contatosInteresseEnviarCrm.length > 0) {
                    $scope.contatosInteresseEnviarCrm.forEach(function (contato) {
                        contacts.push(contato);
                    });
                }
                else {
                    $scope.showBrowserContatos
                        .filter(function (obj) {
                        return obj.checkSelecionado;
                    })
                        .forEach(function (contato) {
                        contacts.push(contato);
                    });
                }
            }
            return contacts;
        };
        $scope.funcaonomeNovaSegmentacaoLista = function () {
            $("#PassoContatos1").fadeIn();
            $("#PassoContatos2").hide();
            $("#PassoContatos3").hide();
            $("#PassoContatos4").hide();
            $("#spanImportacao").removeClass("txtVerdePassoPasso");
            $("#spanMapeamento").removeClass("txtVerdePassoPasso");
            $("#spanImportacao").addClass("txtAzulPassoPasso");
            $("#spanconfig").removeClass("txtVerdePassoPasso");
            $("#spanconfig").removeClass("txtAzulPassoPasso");
            $("#spanMapeamento").removeClass("txtAzulPassoPasso");
            $("#spanFinal").removeClass("txtAzulPassoPasso");
            $("#imagemPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#imagemPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#divtraco1").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco1").addClass("dvTracoAzulPassoPasso");
            $("#divtraco2").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco2").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco3").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
            $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco4").removeClass("dvTracoAzulPassoPasso");
            $("#divtraco4").addClass("dvTracoCinzaPassoPasso");
            $scope.nomeBtnAvancarContatos = "AVANÇAR";
            $scope.segmentacaoAddContatomap = "";
            $("#validarEmail1")[0].value == "n";
            $("#validarEmail2")[0].value == "n";
            $scope.mostrarConsumoCredito1 = false;
            $scope.mostrarConsumoCredito2 = false;
            $scope.mostrarConsumoCredito3 = false;
            $scope.nomeNovaSegmentacaoLista = "";
            $("#validarEmail2").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $("#validarEmail1").prop("checked", false);
            $scope.tituloAdicionar = "Adicionar contatos";
            $scope.descricaoAdicionar =
                "Capture, importe ou crie novos contatos manualmente e valide via Ramper";
            $scope.fecharLista();
            $scope.voltarProMenu();
            $scope.passo = 1;
        };
        $scope.avancarTelaFinal = function () {
            processarArquivo();
            $("#PassoContatos1").hide();
            $("#PassoContatos2").hide();
            $("#PassoContatos3").hide();
            $("#PassoContatos4").fadeIn();
            $scope.tituloAdicionar = "Finalização";
            $rootScope.showBtnVoltarContatos = false;
            $scope.descricaoAdicionar = "";
            $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
            $("#divtraco3").addClass("dvTracoVerdePassoPasso");
            $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
            $("#spanconfig").removeClass("txtAzulPassoPasso");
            $("#spanconfig").addClass("txtVerdePassoPasso");
            $("#divtraco4").removeClass("dvTracoCinzaPassoPasso");
            $("#divtraco4").addClass("dvTracoAzulPassoPasso");
            $("#imagemPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#spanFinal").removeClass("txtCinzaPassoPasso");
            $("#spanFinal").addClass("txtAzulPassoPasso");
            $scope.nomeBtnAvancarContatos = "FECHAR";
            $scope.passo++;
        };
        $scope.zerarBloco = function () {
            if ($("#dragEmail").hasClass("colunaVermelha")) {
                $("#dragEmail").addClass("mapeamentocelulas");
                $("#dragEmail").removeClass("colunaVermelha");
            }
            if ($("#dragNome").hasClass("colunaVermelha")) {
                $("#dragNome").addClass("mapeamentocelulas");
                $("#dragNome").removeClass("colunaVermelha");
            }
            if ($("#dragSobrenome").hasClass("colunaVermelha")) {
                $("#dragSobrenome").addClass("mapeamentocelulas");
                $("#dragSobrenome").removeClass("colunaVermelha");
            }
            if ($("#dragEmpresa").hasClass("colunaVermelha")) {
                $("#dragEmpresa").addClass("mapeamentocelulas");
                $("#dragEmpresa").removeClass("colunaVermelha");
            }
            if ($("#dragDominio").hasClass("colunaVermelha")) {
                $("#dragDominio").addClass("mapeamentocelulas");
                $("#dragDominio").removeClass("colunaVermelha");
            }
            var ascii = 65;
            while (ascii <= 122) {
                var componente = document.getElementById("th" + String.fromCharCode(ascii));
                if (componente.childNodes[0]) {
                    reposicionarBloco(componente, document.getElementById(componente.childNodes[0].id));
                }
                ascii++;
                if (ascii == 91)
                    ascii = 97;
            }
        };
        $scope.comecarDeNovoLista = function () {
            $scope.zerarBloco();
            $("#spanImportacao").removeClass("txtVerdePassoPasso");
            $("#spanMapeamento").removeClass("txtVerdePassoPasso");
            $("#spanImportacao").addClass("txtAzulPassoPasso");
            $("#spanconfig").removeClass("txtVerdePassoPasso");
            $("#spanconfig").removeClass("txtAzulPassoPasso");
            $("#spanMapeamento").removeClass("txtAzulPassoPasso");
            $("#spanFinal").removeClass("txtAzulPassoPasso");
            $("#imagemPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#imagemPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#divtraco1").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco1").addClass("dvTracoAzulPassoPasso");
            $("#divtraco2").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco2").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco3").removeClass("dvTracoVerdePassoPasso");
            $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
            $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
            $("#divtraco4").removeClass("dvTracoAzulPassoPasso");
            $("#divtraco4").addClass("dvTracoCinzaPassoPasso");
            $scope.nomeBtnAvancarContatos = "AVANÇAR";
            $scope.segmentacaoAddContatomap = "";
            $("#validarEmail1")[0].value == "n";
            $("#validarEmail2")[0].value == "n";
            $scope.mostrarConsumoCredito1 = false;
            $scope.mostrarConsumoCredito2 = false;
            $scope.mostrarConsumoCredito3 = false;
            $scope.nomeNovaSegmentacaoLista = "";
            $("#validarEmail2").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $("#validarEmail1").prop("checked", false);
            $scope.arquivoInvalido = false;
            $scope.arquivoInvalidoTextoEspecial = false;
            $scope.arquivoInvalidoLimiteRegistros = false;
            $("#bordaImportacaoContatos").removeClass("importacaocontatosPlanVermelha");
            $("#bordaImportacaoContatos").addClass("importacaocontatosPlan");
            $("#segmentacaoAddContatomap").removeClass("corTextoErro");
            $("#dvMensagemSegmentacaomap").fadeOut();
            $scope.tituloAdicionar = "Adicionar contatos";
            $scope.descricaoAdicionar =
                "Capture, importe ou crie novos contatos manualmente e valide via Ramper";
            $scope.passo = 1;
        };
        $scope.fecharImportar = function () {
            $("#formNovoContatoLista").fadeOut();
            $("#PassoContatos1").fadeIn();
            $("#PassoContatos2").hide();
            $("#PassoContatos3").hide();
            $("#PassoContatos4").hide();
            $scope.zerarBloco();
            $scope.comecarDeNovoLista();
            $scope.fecharLista();
            $scope.fecharAdicionar();
        };
        $scope.voltarProMenu = function () {
            $("#formNovoContatoLista").fadeOut();
            $("#PassoContatos1").fadeIn();
            $("#PassoContatos2").hide();
            $("#PassoContatos3").hide();
            $("#PassoContatos4").hide();
            $scope.zerarBloco();
            $scope.comecarDeNovoLista();
            $scope.fecharLista();
        };
        $scope.mostrarValidacaoEmail1 = function () {
            $("#validarEmail2").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $scope.mostrarConsumoCredito2 = false;
            $scope.mostrarConsumoCredito3 = false;
            $scope.mostrarConsumoCredito1 = !$scope.mostrarConsumoCredito1;
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        $scope.mostrarValidacaoEmail2 = function () {
            $("#validarEmail1").prop("checked", false);
            $("#validarEmail3").prop("checked", false);
            $scope.mostrarConsumoCredito2 = !$scope.mostrarConsumoCredito2;
            $scope.mostrarConsumoCredito1 = false;
            $scope.mostrarConsumoCredito3 = false;
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        $scope.mostrarValidacaoEmail3 = function () {
            $("#validarEmail1").prop("checked", false);
            $("#validarEmail2").prop("checked", false);
            $scope.mostrarConsumoCredito3 = !$scope.mostrarConsumoCredito3;
            $scope.mostrarConsumoCredito2 = false;
            $scope.mostrarConsumoCredito1 = false;
            $scope.primeiraValidacao = $("#validarEmail1").is(":checked");
            $scope.segundaValidacao = $("#validarEmail2").is(":checked");
            $scope.terceiraValidacao = $("#validarEmail3").is(":checked");
        };
        /**
         * @description Checa se houve mudanças no valor dos filtros de emails.
         *
         * @param { String } pParams - String passada pelo ng-click.
         *
         * @returns { Object } Marca como ON o campo Receberam e-mail caso outro filtro dos emails for marcado. Caso o campo Receberam e-mail seja marcado como OFF, marca os outros campos como OFF também.
         */
        $scope.alteraStatusCandlestick = function (pParams) {
            switch (pParams) {
                case "abriram":
                    if (!$("#abriram")[0].value == "") {
                        $("#receberam").candlestick("on");
                    }
                    break;
                case "responderam":
                    if (!$("#responderam")[0].value == "") {
                        $("#receberam").candlestick("on");
                    }
                    break;
                case "clicaram":
                    if (!$("#clicaram")[0].value == "") {
                        $("#receberam").candlestick("on");
                    }
                    break;
                default:
                    break;
            }
        };
        $scope.avancarImportar = function () {
            if ($scope.passo == 1) {
                $scope.zerarBloco();
                $("#PassoContatos1").hide();
                $("#PassoContatos2").fadeIn();
                $("#PassoContatos3").hide();
                $("#PassoContatos4").hide();
                $scope.tituloAdicionar = "Configuração";
                $scope.descricaoAdicionar =
                    "O que você deseja fazer com os contatos que serão importados?";
                $rootScope.showBtnVoltarContatos = true;
                $scope.nomeBtnAvancarContatos = "AVANÇAR";
                $("#divtraco1").removeClass("dvTracoAzulPassoPasso");
                $("#spanImportacao").removeClass("txtAzulPassoPasso");
                $("#imagemPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                $("#divtraco1").addClass("dvTracoVerdePassoPasso");
                $("#spanImportacao").addClass("txtVerdePassoPasso");
                $("#divtraco2").removeClass("dvTracoCinzaPassoPasso");
                $("#divtraco2").addClass("dvTracoAzulPassoPasso");
                $("#spanMapeamento").removeClass("txtCinzaPassoPasso");
                $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                $("#spanMapeamento").addClass("txtAzulPassoPasso");
                $scope.nomeBtnAvancarContatos = "AVANÇAR";
                $scope.passo++;
            }
            else if ($scope.passo == 2) {
                if ($scope.segmentacaoAddContatomap == null ||
                    !$scope.segmentacaoAddContatomap ||
                    $scope.segmentacaoAddContatomap == "") {
                    $("#segmentacaoAddContatomap").addClass("corTextoErro");
                    $("#dvMensagemSegmentacaomap").fadeIn();
                }
                else {
                    $("#PassoContatos1").hide();
                    $("#PassoContatos2").hide();
                    $("#PassoContatos3").fadeIn();
                    $("#PassoContatos4").hide();
                    $scope.tituloAdicionar = "Mapeamento";
                    $scope.descricaoAdicionar =
                        "Arraste os tópicos, para indicar como o Ramper deve identificar cada tipo de dado importado.";
                    $rootScope.showBtnVoltarContatos = true;
                    $("#spanMapeamento").removeClass("txtAzulPassoPasso");
                    $("#spanMapeamento").addClass("txtVerdePassoPasso");
                    $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#divtraco2").removeClass("dvTracoAzulPassoPasso");
                    $("#divtraco2").addClass("dvTracoVerdePassoPasso");
                    $("#divtraco3").removeClass("dvTracoCinzaPassoPasso");
                    $("#divtraco3").addClass("dvTracoAzulPassoPasso");
                    $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $("#spanconfig").removeClass("txtCinzaPassoPasso");
                    $("#spanconfig").addClass("txtAzulPassoPasso");
                    $scope.nomeBtnAvancarContatos = "AVANÇAR";
                    if ($scope.primeiraValidacao == true) {
                        $scope.email = "E-MAIL";
                        $scope.empresa = "EMPRESA";
                        $scope.sobrenome = "SOBRENOME";
                        $scope.nome = "NOME";
                        $scope.dominio = "DOMÍNIO";
                        $("#dragDominio").removeClass("colunaVermelha");
                        $("#dragDominio").addClass("mapeamentocelulas");
                    }
                    else if ($scope.segundaValidacao == true) {
                        $scope.email = "E-MAIL";
                        $scope.empresa = "EMPRESA";
                        $scope.sobrenome = "SOBRENOME";
                        $scope.nome = "NOME";
                        $scope.dominio = "DOMÍNIO";
                        $("#dragEmail").removeClass("colunaVermelha");
                        $("#dragEmail").addClass("mapeamentocelulas");
                    }
                    else if ($scope.terceiraValidacao == true) {
                        $scope.email = "E-MAIL";
                        $scope.empresa = "EMPRESA";
                        $scope.sobrenome = "SOBRENOME";
                        $scope.nome = "NOME";
                        $scope.dominio = "DOMÍNIO";
                        $("#dragDominio").removeClass("colunaVermelha");
                        $("#dragDominio").addClass("mapeamentocelulas");
                    }
                    else if ($scope.terceiraValidacao == false &&
                        $scope.primeiraValidacao == false &&
                        $scope.segundaValidacao == false) {
                        $scope.email = "E-MAIL";
                        $scope.empresa = "EMPRESA";
                        $scope.sobrenome = "SOBRENOME";
                        $scope.nome = "NOME";
                        $scope.dominio = "DOMÍNIO";
                        $("#dragDominio").removeClass("colunaVermelha");
                        $("#dragDominio").addClass("mapeamentocelulas");
                    }
                    $scope.passo++;
                }
            }
            else if ($scope.passo == 3) {
                if ($scope.primeiraValidacao == true) {
                    $scope.email = "E-MAIL*";
                    $scope.empresa = "EMPRESA*";
                    $scope.sobrenome = "SOBRENOME*";
                    $scope.nome = "NOME*";
                    if ($("#dragEmail").hasClass("mapeamentocelulas")) {
                        $("#dragEmail").removeClass("mapeamentocelulas");
                        $("#dragEmail").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmail").removeClass("colunaVermelha");
                    $("#dragEmail").addClass("mapeamentocelulas");
                    if ($("#dragNome").hasClass("mapeamentocelulas")) {
                        $("#dragNome").removeClass("mapeamentocelulas");
                        $("#dragNome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragNome").removeClass("colunaVermelha");
                    $("#dragNome").addClass("mapeamentocelulas");
                    if ($("#dragSobrenome").hasClass("mapeamentocelulas")) {
                        $("#dragSobrenome").removeClass("mapeamentocelulas");
                        $("#dragSobrenome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragSobrenome").removeClass("colunaVermelha");
                    $("#dragSobrenome").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("mapeamentocelulas")) {
                        $("#dragEmpresa").removeClass("mapeamentocelulas");
                        $("#dragEmpresa").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmpresa").removeClass("colunaVermelha");
                    $("#dragEmpresa").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("colunaAtiva") &&
                        $("#dragNome").hasClass("colunaAtiva") &&
                        $("#dragSobrenome").hasClass("colunaAtiva") &&
                        $("#dragEmail").hasClass("colunaAtiva")) {
                        $scope.avancarTelaFinal();
                    }
                }
                else if ($scope.segundaValidacao == true) {
                    $scope.empresa = "EMPRESA*";
                    $scope.sobrenome = "SOBRENOME*";
                    $scope.nome = "NOME*";
                    $scope.dominio = "DOMÍNIO*";
                    if ($("#dragDominio").hasClass("mapeamentocelulas")) {
                        $("#dragDominio").removeClass("mapeamentocelulas");
                        $("#dragDominio").addClass("colunaVermelha");
                    }
                    else
                        $("#dragDominio").removeClass("colunaVermelha");
                    $("#dragDominio").addClass("mapeamentocelulas");
                    if ($("#dragNome").hasClass("mapeamentocelulas")) {
                        $("#dragNome").removeClass("mapeamentocelulas");
                        $("#dragNome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragNome").removeClass("colunaVermelha");
                    $("#dragNome").addClass("mapeamentocelulas");
                    if ($("#dragSobrenome").hasClass("mapeamentocelulas")) {
                        $("#dragSobrenome").removeClass("mapeamentocelulas");
                        $("#dragSobrenome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragSobrenome").removeClass("colunaVermelha");
                    $("#dragSobrenome").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("mapeamentocelulas")) {
                        $("#dragEmpresa").removeClass("mapeamentocelulas");
                        $("#dragEmpresa").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmpresa").removeClass("colunaVermelha");
                    $("#dragEmpresa").addClass("mapeamentocelulas");
                    if ($("#dragDominio").hasClass("colunaAtiva") &&
                        $("#dragEmpresa").hasClass("colunaAtiva") &&
                        $("#dragNome").hasClass("colunaAtiva") &&
                        $("#dragSobrenome").hasClass("colunaAtiva")) {
                        $scope.avancarTelaFinal();
                    }
                }
                else if ($scope.terceiraValidacao == true) {
                    $scope.email = "E-MAIL*";
                    $scope.empresa = "EMPRESA*";
                    $scope.sobrenome = "SOBRENOME*";
                    $scope.nome = "NOME*";
                    if ($("#dragNome").hasClass("mapeamentocelulas")) {
                        $("#dragNome").removeClass("mapeamentocelulas");
                        $("#dragNome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragNome").removeClass("colunaVermelha");
                    $("#dragNome").addClass("mapeamentocelulas");
                    if ($("#dragSobrenome").hasClass("mapeamentocelulas")) {
                        $("#dragSobrenome").removeClass("mapeamentocelulas");
                        $("#dragSobrenome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragSobrenome").removeClass("colunaVermelha");
                    $("#dragSobrenome").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("mapeamentocelulas")) {
                        $("#dragEmpresa").removeClass("mapeamentocelulas");
                        $("#dragEmpresa").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmpresa").removeClass("colunaVermelha");
                    $("#dragEmpresa").addClass("mapeamentocelulas");
                    if ($("#dragEmail").hasClass("mapeamentocelulas")) {
                        $("#dragEmail").removeClass("mapeamentocelulas");
                        $("#dragEmail").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmail").removeClass("colunaVermelha");
                    $("#dragEmail").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("colunaAtiva") &&
                        $("#dragNome").hasClass("colunaAtiva") &&
                        $("#dragSobrenome").hasClass("colunaAtiva") &&
                        $("#dragEmail").hasClass("colunaAtiva")) {
                        $scope.avancarTelaFinal();
                    }
                }
                else if ($scope.terceiraValidacao == false &&
                    $scope.primeiraValidacao == false &&
                    $scope.segundaValidacao == false) {
                    $scope.email = "E-MAIL*";
                    $scope.empresa = "EMPRESA*";
                    $scope.sobrenome = "SOBRENOME*";
                    $scope.nome = "NOME*";
                    if ($("#dragNome").hasClass("mapeamentocelulas")) {
                        $("#dragNome").removeClass("mapeamentocelulas");
                        $("#dragNome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragNome").removeClass("colunaVermelha");
                    $("#dragNome").addClass("mapeamentocelulas");
                    if ($("#dragSobrenome").hasClass("mapeamentocelulas")) {
                        $("#dragSobrenome").removeClass("mapeamentocelulas");
                        $("#dragSobrenome").addClass("colunaVermelha");
                    }
                    else
                        $("#dragSobrenome").removeClass("colunaVermelha");
                    $("#dragSobrenome").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("mapeamentocelulas")) {
                        $("#dragEmpresa").removeClass("mapeamentocelulas");
                        $("#dragEmpresa").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmpresa").removeClass("colunaVermelha");
                    $("#dragEmpresa").addClass("mapeamentocelulas");
                    if ($("#dragEmail").hasClass("mapeamentocelulas")) {
                        $("#dragEmail").removeClass("mapeamentocelulas");
                        $("#dragEmail").addClass("colunaVermelha");
                    }
                    else
                        $("#dragEmail").removeClass("colunaVermelha");
                    $("#dragEmail").addClass("mapeamentocelulas");
                    if ($("#dragEmpresa").hasClass("colunaAtiva") &&
                        $("#dragNome").hasClass("colunaAtiva") &&
                        $("#dragSobrenome").hasClass("colunaAtiva") &&
                        $("#dragEmail").hasClass("colunaAtiva")) {
                        $scope.avancarTelaFinal();
                    }
                }
                else {
                    $("#PassoContatos1").hide();
                    $("#PassoContatos2").hide();
                    $("#PassoContatos3").hide();
                    $("#PassoContatos4").fadeIn();
                    $scope.tituloAdicionar = "Finalização";
                    $rootScope.showBtnVoltarContatos = false;
                    $scope.descricaoAdicionar = "";
                    $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
                    $("#divtraco3").addClass("dvTracoVerdePassoPasso");
                    $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#spanconfig").removeClass("txtAzulPassoPasso");
                    $("#spanconfig").addClass("txtVerdePassoPasso");
                    $("#divtraco4").removeClass("dvTracoCinzaPassoPasso");
                    $("#divtraco4").addClass("dvTracoAzulPassoPasso");
                    $("#imagemPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $("#spanFinal").removeClass("txtCinzaPassoPasso");
                    $("#spanFinal").addClass("txtAzulPassoPasso");
                    $scope.nomeBtnAvancarContatos = "FECHAR";
                    $scope.passo++;
                }
            }
            else {
                mixpanel.track("Contatos | Importou lista", {
                    Local_IP: $rootScope.ip,
                });
                $scope.fecharImportar();
            }
        };
        $scope.passo = 1;
        $scope.voltarImportar = function () {
            if ($scope.passo == 2) {
                $("#PassoContatos1").fadeIn();
                $("#PassoContatos2").hide();
                $("#PassoContatos3").hide();
                $("#PassoContatos4").hide();
                $scope.tituloAdicionar = "Importar contatos";
                $scope.descricaoAdicionar =
                    "Insira seu arquivo com uma lista de contatos a serem adicionados no Ramper.";
                $rootScope.showBtnVoltarContatos = false;
                $("#divtraco2").removeClass("dvTracoAzulPassoPasso");
                $("#divtraco2").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco2").addClass("dvTracoCinzaPassoPasso");
                $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
                $("#divtraco3").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
                $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                $("#spanconfig").removeClass("txtAzulPassoPasso");
                $("#spanconfig").addClass("txtCinzaPassoPasso");
                $("#spanMapeamento").removeClass("txtVerdePassoPasso");
                $("#spanMapeamento").removeClass("txtCinzaPassoPasso");
                $("#spanMapeamento").addClass("txtCinzaPassoPasso");
                $("#divtraco1").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco1").addClass("dvTracoAzulPassoPasso");
                $("#spanImportacao").removeClass("txtVerdePassoPasso");
                $("#spanImportacao").addClass("txtAzulPassoPasso");
                $("#imagemPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                $scope.passo--;
            }
            else if ($scope.passo == 3) {
                $("#PassoContatos1").hide();
                $("#PassoContatos2").fadeIn();
                $("#PassoContatos3").hide();
                $("#PassoContatos4").hide();
                $scope.tituloAdicionar = "Configuração";
                $scope.descricaoAdicionar =
                    "O que você deseja fazer com o contatos que serão importados?";
                $("#divtraco2").removeClass("dvTracoAzulPassoPasso");
                $("#divtraco2").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco2").addClass("dvTracoAzulPassoPasso");
                $("#divtraco3").removeClass("dvTracoAzulPassoPasso");
                $("#divtraco3").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco3").addClass("dvTracoCinzaPassoPasso");
                $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                $("#imagemPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                $("#spanconfig").removeClass("txtAzulPassoPasso");
                $("#spanconfig").addClass("txtCinzaPassoPasso");
                $("#spanMapeamento").addClass("txtAzulPassoPasso");
                $("#spanMapeamento").removeClass("txtCinzaPassoPasso");
                $("#spanMapeamento").removeClass("txtVerdePassoPasso");
                $scope.passo--;
            }
            else if ($scope.passo == 4) {
                $("#PassoContatos1").hide();
                $("#PassoContatos2").hide();
                $("#PassoContatos3").fadeIn();
                $("#PassoContatos4").hide();
                $scope.tituloAdicionar = "Mapeamento";
                $scope.descricaoAdicionar =
                    "Arraste os tópicos, para indicar como o Ramper deve identificar cada tipo de dado importado.";
                $scope.nomeBtnAvancar = "FECHAR";
                $("#spanFinal").removeClass("txtVerdePassoPasso");
                $("#spanFinal").removeClass("txtAzulPassoPasso");
                $("#spanFinal").addClass("txtCinzaPassoPasso");
                $("#divtraco4").removeClass("dvTracoAzulPassoPasso");
                $("#divtraco4").addClass("dvTracoCinzaPassoPasso");
                $("#imagemPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                $("#imagemPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                $("#spanconfig").removeClass("txtVerdePassoPasso");
                $("#spanconfig").addClass("txtAzulPassoPasso");
                $("#divtraco3").removeClass("dvTracoVerdePassoPasso");
                $("#divtraco3").addClass("dvTracoAzulPassoPasso");
                $scope.passo--;
            }
        };
        $scope.validarSegmentacao = function () {
            if ($scope.segmentacaoAddContatomap != null ||
                $scope.segmentacaoAddContatomap) {
                $("#segmentacaoAddContatomap").removeClass("corTextoErro");
                $("#dvMensagemSegmentacaomap").fadeOut();
            }
        };
        $scope.removerClassesCor = function () {
            $("#confContatosSelecionado").removeClass("divAltaConfiabilidade");
            $("#confContatosSelecionado").removeClass("divMediaConfiabilidade");
            $("#confContatosSelecionado").removeClass("divBaixaConfiabilidade");
            $("#confContatosSelecionado").removeClass("divSemConfiabilidade");
        };
        $scope.clickMenuDetalhes = function (pDetalhe, pEmpresa) {
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            if (pDetalhe == "d") {
                $("#liDetalhesDados").addClass("liMenuDetalhesActive");
                $("#dvDetalhesDados").fadeIn();
                if (_contatoDetalhes.cor == "#1BB258") {
                    $scope.removerClassesCor();
                    $("#confContatosSelecionado").addClass("divAltaConfiabilidade");
                    $scope.confiabilidadeContatoSelecionado = "Alta";
                }
                else if (_contatoDetalhes.cor == "#EFB734") {
                    $scope.removerClassesCor();
                    $("#confContatosSelecionado").addClass("divMediaConfiabilidade");
                    $scope.confiabilidadeContatoSelecionado = "Média";
                }
                else if (_contatoDetalhes.cor == "#E04747") {
                    $scope.removerClassesCor();
                    $("#confContatosSelecionado").addClass("divBaixaConfiabilidade");
                    $scope.confiabilidadeContatoSelecionado = "Baixa";
                }
                else if (_contatoDetalhes.cor == "#7A7A7A") {
                    $scope.removerClassesCor();
                    $("#confContatosSelecionado").addClass("divSemConfiabilidade");
                    $scope.confiabilidadeContatoSelecionado = "Sem";
                }
            }
            else if (pDetalhe == "m") {
                $rootScope.loaderAtivo = true;
                $("#liDetalhesMetricas").addClass("liMenuDetalhesActive");
                $("#dvDetalhesMetricas").fadeIn();
                $scope.$broadcast("getMetricas", {
                    tela: "O",
                    objectId: _contatoDetalhes._id,
                });
                $rootScope.loaderAtivo = false;
                $("#sltDataMetricas").hide();
                $("#sequenciaFiltro").hide();
            }
            else if (pDetalhe == "c") {
                $("#liDetalhesCadencias").addClass("liMenuDetalhesActive");
                $("#dvDetalhesCadencias").removeClass("emptyContato");
                $scope.semBuscaContatoCad = false;
                if ($scope.listaCadenciasShow.length == 0) {
                    $(".dvEmptyDetalhesContatoCadencia").fadeIn();
                    $scope.semContatosCadencia = true;
                }
                $scope.listaCadenciasShow.forEach(function (detalheCadencia) {
                    detalheCadencia.checkCadenciaSelecionado = false;
                    detalheCadencia.disparoemails.forEach(function (detalheDisparosEmails) {
                        if (detalheDisparosEmails.dataResposta &&
                            detalheDisparosEmails.dataResposta != null &&
                            detalheDisparosEmails.dataResposta != "")
                            detalheDisparosEmails.cor = "#2f6d6c";
                        else if (detalheDisparosEmails.statusEnvio == "E" &&
                            (detalheDisparosEmails.dataResposta == null ||
                                !detalheDisparosEmails.dataResposta))
                            detalheDisparosEmails.cor = "green";
                        else
                            detalheDisparosEmails.cor = "#b5b5b5";
                        $scope.dataRespostaSequencia = detalheDisparosEmails.dataResposta;
                        $scope.statusRespostaSequencia =
                            detalheDisparosEmails.statusResposta;
                        $scope.dataAberturaSequencia = detalheDisparosEmails.dataAbertura;
                        $scope.statusEnvioSequencia = detalheDisparosEmails.statusEnvio;
                    });
                    $scope.totalSequencia = detalheCadencia.quantidadeEmailsFluxo;
                });
                for (var i = 0; i < $scope.listaCadenciasShow.length; i++) {
                    if ($scope.listaCadenciasShow[i].status == "F")
                        $scope.listaCadenciasShow[i].status = "FINALIZADA";
                    if ($scope.listaCadenciasShow[i].status == "P")
                        $scope.listaCadenciasShow[i].status = "PAUSADA";
                    if ($scope.listaCadenciasShow[i].status == "A")
                        $scope.listaCadenciasShow[i].status = "ATIVA";
                }
                $("#dvDetalhesCadencias").fadeIn();
            }
            else if (pDetalhe == "s") {
                $("#liDetalhesSegmentacoes").addClass("liMenuDetalhesActive");
                $("#dvDetalhesSegmentacoes").fadeIn();
                if ($scope.listaSegmentacoesShow.length == 0) {
                    $("#dvEmptyDetalhesCadenciaSeg").fadeIn();
                    $("#dvDetalhesSegmentacoes").hide();
                    $scope.semContatosSeg = true;
                }
                $scope.listaSegmentacoesShow.forEach(function (detalheSegmentacao) {
                    detalheSegmentacao.checkSelecionado = false;
                });
                $scope.reverse = false;
                $scope.propertyName = "nome";
                $scope.buscarSegmentacao = "";
            }
            else if (pDetalhe == "h") {
                $("#liDetalhesHistorico").addClass("liMenuDetalhesActive");
                $("#dvDetalhesHistorico").fadeIn();
            }
            else if (pDetalhe == "r") {
                $("#liDetalhesResumo").addClass("liMenuDetalhesActive");
                $("#dvDetalhesContatoResumo").fadeIn();
            }
        };
        esconderTodasDivsDetalhes = function () {
            $("#dvDetalhesDados").hide();
            $("#dvDetalhesMetricas").hide();
            $("#dvDetalhesCadencias").hide();
            $("#dvDetalhesSegmentacoes").hide();
            $("#dvDetalhesHistorico").hide();
            $("#dvDetalhesContatoResumo").hide();
            $(".dvEmptyDetalhesContatoCadencia").hide();
            $(".dvEmptyDetalhesCadenciaSeg").hide();
            $scope.semContatosCadencia = false;
            $scope.semContatosSeg = false;
        };
        removerDestaqueMenu = function () {
            $("#liDetalhesDados").removeClass("liMenuDetalhesActive");
            $("#liDetalhesMetricas").removeClass("liMenuDetalhesActive");
            $("#liDetalhesCadencias").removeClass("liMenuDetalhesActive");
            $("#liDetalhesSegmentacoes").removeClass("liMenuDetalhesActive");
            $("#liDetalhesHistorico").removeClass("liMenuDetalhesActive");
            $("#liDetalhesResumo").removeClass("liMenuDetalhesActive");
        };
        $scope.limparDadosContato = function () {
            $scope.nome = "";
            $scope.sobrenome = "";
            $scope.email = "";
            $scope.cargo = "";
            $scope.telefone = "";
            $scope.departamento = "";
            $scope.dominio = "";
            $scope.empresa = "";
            $scope.segmento = "";
            $scope.porte = "";
            $scope.pais = "";
            $scope.estado = "";
            $scope.cidade = "";
            $scope.telefonesNovaEmpresa = [];
        };
        $scope.fecharDetalhes = function () {
            $("#dvDetalhesGeral").fadeOut();
            $scope.totalCadenciaSelecionadas = 0;
            $("#checkCadenciasContatos").prop("checked", false);
            _checkCadenciaTodos = false;
            $scope.totalSegmentacoesSelecionadas = 0;
            $("#segmentacaoCheck").prop("checked", false);
            _clickCheckTodosSegmtacoes = false;
            _contatoDetalhes = null;
            _editarContato = null;
        };
        infiniteScrolling("#tbleContatos", function () {
            var alreadyReturnedAllContacts = $scope.showBrowserContatos
                ? $scope.showBrowserContatos.length === $scope.total
                : false;
            if (!alreadyReturnedAllContacts) {
                $scope.consultarContatos();
            }
        });
        $scope.abrirSubMenu = function (pDados) {
            if (pDados == "G") {
                $("#abrirSubMenuContatos").show();
                $("#abrirImageSeta").show();
                $scope.subMenuDeContatos = true;
                $scope.montarClasses();
                $scope.contatosInteresseEnviarCrm = [];
            }
            else if (pDados == "E") {
                $("#abrirSubMenuContatosEspecifico").show();
                $scope.subMenuCadenciasEspecifica = true;
            }
            else if (pDados == "C") {
                $("#abrirSubMenuCadenciaContatos").show();
                $rootScope.subMenuCadenciaDeContatos = true;
            }
            else if (pDados == "S") {
                $("#abrirSubMenucontatosSeg").show();
                $scope.subMenuContatosSeg = true;
            }
        };
        $(document).mouseup(function (e) {
            var container = $(".subMenuContatosEspecifico");
            if (!container.is(e.target) && container.has(e.target).length === 0)
                container.hide();
        });
        $(document).mouseup(function (e) {
            var container = $(".abrirSubMenuIntegracaoDetalhes");
            if (!container.is(e.target) && container.has(e.target).length === 0)
                container.hide();
        });
        $(document).mouseup(function (e) {
            var subMenu = $(".subMenuContatos");
            var seta = $("#abrirImageSeta");
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0) {
                subMenu.hide();
                seta.hide();
            }
        });
        $(document).mouseup(function (e) {
            var subMenu = $(".subMenuContatosInterno");
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $(document).mouseup(function (e) {
            var subMenu = $(".subMenuCadenciasContatos");
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $scope.duplicarContatoPara = function () {
            if (!$scope.listaSegmentacoes.length ||
                $scope.listaSegmentacoes.length == 0)
                $scope.consultarSegmentacoes();
            $scope.fecharSubMenu();
            $("#abrirSubMenuContatosEspecifico").hide();
            $scope.subMenuCadenciasEspecifica = false;
            $scope.popupDuplicarPara = true;
            $("#idFundoPopupContatos").fadeIn();
            $scope.segmentacaoFiltroContatos = "";
            $scope.nomeSegDuplicarPara = "";
            document.getElementById("idSpanSegmentacaoDuplicarPara").innerText =
                "Segmentação *";
        };
        $scope.fecharPopupDuplicarPara = function (pFechar, pContato) {
            if (pFechar) {
                var contatos = [];
                if (!pContato) {
                    if ($("#dvDetalhesGeral").is(":visible"))
                        contatos.push(_contatoDetalhes);
                    else
                        contatos = $scope.showBrowserContatos.filter(function (obj) {
                            return obj.checkSelecionado;
                        });
                }
                if (pContato == null) {
                    var nContatos = contatos.length;
                    var nomeSegmentacaoMixpanel = $scope.nomeSegDuplicarPara;
                    mixpanel.track("Contatos | Enviou contatos para segmentação", {
                        Local_IP: $rootScope.ip,
                    });
                }
                $http({
                    method: "POST",
                    url: "/contatos/enviarContatosSegmentacao",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        tipoDaChave: $scope.usuarioFiltro
                            ? "U"
                            : $rootScope.permissoes.tipoUsuario,
                        todos: _clickCheckTodos,
                        objectIdContato: pContato
                            ? pContato
                            : contatos.map(function (m) {
                                return m._id;
                            }),
                        objectIdSegmentacoes: $scope.popupDuplicarPara
                            ? $scope.segmentacaoFiltroContatos
                            : $("#segmentacaoAddContato")[0].selectedOptions[0].value,
                        buscaTexto: $scope.inpBuscarContatos,
                        dataInicio: $scope.dataInicioFiltroContatos
                            ? new Date($scope.dataInicioFiltroContatos.toDateString())
                            : "",
                        dataFim: $scope.dataFinalFiltroContatos
                            ? new Date($scope.dataFinalFiltroContatos.toDateString())
                            : "",
                        dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                            ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                            ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                            : "",
                        dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                            ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                            : "",
                        status: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                        objectId: $scope.usuarioFiltro,
                        objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                        semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                        objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                        alta: $("#cbAltaContatosInput").is(":checked"),
                        media: $("#cbMediaContatosInput").is(":checked"),
                        baixa: $("#cbBaixaContatosInput").is(":checked"),
                        sem: $("#cbSemContatosInput").is(":checked"),
                        receberam: $("#receberam")[0].value,
                        abriram: $("#abriram")[0].value,
                        clicaram: $("#clicaram")[0].value,
                        responderam: $("#responderam")[0].value,
                    },
                }).then(function () {
                    $scope.initLoader();
                    setTimeout(function () {
                        $scope.recarregarTela();
                    }, 1000);
                });
                $scope.segmentacaoFiltroContatos = "";
                $scope.nomeSegDuplicarPara = "";
                $rootScope.mostrarConsumoCredito = false;
                $rootScope.mostrarImpossibilidadeValidacao = false;
                $("#validarEmail").prop("checked", false);
                document.getElementById("idSpanSegmentacaoDuplicarPara").innerText =
                    "Segmentação *";
                limparSelecaoContatos();
            }
            $scope.popupDuplicarPara = false;
            $("#idFundoPopupContatos").fadeOut();
        };
        $("#sltDataFiltroTelaContatos").daterangepicker(__assign(__assign({}, DateRangePicker.config), { ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataFiltroTelaContatos").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltroContatos = picker.startDate.toDate();
            $scope.dataInicioFiltroContatos.setHours(0);
            $scope.dataInicioFiltroContatos.setMinutes(0);
            $scope.dataInicioFiltroContatos.setSeconds(0);
            $scope.dataFinalFiltroContatos = picker.endDate.toDate();
            $scope.dataFinalFiltroContatos.setHours(23);
            $scope.dataFinalFiltroContatos.setMinutes(59);
            $scope.dataFinalFiltroContatos.setSeconds(59);
        });
        $("#sltDataFiltroTelaContatos").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $("#sltDataFiltroTelaContatosDataSegmentacao").daterangepicker(__assign(__assign({}, DateRangePicker.config), { ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataFiltroTelaContatosDataSegmentacao").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltroContatosSegmentacao = picker.startDate.toDate();
            $scope.dataInicioFiltroContatosSegmentacao.setHours(0);
            $scope.dataInicioFiltroContatosSegmentacao.setMinutes(0);
            $scope.dataInicioFiltroContatosSegmentacao.setSeconds(0);
            $scope.dataFinalFiltroContatosSegmentacao = picker.endDate.toDate();
            $scope.dataFinalFiltroContatosSegmentacao.setHours(23);
            $scope.dataFinalFiltroContatosSegmentacao.setMinutes(59);
            $scope.dataFinalFiltroContatosSegmentacao.setSeconds(59);
        });
        $("#sltDataFiltroTelaContatosDataSegmentacao").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $("#sltDataFiltroTelaContatosDataEnvioProgramacao").daterangepicker(__assign(__assign({}, DateRangePicker.config), { drops: "up", maxSpan: {
                days: 15,
            }, ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataFiltroTelaContatosDataEnvioProgramacao").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltroContatosEnvioProgramacao =
                picker.startDate.toDate();
            $scope.dataInicioFiltroContatosEnvioProgramacao.setHours(0);
            $scope.dataInicioFiltroContatosEnvioProgramacao.setMinutes(0);
            $scope.dataInicioFiltroContatosEnvioProgramacao.setSeconds(0);
            $scope.dataFinalFiltroContatosEnvioProgramacao =
                picker.endDate.toDate();
            $scope.dataFinalFiltroContatosEnvioProgramacao.setHours(23);
            $scope.dataFinalFiltroContatosEnvioProgramacao.setMinutes(59);
            $scope.dataFinalFiltroContatosEnvioProgramacao.setSeconds(59);
        });
        $("#sltDataFiltroTelaContatosDataEnvioProgramacao").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $scope.filtrarRespContato = function (pDados) {
            if (pDados == "sim") {
                if ($("#bolinhaSimFiltroContato").hasClass("corImgClasResp"))
                    $("#bolinhaSimFiltroContato")
                        .removeClass("corImgClasResp")
                        .addClass("corImgOriginalResp");
                else
                    $("#bolinhaSimFiltroContato")
                        .removeClass("corImgOriginalResp")
                        .addClass("corImgClasResp");
            }
            else if (pDados == "neutra") {
                if ($("#bolinhaNeutraFiltroContato").hasClass("corImgClasResp"))
                    $("#bolinhaNeutraFiltroContato")
                        .removeClass("corImgClasResp")
                        .addClass("corImgOriginalResp");
                else
                    $("#bolinhaNeutraFiltroContato")
                        .removeClass("corImgOriginalResp")
                        .addClass("corImgClasResp");
            }
            else if (pDados == "nao") {
                if ($("#bolinhaNaoFiltroContato").hasClass("corImgClasResp"))
                    $("#bolinhaNaoFiltroContato")
                        .removeClass("corImgClasResp")
                        .addClass("corImgOriginalResp");
                else
                    $("#bolinhaNaoFiltroContato")
                        .removeClass("corImgOriginalResp")
                        .addClass("corImgClasResp");
            }
        };
        $scope.novaSegmentacaoPorLista = function () {
            $scope.popupNovaSegmentacaoPorLista = true;
            $("#idFundoPopupContatos").fadeIn();
            $("#txtNomeNovaSegmentacaoLista").removeClass("inptComErro");
            $("#dvMensagemNomeSegmentacaoLista").hide();
        };
        $scope.fecharPopupNovaSegmentacaoPorLista = function () {
            $scope.popupNovaSegmentacaoPorLista = false;
            $("#idFundoPopupContatos").fadeOut();
            $scope.nomeNovaSegmentacaoLista = "";
        };
        $scope.salvarNovaSegmentacaoListas = function () {
            if ($scope.nomeNovaSegmentacaoLista == null ||
                $scope.nomeNovaSegmentacaoLista == "" ||
                !$scope.nomeNovaSegmentacaoLista) {
                $("#txtNomeNovaSegmentacaoLista").addClass("inptComErro");
                $("#dvMensagemNomeSegmentacaoLista").fadeIn();
            }
            else {
                $("#txtNomeNovaSegmentacaoLista").removeClass("inptComErro");
                $("#dvMensagemNomeSegmentacaoLista").fadeOut();
                $scope.salvarNovaSegmentacao(true);
                $scope.fecharPopupNovaSegmentacaoPorLista();
            }
        };
        $scope.atualizarNomeSegmentacaoLista = function () {
            $("#txtNomeNovaSegmentacaoLista").removeClass("inptComErro");
            $("#dvMensagemNomeSegmentacaoLista").fadeOut();
        };
        $scope.identifInputVazio = function (keyEvent) {
            var input = keyEvent.target.value.trim();
            $scope.botaoBuscarVisivel = Boolean(input);
        };
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                $scope.inpBuscarContatos = $scope.buscarContatos || "";
                if ($scope.popupRemove) {
                    if ($scope.digiteExcluir == $scope.qtdSelecionadosExcluir)
                        $scope.fecharPopupRemover(true);
                }
                else {
                    $scope.buscarHabilitado = true;
                    $("#checkContatos").prop("checked", false);
                    _clickCheckTodos = false;
                    _pagina = -100;
                    _campoOrdem = "percAcerto";
                    _ordem = -1;
                    $scope.totalSelecionados = 0;
                    $scope.consultarContatos();
                    $scope.zerarCamposOrdenacao();
                    limparSelecaoContatos();
                }
            }
        };
        $scope.enterSegmentacao = function (keyEvent) {
            if (keyEvent.which === 13) {
                $scope.semBuscaContatoSeg = true;
                $scope.buscaOuFiltro = "Sua busca";
            }
            else {
                $scope.semBuscaContatoSeg = false;
            }
        };
        $scope.enterCadencia = function (keyEvent) {
            if (keyEvent.which === 13) {
                $scope.buscaOuFiltro = "Sua busca";
                $("#tbleContatosCadencia").addClass("emptyContato");
                $scope.semBuscaContatoCad = true;
            }
            else {
                $scope.semBuscaContatoCad = false;
            }
        };
        $scope.removerFiltros = function () {
            $scope.botaoBuscarVisivel = false;
            $scope.cadenciaFiltroSeg = "";
            $scope.buscarContatos = "";
            $scope.inpBuscarContatos = "";
            $("#sltDataFiltroTelaContatosDataSegmentacao")[0].value = "";
            $("#sltDataFiltroTelaContatosDataEnvioProgramacao")[0].value = "";
            $("#checkContatos").prop("checked", false);
            _clickCheckTodos = false;
            _pagina = -100;
            $scope.limparFiltros("con");
            $scope.resetCandleStick();
            $scope.totalSelecionados = 0;
            setTimeout(function () {
                _campoOrdem = "percAcerto";
                _ordem = -1;
                $scope.consultarContatos();
                $scope.esconderEmptyStates();
                $scope.montarNomeFiltros();
                $scope.filtroHabilitado = false;
                $("#idBtnFiltroContatos")
                    .removeClass()
                    .addClass("btnVoltarPadroNovoFiltro");
                $("#idImgFiltrarContatos").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
            }, 100);
        };
        $scope.redirectContatos = function () {
            $rootScope.redirectContatos();
        };
        $scope.esconderEmptyStates = function () {
            $scope.semContatos = false;
            $scope.semContatosBusca = false;
            $scope.semContatosFiltro = false;
            $scope.buscarHabilitado = false;
            $scope.filtroContatos = false;
        };
        var qtdErros = 0;
        $scope.ficarNaTela = function () {
            $scope.popupDominio = false;
            $scope.mudancaDeDominio = false;
            $("#idFundoPopupContatos").fadeOut();
            if (_editarContato) {
                $scope.empresaNovoContato = _editarContato.aliasEmpresas[0].empresa;
            }
            else if (_contatoDetalhes) {
                $scope.empresaNovoContato = _contatoDetalhes.aliasEmpresas[0].empresa;
            }
            $scope.atualizarInfosContato("empresa");
        };
        $scope.divFechar = function () {
            $("#voltarCriacaoManual").hide();
            $("#passo1").hide();
            $("#passo2").hide();
            $("#passo3").show();
            $("#dvPassoPassoGeral2").hide();
            $("#dvPassoPassoGeral3").show();
            $rootScope.habilitarBtnEditarContato = true;
            $rootScope.viewBtnAvancar = false;
            $scope.nomeBotaoAvancarCriacao = "FECHAR";
            $("#dvTraco3")
                .removeClass("dvTracoCinzaPassoPasso")
                .addClass("dvTracoAzulPassoPasso");
            $("#spanFinalizacaoManual")
                .removeClass("txtCinzaPassoPasso")
                .addClass("txtAzulPassoPasso");
            $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#dvTraco1")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoVerdePassoPasso");
            $("#spanInicioManual")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtVerdePassoPasso");
            $("#imgPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
        };
        $scope.qtdErroEAvancar = function () {
            $scope.popupDominio = false;
            $scope.mudancaDeDominio = true;
            $("#idFundoPopupContatos").fadeOut();
            if ($scope.criacaoDeContato) {
                _countDiv++;
                $("#voltarCriacaoManual").show();
                $scope.showEtapa(_countDiv);
                qtdErros = 1;
            }
            else {
                if ($scope.empresaNovoContato) {
                    $scope.editarContatoGeral = false;
                    $scope.editarContatoDetalhe = false;
                    var editouEmail = false;
                    if (_editarContato) {
                        editouEmail = _editarContato.email != $scope.emailNovoContato;
                    }
                    else if (_contatoDetalhes) {
                        editouEmail = _contatoDetalhes.email != $scope.emailNovoContato;
                    }
                    if (editouEmail) {
                        $scope.procurarContatoPorTime(true, editouEmail);
                    }
                    else {
                        if (qtdErros == 1) {
                            _countDiv++;
                            $scope.salvarContato(true);
                            $scope.divFechar();
                            qtdErros = 0;
                        }
                    }
                }
            }
        };
        $scope.avancar = function () {
            qtdErros = 0;
            $scope.criacaoDeContato = true;
            if (_countDiv == 1) {
                if ($scope.nomeNovoContato == null ||
                    $scope.nomeNovoContato == "" ||
                    !$scope.nomeNovoContato) {
                    $("#txtNomeContato").addClass("inptComErro");
                    $("#dvMensagemNome").fadeIn();
                    qtdErros++;
                }
                if ($scope.sobrenomeNovoContato == null ||
                    $scope.sobrenomeNovoContato == "" ||
                    !$scope.sobrenomeNovoContato) {
                    $("#txtSobrenomeContato").addClass("inptComErro");
                    $("#dvMensagemSobrenome").fadeIn();
                    qtdErros++;
                }
                if ($scope.emailNovoContato == null ||
                    $scope.emailNovoContato == "" ||
                    !$scope.emailNovoContato) {
                    $("#txtEmailContato").addClass("inptComErro");
                    $("#dvMensagemEmail").fadeIn();
                    qtdErros++;
                }
                if ($scope.empresaNovoContato == null ||
                    $scope.empresaNovoContato == "" ||
                    !$scope.empresaNovoContato) {
                    $("#txtEmpresaContato").addClass("inptComErro");
                    $("#dvMensagemEmpresa").fadeIn();
                    qtdErros++;
                }
                if (qtdErros == 0) {
                    if ($scope.pesquisaEmpresa != $scope.empresaNovoContato) {
                        $scope.popupDominio = true;
                        $("#idFundoPopupContatos").fadeIn();
                    }
                    else {
                        _countDiv++;
                        $("#voltarCriacaoManual").show();
                        $scope.showEtapa(_countDiv);
                        qtdErros = 1;
                    }
                }
            }
            else if (_countDiv == 2) {
                $scope.botaoAvancarCriarContatoDesabilitado = true;
                $scope.popupDominio = false;
                $("#idFundoPopupContatos").fadeOut();
                if ($("#segmentacaoAddContato")[0].value == null ||
                    $("#segmentacaoAddContato")[0].value == "") {
                    $("#segmentacaoAddContato").addClass("corTextoErro");
                    $("#dvMensagemSegmentacao").fadeIn();
                    qtdErros++;
                }
                if (qtdErros == 0) {
                    $scope.procurarContatoPorTime(false);
                }
            }
            else {
                $scope.fecharContatoManual();
            }
        };
        $scope.salvarEditarContato = function () {
            qtdErros = 0;
            $scope.criacaoDeContato = false;
            if (_countDiv == 3) {
                if (!$scope.nomeNovoContato) {
                    $("#txtNomeContato").addClass("inptComErro");
                    $("#dvMensagemNome").fadeIn();
                    qtdErros++;
                }
                if (!$scope.sobrenomeNovoContato) {
                    $("#txtSobrenomeContato").addClass("inptComErro");
                    $("#dvMensagemSobrenome").fadeIn();
                    qtdErros++;
                }
                if (!$scope.emailNovoContato) {
                    $("#txtEmailContato").addClass("inptComErro");
                    $("#dvMensagemEmail").fadeIn();
                    qtdErros++;
                }
                if (!$scope.empresaNovoContato) {
                    $("#txtEmpresaContato").addClass("inptComErro");
                    $("#dvMensagemEmpresa").fadeIn();
                    qtdErros++;
                }
                if ($scope.emailNovoContato &&
                    (($scope.empresaDoBancoInterno != $scope.empresaNovoContato &&
                        $scope.editarContatoDetalhe == true) ||
                        ($scope.empresaDoBancoFora != $scope.empresaNovoContato &&
                            $scope.editarContatoGeral == true))) {
                    $scope.popupDominio = true;
                    $("#idFundoPopupContatos").fadeIn();
                    qtdErros++;
                }
                if (qtdErros == 0) {
                    var editouEmail = false;
                    if (_editarContato) {
                        editouEmail = _editarContato.email != $scope.emailNovoContato;
                    }
                    else if (_contatoDetalhes) {
                        editouEmail = _contatoDetalhes.email != $scope.emailNovoContato;
                    }
                    if (editouEmail) {
                        $scope.procurarContatoPorTime(true, editouEmail);
                    }
                    else {
                        $scope.editarContatoDetalhe = false;
                        $scope.editarContatoGeral = false;
                        _countDiv++;
                        $scope.salvarContato(true);
                        $scope.divFechar();
                    }
                }
            }
            else {
                $scope.fecharContatoManual();
            }
        };
        $scope.fecharPopupInformativo = function () {
            qtdErros = 0;
            $rootScope.popupInformativo = false;
            $("#menuPrincipal2").removeClass("blur");
            $(".dvDetalhesDetalhes").removeClass("blur");
            $(".dvPassoPasso").removeClass("blur");
        };
        $scope.voltar = function () {
            if (_countDiv > 1)
                _countDiv--;
            if (_countDiv == 1)
                $("#voltarCriacaoManual").hide();
            $scope.showEtapa(_countDiv);
        };
        $scope.voltarCriarContato = function (pDados) {
            if (pDados == "m") {
                $scope.fecharContatoManual();
                $scope.adicionarContato();
            }
        };
        $scope.esconderEtapas = function () {
            $("#passo1").hide();
            $("#passo2").hide();
            $("#passo3").hide();
            $("#passo4").hide();
        };
        $scope.showEtapa = function (etapa) {
            $scope.esconderEtapas();
            var x = etapa + 1;
            switch (etapa) {
                case 1:
                    $scope.coresPassoPassoInicial();
                    $rootScope.viewBtnAvancar = true;
                    $("#voltarCriacaoManual").hide();
                    break;
                case 2:
                    $("#dvTraco1")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoVerdePassoPasso");
                    $("#spanInicioManual")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtVerdePassoPasso");
                    $("#imgPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#dvTraco2")
                        .removeClass("dvTracoCinzaPassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanConfiguracao")
                        .removeClass("txtCinzaPassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#dvTraco2")
                        .removeClass("dvTracoVerdePassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanConfiguracao")
                        .removeClass("txtVerdePassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $("#dvTraco3")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoCinzaPassoPasso");
                    $("#spanFinalizacaoManual")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtCinzaPassoPasso");
                    $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 3:
                    $("#dvTraco2")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoVerdePassoPasso");
                    $("#spanConfiguracao")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtVerdePassoPasso");
                    $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#dvTraco3")
                        .removeClass("dvTracoCinzaPassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanFinalizacaoManual")
                        .removeClass("txtCinzaPassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    break;
                default:
                    return;
            }
            $("#passo" + etapa.toString()).fadeIn();
        };
        $scope.coresPassoPassoInicial = function () {
            $("#dvTraco1")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoAzulPassoPasso");
            $("#spanInicioManual")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtAzulPassoPasso");
            $("#imgPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#dvTraco2")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#dvTraco2")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanConfiguracao")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#spanConfiguracao")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#dvTraco3")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#dvTraco3")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanFinalizacaoManual")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#spanFinalizacaoManual")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
        };
        $scope.fecharContatoManual = function () {
            $("#formNovoContatoManual").fadeOut();
            $("#dvAdicionarContatos").fadeOut();
            $scope.totalSelecionados = 0;
            $("#dvPassoPassoGeral2").show();
            $("#dvPassoPassoGeral3").show();
            $("#txtNomeContato").removeClass("inptComErro");
            $("#txtSobrenomeContato").removeClass("inptComErro");
            $("#txtEmailContato").removeClass("inptComErro");
            $("#txtCargoContato").removeClass("inptComErro");
            $("#txtEmpresaContato").removeClass("inptComErro");
            $("#dvMensagemEmpresa").fadeOut();
            $("#dvMensagemNome").hide();
            $("#dvMensagemSobrenome").hide();
            $("#dvMensagemEmail").hide();
            _countDiv = 1;
            $rootScope.mostrarConsumoCredito = false;
            $rootScope.mostrarImpossibilidadeValidacao = false;
            $("#validarEmail").prop("checked", false);
            $scope.limparVariaveis();
            _pagina = -100;
            $scope.consultarContatos();
            $scope.fecharDetalhes();
        };
        $scope.limparVariaveis = function () {
            $scope.nomeBtnAvancar = "AVANÇAR";
            $scope.nomeNovoContato = "";
            $scope.sobrenomeNovoContato = "";
            $scope.emailNovoContato = "";
            $scope.cargoNovoContato = "";
            $scope.notas = "";
            $scope.departamentoNovoContato = "";
            $scope.telefoneNovoContato = "";
            $scope.linkedinNovoContato = "";
            $scope.twitterNovoContato = "";
            $scope.facebookNovoContato = "";
            $scope.googlePlusNovoContato = "";
            $scope.instagramNovoContato = "";
            $scope.dominioEmpresaNovoContato = "";
            $scope.porteEmpresaNovoContato = "";
            $scope.segmentoEmpresaNovoContato = "";
            $scope.cidadeEmpresaNovoContato = "";
            $scope.estadoEmpresaNovoContato = "";
            $scope.empresaNovoContato = "";
            $scope.segmentacaoAddContato = "";
            $("#validarEmail")[0].value == "n";
            $scope.nomeNovaSegmentacao = "";
            $rootScope.habilitarBtnEditarContato = false;
            $scope.emailInput = {
                disable: false,
                message: "",
            };
        };
        validarArquivoExcel = function (pArquivo) {
            for (var i = 0; i < pArquivo.name.substring(0, pArquivo.name.indexOf(".")).length; i++) {
                if ((pArquivo.name.charCodeAt(i) < 65 ||
                    pArquivo.name.charCodeAt(i) > 90) &&
                    (pArquivo.name.charCodeAt(i) < 45 ||
                        pArquivo.name.charCodeAt(i) > 57) &&
                    (pArquivo.name.charCodeAt(i) < 97 ||
                        pArquivo.name.charCodeAt(i) > 122) &&
                    pArquivo.name.charCodeAt(i) != 45 &&
                    pArquivo.name.charCodeAt(i) != 95) {
                    $scope.arquivoInvalidoTextoEspecial = true;
                    $scope.fecharLista();
                    $scope.adicionarErroLista();
                    return;
                }
            }
            _extensaoArquivo = pArquivo.name.split(".").pop().toLowerCase();
            _nomeDoArquivo = "";
            if (_extensaoArquivo == "xlsx" || _extensaoArquivo == "csv") {
                $http({
                    method: "GET",
                    url: "/aws/gerarIdentificador/",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        nomeDoArquivoSemExtensao: pArquivo.name.substring(0, pArquivo.name.indexOf(".")),
                    },
                }).then(function (retorno) {
                    $rootScope.loaderAtivo = true;
                    _nomeDoArquivo = retorno.data;
                    var reader = new FileReader();
                    reader.onload = function (f) { };
                    reader.readAsDataURL(pArquivo);
                    var formData = new FormData();
                    formData.append("file", pArquivo);
                    var request = new XMLHttpRequest();
                    request.open("POST", "/aws/uploaderArquivoAWS/" +
                        $rootScope.DadosCadastrais.objectIdLogin +
                        "/" +
                        _nomeDoArquivo +
                        "/" +
                        _extensaoArquivo, true);
                    request.setRequestHeader("ramp3r-auth", "ramp3r-authorization");
                    request.setRequestHeader("ramp3r-l", $rootScope.IDCrypto);
                    request.send(formData);
                    request.onreadystatechange = function () {
                        if (request.readyState == 4) {
                            $http({
                                method: "GET",
                                url: "/listas/lerArquivo",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": $rootScope.IDCrypto,
                                },
                                params: {
                                    nome: "arquivos/" + _nomeDoArquivo + "." + _extensaoArquivo,
                                },
                            }).then(function (rRetorno) {
                                $scope.previewExcel = [];
                                $rootScope.loaderAtivo = false;
                                var ctrl = 0;
                                if (rRetorno.data.quantidadeContatos <= _limiteRegistros) {
                                    rRetorno.data.lista.forEach(function (item) {
                                        ctrl++;
                                        item.numero = ctrl;
                                        if (ctrl == rRetorno.data.lista.length) {
                                            setTimeout(function () {
                                                var ascii = 65;
                                                while (ascii <= 122) {
                                                    $("#linhaExcel_" + String.fromCharCode(ascii) + ctrl).removeClass("bordacontatoatabletd");
                                                    $("#linhaExcel_" + String.fromCharCode(ascii) + ctrl).addClass("bordacontatoatabletdbottom");
                                                    ascii++;
                                                    if (ascii == 91)
                                                        ascii = 97;
                                                }
                                            }, 2000);
                                        }
                                        $scope.previewExcel.push({
                                            A: item[0],
                                            B: item[1],
                                            C: item[2],
                                            D: item[3],
                                            E: item[4],
                                            F: item[5],
                                            G: item[6],
                                            H: item[7],
                                            I: item[8],
                                            J: item[9],
                                            K: item[10],
                                            L: item[11],
                                            M: item[12],
                                            N: item[13],
                                            O: item[14],
                                            P: item[15],
                                            Q: item[16],
                                            R: item[17],
                                            S: item[18],
                                            T: item[19],
                                            U: item[20],
                                            V: item[21],
                                            W: item[22],
                                            X: item[23],
                                            Y: item[24],
                                            Z: item[25],
                                            a: item[26],
                                            b: item[27],
                                            c: item[28],
                                            d: item[29],
                                            e: item[30],
                                            f: item[31],
                                            g: item[32],
                                            h: item[33],
                                            i: item[34],
                                            j: item[35],
                                            k: item[36],
                                            l: item[37],
                                            m: item[38],
                                            n: item[39],
                                            o: item[40],
                                            p: item[41],
                                            q: item[42],
                                            r: item[43],
                                            s: item[44],
                                            t: item[45],
                                            u: item[46],
                                            v: item[47],
                                            w: item[48],
                                            x: item[49],
                                            y: item[50],
                                            z: item[51],
                                            numero: item.numero,
                                        });
                                    });
                                }
                                else {
                                    $scope.fecharLista();
                                    $scope.adicionarErroLista();
                                    $scope.arquivoInvalidoLimiteRegistros = true;
                                }
                            });
                        }
                    };
                });
            }
            else {
                $scope.fecharLista();
                $scope.adicionarErroLista();
                $scope.arquivoInvalido = true;
                $scope.arquivoInvalidoTextoEspecial = false;
                $scope.arquivoInvalidoLimiteRegistros = false;
            }
        };
        $scope.adicionarErroLista = function () {
            $("#bordaImportacaoContatos").removeClass("importacaocontatosPlan");
            $("#bordaImportacaoContatos").addClass("importacaocontatosPlanVermelha");
        };
        processarArquivo = function () {
            var ascii = 65;
            var mapeamento = [];
            $scope.importacaoOK = true;
            $scope.qtdContatosImportados = "";
            while (ascii <= 122) {
                var componente = document.getElementById("th" + String.fromCharCode(ascii));
                if (componente.childNodes[0]) {
                    var coluna = String.fromCharCode(ascii);
                    var valor = componente.childNodes[0].id;
                    mapeamento.push({ coluna: coluna, valor: valor });
                }
                ascii++;
                if (ascii == 91)
                    ascii = 97;
            }
            $http({
                method: "POST",
                url: "/listas/processarArquivo",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    nome: "arquivos/" + _nomeDoArquivo + "." + _extensaoArquivo,
                    mapeamento: mapeamento,
                    validarEmailsViaRamper: $scope.primeiraValidacao,
                    descobrirEmailsViaRamper: $scope.segundaValidacao,
                    bloquearEnviosDosContatos: $scope.terceiraValidacao,
                    objectIdSegmentacao: $scope.segmentacaoAddContatomap,
                },
            }).then(function (rRetorno) {
                $scope.qtdContatosImportados = rRetorno;
            });
        };
        erroAoSelecionarArquivo = function () {
            $("#bordaImportacaoContatos").removeClass("importacaocontatosPlan");
            $("#bordaImportacaoContatos").addClass("importacaocontatosPlanVermelha");
            $scope.arquivoInvalido = true;
            $scope.arquivoInvalidoTextoEspecial = false;
            $scope.arquivoInvalidoLimiteRegistros = false;
        };
        selecionouArquivo = function () {
            $scope.nomeArquivoImportado = $("#inptArquivo")[0].files[0].name;
            $("#nomedoArquivoExcelX").show();
            $("#nomedoArquivoExcel").show();
            $scope.arquivoInvalido = false;
            $scope.arquivoInvalidoTextoEspecial = false;
            $scope.arquivoInvalidoLimiteRegistros = false;
            $("#botaocontatosLista")[0].className = "btn-cinza btn-outline-none";
            $("#imgcontatosLista")[0].className = "background-colorContatosActive";
            $("#bordaImportacaoContatos")[0].className = "importacaocontatosPlan";
            $("#spanArrasteAqui")[0].className = "fontNormalImportant";
            $("#nomedoArquivoExcel")[0].className = "importaçãoPlanilha2";
            $("#nomedoArquivoExcelX")[0].className = "inputplanilha2";
            $rootScope.showBtnAvancarContatos = true;
            $rootScope.viewBtnAvancar = true;
            validarArquivoExcel($("#inptArquivo")[0].files[0]);
            $scope.$apply();
        };
        $scope.carregarDragAndDropExcel = function () {
            var _componenteAlfabeto = null;
            var _areaDragDrop = $("#areaDragDrop")[0];
            _areaDragDrop.addEventListener("dragleave", dragHoverExcel, false);
            _areaDragDrop.addEventListener("dragover", dragHoverExcel, false);
            _areaDragDrop.addEventListener("drop", dropExcel, false);
            _listaAlfabeto.forEach(function (itemAlfabeto) {
                _componenteAlfabeto = $("#th" + itemAlfabeto)[0];
                _componenteAlfabeto.addEventListener("dblclick", duploCliqueTabela, false);
                _componenteAlfabeto.addEventListener("dragover", dragOverTabela, false);
                _componenteAlfabeto.addEventListener("drop", dropTabela, false);
            });
        };
        $scope.fecharLista = function () {
            $scope.nomeArquivoImportado = null;
            $("#inptArquivo").val("");
            $("#nomedoArquivoExcelX").hide();
            $("#nomedoArquivoExcel").hide();
            $("#botaocontatosLista").removeClass("btn-cinza");
            $("#botaocontatosLista").addClass("btn-verde btn-outline-none");
            $("#imgcontatosLista").removeClass("background-colorContatosActive");
            $("#imgcontatosLista").addClass("background-colorContatos");
            $rootScope.showBtnAvancarContatos = false;
            $rootScope.viewBtnAvancar = false;
        };
        dragHoverExcel = function (pEvent) {
            pEvent.stopPropagation();
            pEvent.preventDefault();
            $scope.arquivoInvalido = false;
            $("#botaocontatosLista")[0].className = "btn-cinza btn-outline-none";
            $("#imgcontatosLista")[0].className = "background-colorContatosActive";
            $("#bordaImportacaoContatos")[0].className = "importacaocontatosPlan";
            $("#spanArrasteAqui")[0].className = "fontNormalImportant";
        };
        $scope.clickUpload = function () {
            $("#inptArquivo").click();
            $scope.arquivoInvalidoTextoEspecial = false;
            $scope.arquivoInvalidoLimiteRegistros = false;
        };
        dropExcel = function (pEvent) {
            dragHoverExcel(pEvent);
            var _arquivo = pEvent.dataTransfer.files[0];
            $("#nomedoArquivoExcel")[0].className = "importaçãoPlanilha2";
            $("#nomedoArquivoExcelX")[0].className = "inputplanilha2";
            $("#nomedoArquivoExcelX").show();
            $("#nomedoArquivoExcel").show();
            $rootScope.showBtnAvancarContatos = true;
            $rootScope.viewBtnAvancar = true;
            $scope.arquivoInvalidoTextoEspecial = false;
            $scope.arquivoInvalidoLimiteRegistros = false;
            $scope.nomeArquivoImportado = pEvent.dataTransfer.files[0].name;
            validarArquivoExcel(_arquivo);
            $scope.$apply();
        };
        duploCliqueTabela = function (pEvent) {
            if (pEvent.target.id.indexOf("drag") > -1) {
                var _componente = $(event.target).parent()[0];
                reposicionarBloco(_componente);
            }
        };
        reposicionarBloco = function (pComponente, pChild) {
            if (!pChild)
                pChild = $(event.target)[0];
            pChild.classList.remove("colunaAtiva");
            var _letra = pComponente.id.substring(3, 2);
            pComponente.removeChild(pChild);
            if (document.getElementById("divCampos").childElementCount < 6)
                document.getElementById("divCampos").appendChild(pChild);
            else
                document.getElementById("divCamposSegundo").appendChild(pChild);
        };
        dragOverTabela = function (pEvent) {
            pEvent.preventDefault();
        };
        dropTabela = function (pEvent) {
            pEvent.preventDefault();
            var _componente = pEvent.target;
            var _componentAntigo = null;
            var _campo = document.getElementById(pEvent.dataTransfer.getData("text"));
            if (pEvent.target.id.indexOf("drag") > -1) {
                _componente = $(event.target).parent()[0];
                _componente.removeChild($(event.target)[0]);
                _componentAntigo = _campo.parentElement;
                _componentAntigo.appendChild($(event.target)[0]);
            }
            else {
                _componentAntigo = _campo.parentElement;
                _componente.innerText = null;
            }
            _componente.appendChild(_campo);
            $(_campo).addClass("colunaAtiva");
        };
        dragStartTabela = function (pEvent) {
            pEvent.dataTransfer.setData("text", pEvent.target.id);
        };
        $(document).ready(function () {
            tinymce.editors = [];
            setTimeout(function () {
                tinymce.init({
                    selector: "#txtEditarDisparo",
                    language: "pt_BR",
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: "div",
                    height: 145,
                    width: 766,
                    left: 4,
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                        "textcolor",
                        "preview",
                        "table",
                    ],
                    toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ",
                    target_list: false,
                    content_css: ["//www.tinymce.com/css/codepen.min.css"],
                });
            }, 500);
        });
        $("#sltEditarDataDisparoTelaContatos").daterangepicker(__assign(__assign({}, DateRangePicker.config), { singleDatePicker: true }));
        $("#sltEditarDataDisparoTelaContatos").on("apply.daterangepicker", function (ev, picker) {
            var format = DateRangePicker.config.locale.format;
            $(this).val(picker.startDate.format(format));
            $scope.dataInicioFiltro = picker.startDate.toDate();
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
        });
        $("#sltEditarDataDisparoTelaContatos").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $scope.clickMetadadoEditarDisparo = function (ab) {
            if (tinymce.activeEditor) {
                var newNode = tinymce.activeEditor.getDoc().createElement("span");
                newNode.textContent = "/*" + $scope["metadadoSelecionadoEdicao"] + "*/";
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
                $scope["metadadoSelecionadoEdicao"] = undefined;
            }
        };
        $scope.abrirPopupFiltrar = function () {
            $("#idFundoPopupContatos").fadeIn();
            $scope.popupFiltrarCadencias = true;
        };
        $scope.fecharPopupFiltrarCadencias = function () {
            $("#idFundoPopupContatos").fadeOut();
            $scope.popupFiltrarCadencias = false;
        };
        $scope.resetCandleStick = function () {
            setTimeout(function () {
                $("#receberam")[0].value = "";
                $("#receberam").candlestick("reset");
                $("#abriram")[0].value = "";
                $("#abriram").candlestick("reset");
                $("#clicaram")[0].value = "";
                $("#clicaram").candlestick("reset");
                $("#responderam")[0].value = "";
                $("#responderam").candlestick("reset");
            }, 2000);
        };
        $scope.limparFiltros = function (pDados) {
            limparSelecaoContatos();
            if (pDados == "cad") {
                $scope.filtroCadenciaDetalheContato = "";
                $("#bolinhaSimFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
                $("#bolinhaNeutraFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
                $("#bolinhaNaoFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
            }
            else if (pDados == "con") {
                $scope.precisaConsularSegmentacoes = true;
                if (!$rootScope.administrativoOuVisao &&
                    $scope.usuarioFiltro == $rootScope.DadosCadastrais.objectIdLogin) {
                    $scope.usuarioFiltro = $rootScope.DadosCadastrais.objectIdLogin;
                }
                else {
                    $scope.usuarioFiltro = "";
                }
                $scope.contatoFiltroCad = null;
                $scope.segmentacaoFiltro = null;
                $scope.dataFinalFiltroContatos = null;
                $scope.dataInicioFiltroContatos = null;
                $scope.sltDataFiltroTelaContatos = null;
                $scope.dataFinalFiltroContatosSegmentacao = null;
                $scope.dataInicioFiltroContatosSegmentacao = null;
                $scope.sltDataFiltroTelaContatosDataSegmentacao = null;
                $scope.dataFinalFiltroContatosEnvioProgramacao = null;
                $scope.dataInicioFiltroContatosEnvioProgramacao = null;
                $scope.sltDataFiltroTelaContatosDataEnvioProgramacao = null;
                $scope.idCadenciaSegFiltro = null;
                $scope.cadenciaFiltroSeg = null;
                $("#cbAltaContatosInput").prop("checked", false);
                $("#cbMediaContatosInput").prop("checked", false);
                $("#cbBaixaContatosInput").prop("checked", false);
                $("#cbSemContatosInput").prop("checked", false);
                $("#bolinhaSimFiltroContato")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
                $("#bolinhaNeutraFiltroContato")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
                $("#bolinhaNaoFiltroContato")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Status contato";
                document.getElementById("idUsuariosContatosFiltro").innerText =
                    "Usuários";
                document.getElementById("idSegmentacaoFiltro").innerText =
                    "Segmentação";
                document.getElementById("idCadenciaSegFiltro").innerText = "Cadência";
                if ($("#sltDataFiltroTelaContatosDataEnvioProgramacao")[0].value !=
                    null ||
                    $("#sltDataFiltroTelaContatosDataSegmentacao")[0].value != null ||
                    $("#sltDataFiltroTelaContatos"[0].value != null)) {
                    $scope.resetarDatapicker();
                }
                setTimeout(function () {
                    $scope.consultarSegmentacoesComContatos();
                }, 10);
                $scope.txtNomeFiltros = "";
                $scope.escondeNomeFiltros = true;
                $("#receberam")[0].value = "";
                $("#receberam").candlestick("reset");
                $("#abriram")[0].value = "";
                $("#abriram").candlestick("reset");
                $("#clicaram")[0].value = "";
                $("#clicaram").candlestick("reset");
                $("#responderam")[0].value = "";
                $("#responderam").candlestick("reset");
            }
        };
        $scope.filtrarRespSim = function () {
            if ($("#bolinhaSimFiltro").hasClass("corImgClasResp"))
                $("#bolinhaSimFiltro")
                    .removeClass("corImgClasResp")
                    .addClass("corImgOriginalResp");
            else
                $("#bolinhaSimFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
        };
        $scope.filtrarRespNeutra = function () {
            if ($("#bolinhaNeutraFiltro").hasClass("corImgClasResp"))
                $("#bolinhaNeutraFiltro")
                    .removeClass("corImgClasResp")
                    .addClass("corImgOriginalResp");
            else
                $("#bolinhaNeutraFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
        };
        $scope.filtrarRespNao = function () {
            if ($("#bolinhaNaoFiltro").hasClass("corImgClasResp"))
                $("#bolinhaNaoFiltro")
                    .removeClass("corImgClasResp")
                    .addClass("corImgOriginalResp");
            else
                $("#bolinhaNaoFiltro")
                    .removeClass("corImgOriginalResp")
                    .addClass("corImgClasResp");
        };
        $scope.novaSegmentacao = function () {
            $scope.popupNovaSegmentacao = true;
            $("#txtNomeNovaSegmentacao").removeClass("inptComErro");
            $("#dvMensagemNomeSegmentacao").hide();
            if ($scope.popupDuplicarPara) {
                $("#fundoPopupNovaSegmentacao").fadeIn();
            }
            else {
                $("#idFundoPopupContatos").fadeIn();
            }
        };
        $scope.fecharPopupNovaSegmentacao = function () {
            $scope.segmentacaoAddContato = "";
            $scope.nomeNovaSegmentacao = "";
            $scope.popupNovaSegmentacao = false;
            if ($scope.popupDuplicarPara) {
                $("#fundoPopupNovaSegmentacao").fadeOut();
            }
            else {
                $("#idFundoPopupContatos").fadeOut();
            }
        };
        $scope.salvarNovaSegmentacao = function (pValidacaoOK) {
            var _nomeNovaSeg = $scope.nomeNovaSegmentacao;
            if (($scope.nomeNovaSegmentacao == null ||
                $scope.nomeNovaSegmentacao == "" ||
                !$scope.nomeNovaSegmentacao) &&
                !pValidacaoOK) {
                $("#txtNomeNovaSegmentacao").addClass("inptComErro");
                $("#dvMensagemNomeSegmentacao").fadeIn();
            }
            else {
                $http({
                    method: "POST",
                    url: "/segmentacoes/salvarSegmentacao",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        nome: pValidacaoOK
                            ? $scope.nomeNovaSegmentacaoLista
                            : $scope.nomeNovaSegmentacao,
                        origem: pValidacaoOK ? "L" : "A",
                    },
                }).then(function (rRetorno) {
                    if (_nomeDoArquivo || _extensaoArquivo) {
                        $http({
                            method: "POST",
                            url: "/listas/salvarNovaLista",
                            headers: {
                                "ramp3r-auth": "ramp3r-authorization",
                                "ramp3r-l": $rootScope.IDCrypto,
                            },
                            params: {
                                nome: "arquivos/" + _nomeDoArquivo + "." + _extensaoArquivo,
                                validarEmailsViaRamper: $scope.primeiraValidacao,
                                descobrirEmailsViaRamper: $scope.segundaValidacao,
                                bloquearEnviosDosContatos: $scope.terceiraValidacao,
                                objectIdSegmentacaoCriada: rRetorno.data._id,
                            },
                        }).then();
                    }
                    setTimeout(function () {
                        var elemento = pValidacaoOK
                            ? "#segmentacaoAddContatomap"
                            : "#segmentacaoAddContato";
                        for (var i = 0; i < $(elemento)[0].options.length; i++) {
                            try {
                                var objetoTemp = $(elemento)[0].options[i].value;
                                if (objetoTemp == rRetorno.data._id) {
                                    $scope.segmentacaoAddContatomap = objetoTemp;
                                    $(elemento)[0].selectedIndex = i;
                                }
                            }
                            catch (e) { }
                        }
                    }, 250);
                    $scope.consultarSegmentacoes(_nomeNovaSeg);
                    setTimeout(function () {
                        if ($scope.validarSegmentacao) {
                            $("#segmentacaoAddContato").val($scope.valorSegmentacao);
                        }
                    }, 2000);
                });
                if (!pValidacaoOK) {
                    $("#txtNomeNovaSegmentacao").removeClass("inptComErro");
                    $("#dvMensagemNomeSegmentacao").fadeOut();
                    $scope.fecharPopupNovaSegmentacao();
                }
            }
        };
        $scope.exportarContatos = function (enviarEmail) {
            if (enviarEmail) {
                $scope.esconderDivsPopupExportacao();
                $scope.exportarEnviarEmail = true;
            }
            $scope.qtdSelecionadosExportar = $scope.showBrowserContatos.filter(function (obj) {
                return obj.checkSelecionado;
            }).length;
            if ($scope.qtdSelecionadosExportar == "1") {
                $scope.pluralS = "";
                $scope.pluralOs = "";
                $scope.primeiraMsgExportar =
                    "Seu contato já está sendo processado e, ao final disto,";
            }
            else {
                $scope.pluralS = "s";
                $scope.primeiraMsgExportar =
                    "Seus contatos já estão sendo processados e, ao final disto,";
                $scope.pluralOs = "os";
            }
            if (_clickCheckTodos) {
                $scope.qtdSelecionadosExportar = $scope.total;
            }
            var contatos = [];
            if (_contatoDetalhes) {
                contatos.push(_contatoDetalhes);
            }
            else {
                $scope.showBrowserContatos
                    .filter(function (obj) {
                    return obj.checkSelecionado;
                })
                    .forEach(function (contato) {
                    contatos.push(contato);
                });
            }
            $http({
                method: "POST",
                url: "/contatos/exportar/csv",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: $scope.usuarioFiltro
                        ? "U"
                        : $rootScope.permissoes.tipoUsuario,
                    todos: _clickCheckTodos,
                    objectIdSegmentacoes: $scope.popupDuplicarPara
                        ? $("#segmentacaoFiltroContatos")[0].selectedOptions[0].value
                        : $("#segmentacaoAddContato")[0].selectedOptions[0].value,
                    buscaTexto: $scope.inpBuscarContatos,
                    dataInicio: $scope.dataInicioFiltroContatos
                        ? new Date($scope.dataInicioFiltroContatos.toDateString())
                        : "",
                    dataFim: $scope.dataFinalFiltroContatos
                        ? new Date($scope.dataFinalFiltroContatos.toDateString())
                        : "",
                    dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                        ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                        ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    status: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                    objectId: $scope.usuarioFiltro,
                    objectIdSegmentacao: !$scope.segmentacaoFiltro ||
                        $scope.segmentacaoFiltro == "n/a" ||
                        $scope.segmentacaoFiltro == "undefined"
                        ? ""
                        : $scope.segmentacaoFiltro,
                    semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                    objectIdDisparo: !$scope.cadenciaFiltroSeg ||
                        $scope.cadenciaFiltroSeg == "n/a" ||
                        $scope.cadenciaFiltroSeg == "undefined"
                        ? ""
                        : $scope.cadenciaFiltroSeg,
                    alta: $("#cbAltaContatosInput").is(":checked"),
                    media: $("#cbMediaContatosInput").is(":checked"),
                    baixa: $("#cbBaixaContatosInput").is(":checked"),
                    sem: $("#cbSemContatosInput").is(":checked"),
                    receberam: $("#receberam")[0].value,
                    abriram: $("#abriram")[0].value,
                    clicaram: $("#clicaram")[0].value,
                    responderam: $("#responderam")[0].value,
                    enviarEmailComLink: enviarEmail,
                },
                data: contatos.map(function (m) {
                    return m._id;
                }),
            }).then(function (response) {
                if (!enviarEmail) {
                    (function verificarDownload() {
                        setTimeout(function () {
                            $http({
                                method: "GET",
                                url: "/alerta/consultarLinkContatosExportados",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": $rootScope.IDCrypto,
                                },
                                params: {
                                    assunto: "Contatos_Exportados",
                                },
                            }).then(function (response) {
                                if (!response.data && $scope.popupExportarContatos)
                                    verificarDownload();
                                else {
                                    $scope.processandoArquivoParaDownload = false;
                                    $scope.linkParaDownload = response.data.linkDownload;
                                }
                            });
                        }, 5000);
                    })();
                }
            });
            var nContatos = _clickCheckTodos
                ? $scope.qtdSelecionadosExportar
                : contatos.length;
            var contatosTexto = nContatos > 1 ? "contatos" : "contato";
            mixpanel.track("Contatos | Exportou contatos", {
                Local_IP: $rootScope.ip,
            });
        };
        $scope.fecharPopupExportarContatos = function () {
            $scope.popupExportarContatos = false;
            $("#idFundoPopupContatos").fadeOut();
        };
        $scope.atualizarNomeSegmentacao = function () {
            $("#txtNomeNovaSegmentacao").removeClass("inptComErro");
            $("#dvMensagemNomeSegmentacao").fadeOut();
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.removerFiltroContatoCad = function () {
            $scope.semFiltroContatoCad = false;
            $("#tbleContatosCadencia").removeClass("emptyContato");
            $scope.semCadenciasFiltro = false;
            $scope.clickMenuDetalhes("c");
            $scope.buscarContato = "";
            $scope.inpBuscarContatos = "";
            $scope.limparFiltros("con");
        };
        $scope.removerFiltroContatoSeg = function () {
            $scope.semFiltroContatoCad = false;
            $scope.semContatosSeg = false;
            $("#tbleContatosCadencia").removeClass("emptyContato");
            $scope.semCadenciasFiltro = false;
            $scope.clickMenuDetalhes("s");
            $scope.semBuscaContatoSeg = false;
            $scope.buscarContato = "";
            $scope.inpBuscarContatos = "";
            $scope.limparFiltros("con");
        };
        $scope.filtrarCadenciasContatos = function () {
            $scope.semFiltroContatoCad = true;
            $("#tbleContatosCadencia").addClass("emptyContato");
            $scope.buscaOuFiltro = "Seu filtro";
            $scope.semBuscaContatoCad = false;
            $scope.fecharPopupFiltrarCadencias();
        };
        $scope.enviarIntegracaoGeral = function (pTipo) {
            $scope.tipoIntegracao = pTipo;
            $scope.contatoIds = $scope
                .getContactsToCRMIntegrate()
                .map(function (contato) { return contato._id; });
            if (pTipo == "C") {
                $scope.integracaoSelecionada = $scope.applicationCRM;
                $scope.integracaoSigla = $scope.integracaoCRM.application;
                $scope.integracaoApikey = $scope.integracaoCRM.apiKey;
            }
            else if (pTipo == "P") {
                $scope.integracaoSelecionada = $scope.applicationPreVendas;
                $scope.integracaoSigla = $scope.integracaoPreVendas.application;
                $scope.integracaoApikey = $scope.integracaoPreVendas.apiKey;
            }
            else if (pTipo == "I") {
                $scope.integracaoSelecionada = $scope.applicationInbound;
                $scope.integracaoSigla = $scope.integracaoInbound.application;
                $scope.integracaoApikey = $scope.integracaoInbound.apiKey;
            }
            $scope.queryParams = {
                todos: _clickCheckTodos,
                tipoDaChave: $scope.usuarioFiltro
                    ? "U"
                    : $rootScope.permissoes.tipoUsuario,
                buscaTexto: $scope.inpBuscarContatos,
                dataInicio: $scope.dataInicioFiltroContatos
                    ? new Date($scope.dataInicioFiltroContatos.toDateString())
                    : "",
                dataFim: $scope.dataFinalFiltroContatos
                    ? new Date($scope.dataFinalFiltroContatos.toDateString())
                    : "",
                dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                    ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                    : "",
                dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                    ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                    : "",
                dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                    ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                    : "",
                dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                    ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                    : "",
                status: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                objectId: $scope.usuarioFiltro,
                objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                alta: $("#cbAltaContatosInput").is(":checked"),
                media: $("#cbMediaContatosInput").is(":checked"),
                baixa: $("#cbBaixaContatosInput").is(":checked"),
                sem: $("#cbSemContatosInput").is(":checked"),
                receberam: $("#receberam")[0].value,
                abriram: $("#abriram")[0].value,
                clicaram: $("#clicaram")[0].value,
                responderam: $("#responderam")[0].value,
                // opcoesDynamics: $scope.opcoesIntegracoesDynamics365,
            };
            $scope.openModalEnviarContatosIntegracao = true;
        };
        $scope.onModalEnviarContatosIntegracaoFechar = function (status) {
            if (status == "Enviado") {
                $scope.linkAvisoSelecao("Limpar seleção");
            }
        };
        $scope.fecharPopupReterStatus = function () {
            $scope.popupReterStatus = false;
            $("#pagContatos").removeClass("blur");
            $("#sub-navbar").removeClass("blur");
            $(".navbar").removeClass("blur");
            $("#abrirSubMenuContatosEspecifico").hide();
            $("#dvDetalhesGeral").removeClass("blur");
            $(".dvDetalhes").removeClass("blur");
            $scope.recarregarTela();
        };
        $scope.converterContatoInteresse = function (pDados) {
            $scope.fecharSubMenu();
            $scope.ConverterContatoEm("P");
            mixpanel.track("Contatos | Converteu contatos para Interesse", {
                Local_IP: $rootScope.ip,
            });
            if ($scope.enviarCRM == true) {
                $scope.integracaoSelecionada = $scope.applicationInbound;
                $scope.enviarIntegracaoGeral("C");
            }
            else if ($scope.enviarPreVendas == true) {
                $scope.integracaoSelecionada = $scope.applicationInbound;
                $scope.enviarIntegracaoGeral("P");
            }
        };
        $scope.converterContatoSemInteresse = function (pDados) {
            $scope.fecharSubMenu();
            $scope.ConverterContatoEm("S");
            mixpanel.track("Contatos | Converteu contatos para Sem Interesse", {
                Local_IP: $rootScope.ip,
            });
            if ($scope.enviarInbound == true) {
                $scope.integracaoSelecionada = $scope.applicationInbound;
                $scope.enviarIntegracaoGeral("I");
            }
        };
        $scope.ConverterContatoEm = function (pStatus, pTexto) {
            $scope.qtdSelecionadosStatusRetidos = 0;
            $scope.contatosInteresseEnviarCrm = [];
            var contatos = [];
            var contatoStatusRetido = [];
            if (_contatoDetalhes) {
                contatos.push(_contatoDetalhes);
            }
            else {
                $scope.showBrowserContatos
                    .filter(function (obj) {
                    return obj.checkSelecionado;
                })
                    .forEach(function (contato) {
                    $scope.contatosInteresseEnviarCrm.push(contato);
                    if (contato.status === "Optout" ||
                        contato.status === "Processamento")
                        contatoStatusRetido.push(contato);
                    else
                        contatos.push(contato);
                });
            }
            if (contatoStatusRetido.length >= 1) {
                $scope.qtdSelecionadosStatusRetidos = contatoStatusRetido.length;
                if ($scope.qtdSelecionadosStatusRetidos == 1)
                    $scope.txtSelecionadosStatusRetidos = "";
                else
                    $scope.txtSelecionadosStatusRetidos = "s";
                $scope.popupReterStatus = true;
                $("#idPopupReterStatus").show();
                $("#abrirSubMenuContatosEspecifico").hide();
                $("#dvDetalhesGeral").addClass("blur");
            }
            $http({
                method: "POST",
                url: "/contatos/converterContato",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    status: pStatus,
                    tipoDaChave: $scope.usuarioFiltro
                        ? "U"
                        : $rootScope.permissoes.tipoUsuario,
                    todos: _clickCheckTodos,
                    objectIdContato: contatos.map(function (m) {
                        return m._id;
                    }),
                    buscaTexto: $scope.inpBuscarContatos,
                    dataInicio: $scope.dataInicioFiltroContatos
                        ? new Date($scope.dataInicioFiltroContatos.toDateString())
                        : "",
                    dataFim: $scope.dataFinalFiltroContatos
                        ? new Date($scope.dataFinalFiltroContatos.toDateString())
                        : "",
                    dataInicioSegmentacao: $scope.dataInicioFiltroContatosSegmentacao
                        ? new Date($scope.dataInicioFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataFimSegmentacao: $scope.dataFinalFiltroContatosSegmentacao
                        ? new Date($scope.dataFinalFiltroContatosSegmentacao.toDateString())
                        : "",
                    dataInicioEnvioProgramacao: $scope.dataInicioFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataInicioFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    dataFimEnvioProgramacao: $scope.dataFinalFiltroContatosEnvioProgramacao
                        ? new Date($scope.dataFinalFiltroContatosEnvioProgramacao.toDateString())
                        : "",
                    objectIdDisparo: $scope.cadenciaFiltroSeg == "n/a" ? "" : $scope.cadenciaFiltroSeg,
                    statusContato: $scope.contatoFiltroCad ? $scope.contatoFiltroCad : "",
                    objectId: $scope.usuarioFiltro,
                    objectIdSegmentacao: $scope.segmentacaoFiltro == "n/a" ? "" : $scope.segmentacaoFiltro,
                    semSegmentacao: $scope.segmentacaoFiltro == "n/a",
                    alta: $("#cbAltaContatosInput").is(":checked"),
                    media: $("#cbMediaContatosInput").is(":checked"),
                    baixa: $("#cbBaixaContatosInput").is(":checked"),
                    sem: $("#cbSemContatosInput").is(":checked"),
                    receberam: $("#receberam")[0].value,
                    abriram: $("#abriram")[0].value,
                    clicaram: $("#clicaram")[0].value,
                    responderam: $("#responderam")[0].value,
                },
            }).then(function () {
                var nContatos = contatos.length;
                var contatosTexto = nContatos > 1 ? "contatos" : "contato";
                if (pStatus == "I") {
                    mixpanel.track("Contatos | Converteu contatos para Bounce", {
                        Local_IP: $rootScope.ip,
                    });
                }
                var tempoEspera = contatos.length >= 1 && _clickCheckTodos == true ? 3000 : 100;
                $scope.initLoader();
                setTimeout(function () {
                    $scope.recarregarTela();
                }, tempoEspera);
            });
            limparSelecaoContatos();
        };
        $scope.recarregarTela = function () {
            limparSelecaoContatos();
            $scope.buscarContatos = "";
            $scope.inpBuscarContatos = "";
            $("#checkContatos").prop("checked", false);
            _clickCheckTodos = false;
            _pagina = -100;
            $scope.consultarContatos();
            $scope.totalSelecionados = 0;
            $scope.loaderAtivo = false;
            $scope.botaoBuscarVisivel = false;
        };
        $(document).mouseup(function (e) {
            var popupFiltroCont = $("#idPopupFiltrar");
            if (popupFiltroCont.is(e.target)) {
                popupFiltroCont.fadeOut();
                $scope.fecharPopupFiltrar();
            }
        });
        $scope.mostrarSegundaLinha = function (pCon) {
            if (!$rootScope.permissoes.contatos.seeMore) {
                return;
            }
            _contatoDetalhes = pCon;
            if (_contatoDetalhes.conSelecionado == "Selecionada") {
                _contatoDetalhes.conSelecionado = "";
                _contatoDetalhes.txtMais = "mais";
                $("#linha" + pCon._id).addClass("subirListaFora");
                $("#img" + pCon._id).removeClass("classeVirarImagem");
                setTimeout(function () {
                    $("#linha" + pCon._id)
                        .removeClass("subirListaFora")
                        .addClass("classeInvisivelCad");
                }, 300);
            }
            else {
                _contatoDetalhes.conSelecionado = "Selecionada";
                $("#linha" + pCon._id)
                    .removeClass("classeInvisivelCad")
                    .addClass("descerLinhaDentro");
                setTimeout(function () {
                    $("#linha" + pCon._id).removeClass("descerLinhaDentro");
                }, 500);
                $("#img" + pCon._id).addClass("classeVirarImagem");
                _contatoDetalhes.txtMais = "menos";
            }
        };
        var ordemNome = false;
        var ordemData = false;
        var ordemConfiabilidade = false;
        $scope.abrirOrdenarContato = function () {
            $scope.ordenarContatoAberto = !$scope.ordenarContatoAberto;
            if ($scope.ordenarContatoAberto) {
                $("#idOrdenarContato").show();
            }
            else
                $("#idOrdenarContato").hide();
        };
        $scope.ordenarContatos = function (pDados) {
            $scope.botaoBuscarVisivel = false;
            _campoOrdem = pDados;
            $scope.trocarOrdem = false;
            var textoOrd = document.getElementById("idTxtOrdenarContato");
            document.getElementById("txtOrdenacaoAlfabeticaContato").innerText =
                "A-Z";
            document.getElementById("txtOrdenacaoDataContato").innerText = "Data";
            document.getElementById("txtOrdenacaoConfiabilidadeContato").innerText =
                "Confiabilidade";
            if (pDados == "nome") {
                if (!ordemNome) {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = "A-Z";
                    document.getElementById("txtOrdenacaoAlfabeticaContato").innerText =
                        "Z-A";
                    _ordem = 1;
                }
                else {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = "Z-A";
                    document.getElementById("txtOrdenacaoAlfabeticaContato").innerText =
                        "A-Z";
                    _ordem = -1;
                }
                ordemNome = !ordemNome;
                ordemData = false;
                ordemConfiabilidade = false;
            }
            else if (pDados == "_id") {
                if (!ordemData) {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = "Menor data primeiro";
                    document.getElementById("txtOrdenacaoDataContato").innerText =
                        "Maior data";
                    _ordem = 1;
                }
                else {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = "Maior data primeiro";
                    document.getElementById("txtOrdenacaoDataContato").innerText =
                        "Menor data";
                    _ordem = -1;
                }
                ordemNome = false;
                ordemData = !ordemData;
                ordemConfiabilidade = false;
            }
            else if (pDados == "percAcerto") {
                if (!ordemConfiabilidade) {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = "Menor confiabilidade primeiro";
                    document.getElementById("txtOrdenacaoConfiabilidadeContato").innerText = "Maior confiabilidade";
                    _ordem = 1;
                }
                else {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = "Maior confiabilidade primeiro";
                    document.getElementById("txtOrdenacaoConfiabilidadeContato").innerText = "Menor confiabilidade";
                    _ordem = -1;
                }
                ordemNome = false;
                ordemData = false;
                ordemConfiabilidade = !ordemConfiabilidade;
            }
            else if (pDados == "aliasDisparosemails.dataEnvio") {
                if (!ordemConfiabilidade) {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = "Menor envio programdo";
                    document.getElementById("txtOrdenacaoDataEnvioProgramado").innerText =
                        "Menor envio programdo";
                    _ordem = 1;
                }
                else {
                    $("#imgOrdenacaoContato").attr("src", "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = "Maior envio programdo";
                    document.getElementById("txtOrdenacaoDataEnvioProgramado").innerText =
                        "Maior envio programdo";
                    _ordem = -1;
                }
                ordemNome = false;
                ordemData = false;
                ordemConfiabilidade = !ordemConfiabilidade;
            }
            $scope.tituloOrdenacao = textoOrd.innerText;
            $scope.abrirOrdenarContato();
            $scope.recarregarTela();
        };
        $(document).mouseup(function (e) {
            var divOrdenar = $("#idOrdenarContato");
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarContatoAberto = false;
                divOrdenar.hide();
            }
        });
        $scope.zerarCamposOrdenacao = function () {
            _campoOrdem = "percAcerto";
            var _ordem = -1;
            $scope.trocarOrdem = true;
            $scope.tituloOrdenacao = "";
            document.getElementById("idTxtOrdenarContato").innerText = "Ordenar";
            document.getElementById("txtOrdenacaoAlfabeticaContato").innerText =
                "A-Z";
            document.getElementById("txtOrdenacaoDataContato").innerText = "Data";
            document.getElementById("txtOrdenacaoConfiabilidadeContato").innerText =
                "Confiabilidade";
        };
        $("#idInptExcluir").bind("input propertychange", function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, "");
            $(this).val(texto);
        });
        $("#idEmailNovoContato").bind("input propertychange", function () {
            var texto = $(this).val();
            texto = texto.replace(/[\;\ ]/g, "");
            texto = texto.replace(/[\,\ ]/g, "");
            $(this).val(texto);
        });
        $scope.abrirFiltroStatusContatos = function () {
            $scope.filtroStatusContatosAberto = !$scope.filtroStatusContatosAberto;
            if ($scope.filtroStatusContatosAberto)
                $("#idFiltroStatusContatos").show();
            else
                $("#idFiltroStatusContatos").hide();
        };
        $scope.filtrarStatusContatos = function (pDados) {
            if (pDados == "I")
                document.getElementById("idStatusContatosFiltro").innerText = "Bounce";
            else if (pDados == "P")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Interesse";
            else if (pDados == "C")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Não recebem";
            else if (pDados == "N")
                document.getElementById("idStatusContatosFiltro").innerText = "Novo";
            else if (pDados == "O")
                document.getElementById("idStatusContatosFiltro").innerText = "Optout";
            else if (Array.isArray(pDados)) {
                if (pDados.indexOf("M") >= 0 ||
                    pDados.indexOf("NM") >= 0 ||
                    pDados.indexOf("SM") ||
                    pDados.indexOf("FM") >= 0) {
                    document.getElementById("idStatusContatosFiltro").innerText =
                        "Processamento";
                }
            }
            else if (pDados == "Q")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Quarentena";
            else if (pDados == "S")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Sem Interesse";
            else if (pDados == "B")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Já abordado";
            else if (pDados == "A")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Em abordagem";
            else if (pDados == "U")
                document.getElementById("idStatusContatosFiltro").innerText = "Neutro";
            else if (pDados == "R")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Respondido";
            else if (pDados == "DB")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Domínio bloqueado";
            else if (pDados == "SV")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "Sem validação";
            else if (pDados == "EI")
                document.getElementById("idStatusContatosFiltro").innerText =
                    "E-mail inválido";
            $scope.abrirFiltroStatusContatos();
            $scope.contatoFiltroCad = pDados;
            $scope.nomeStatusContatosFiltroSel = document.getElementById("idStatusContatosFiltro").innerText;
        };
        $scope.abrirFiltroUsuarios = function () {
            $scope.filtroUsuariosContatosAberto =
                !$scope.filtroUsuariosContatosAberto;
            if ($scope.filtroUsuariosContatosAberto) {
                $("#idFiltroUsuariosContatos").show();
                $("#idListaUsuContatos").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarUsuariosContatos").focus();
                }, 10);
            }
            else
                $("#idFiltroUsuariosContatos").hide();
        };
        $scope.selecionarUsuarioContatos = function (pDados) {
            $scope.usuarioFiltro = pDados._id;
            $scope.nomeUsuarioFiltroContatos = pDados.nome;
            document.getElementById("idUsuariosContatosFiltro").innerText =
                pDados.nome;
            $scope.filtroUsuariosContatosAberto = false;
            $scope.precisaConsularSegmentacoes = true;
            $("#idFiltroUsuariosContatos").hide();
            $scope.consultarCadenciasSimples(pDados._id);
            $scope.nomeSegmentacaoFiltroSelecionado = "";
            $scope.segmentacaoFiltro = null;
            document.getElementById("idSegmentacaoFiltro").innerText = "Segmentação";
        };
        $scope.abrirFiltroSegmentacao = function () {
            $scope.filtroSegmentacaoAberto = !$scope.filtroSegmentacaoAberto;
            if ($scope.filtroSegmentacaoAberto) {
                $("#idFiltroSegmentacao").show();
                $("#idListaSegmentacao").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("idInptFiltroSegmentacao").focus();
                }, 10);
                $scope.consultarSegmentacoesComContatos();
            }
            else
                $("#idFiltroSegmentacao").hide();
        };
        $scope.selecionarSegmentacao = function (pDados) {
            if (pDados || pDados != null) {
                $scope.segmentacaoFiltro = pDados._id.objectIdSegmentacao;
                document.getElementById("idSegmentacaoFiltro").innerText =
                    pDados._id.nomeSegmentacao;
            }
            else {
                $scope.segmentacaoFiltro = "n/a";
                document.getElementById("idSegmentacaoFiltro").innerText =
                    "Sem segmentação";
                $scope.abrirFiltroSegmentacao();
            }
            $scope.nomeSegmentacaoFiltroSelecionado =
                pDados != undefined ? pDados._id.nomeSegmentacao : "Sem segmentação";
            $scope.abrirFiltroSegmentacao();
        };
        $scope.abrirSegmentacaoDuplicarPara = function () {
            $scope.segmentacaoDuplicarParaAberto =
                !$scope.segmentacaoDuplicarParaAberto;
            if ($scope.segmentacaoDuplicarParaAberto) {
                $("#idSegmentacaoDuplicarPara").show();
                $("#idListaSegmentacaoDuplicarPara").animate({ scrollTop: 0 }, 10);
                $scope.buscarSegmentacaoDuplicarPara = "";
                setTimeout(function () {
                    document.getElementById("idInptSegmentacaoDuplicarPara").focus();
                }, 10);
                $scope.consultarSegmentacoesComContatos();
            }
            else
                $("#idSegmentacaoDuplicarPara").hide();
        };
        $scope.selecionarSegmentacaoDuplicarPara = function (pDados) {
            if (pDados || pDados != null) {
                $scope.segmentacaoFiltroContatos = pDados._id;
                $scope.nomeSegDuplicarPara = pDados.nome;
                document.getElementById("idSpanSegmentacaoDuplicarPara").innerText =
                    pDados.nome;
            }
            else {
                $scope.segmentacaoFiltroContatos = "";
                document.getElementById("idSpanSegmentacaoDuplicarPara").innerText =
                    "Sem segmentação";
                $scope.abrirSegmentacaoDuplicarPara();
            }
            $scope.abrirSegmentacaoDuplicarPara();
        };
        $(document).mouseup(function (e) {
            var filtroStatus = $("#idFiltroStatusContatos");
            var filtroUsuario = $("#idFiltroUsuariosContatos");
            var inputFiltroUsuario = $("#buscarUsuariosContatos");
            var filtroCad = $("#idFiltroSegmentacao");
            var filtroSegDuplicarPara = $("#idSegmentacaoDuplicarPara");
            var filtroEnviarPipeline = $("#idFiltroEnviarPipeline");
            var filtroEnviarEstagio = $("#idFiltroEnviarEstagio");
            var filtroEnviarUsuario = $("#idFiltroEnviarUsuarioPipe");
            var filtroAbrirCadencia = $("#idFiltroCadenciaContato");
            if (!filtroAbrirCadencia.is(e.target) &&
                filtroAbrirCadencia.has(e.target).length === 0) {
                $scope.filtroCadenciaSegAberto = false;
                filtroAbrirCadencia.hide();
            }
            if (!filtroStatus.is(e.target) &&
                filtroStatus.has(e.target).length === 0) {
                $scope.filtroStatusContatosAberto = false;
                filtroStatus.hide();
            }
            if (!filtroUsuario.is(e.target) &&
                filtroUsuario.has(e.target).length === 0) {
                $scope.filtroUsuariosContatosAberto = false;
                filtroUsuario.hide();
            }
            if (inputFiltroUsuario.is(e.target)) {
                $scope.filtroUsuariosContatosAberto = true;
            }
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                $scope.filtroSegmentacaoAberto = false;
                filtroCad.hide();
            }
            if (!filtroSegDuplicarPara.is(e.target) &&
                filtroSegDuplicarPara.has(e.target).length === 0) {
                $scope.segmentacaoDuplicarParaAberto = false;
                filtroSegDuplicarPara.hide();
            }
            if (!filtroEnviarPipeline.is(e.target) &&
                filtroEnviarPipeline.has(e.target).length === 0) {
                $scope.filtroEnviarPipelineAberto = false;
                filtroEnviarPipeline.hide();
            }
            if (!filtroEnviarEstagio.is(e.target) &&
                filtroEnviarEstagio.has(e.target).length === 0) {
                $scope.filtroEnviarEstagioAberto = false;
                filtroEnviarEstagio.hide();
            }
            if (!filtroEnviarUsuario.is(e.target) &&
                filtroEnviarUsuario.has(e.target).length === 0) {
                $scope.filtroEnviarUsuarioPipeAberto = false;
                filtroEnviarUsuario.hide();
            }
        });
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = "";
            if ($("#sltDataFiltroTelaContatos")[0].value != "") {
                $scope.txtNomeFiltros =
                    "Filtrado por " + $("#sltDataFiltroTelaContatos")[0].value;
                contagem++;
            }
            if ($("#sltDataFiltroTelaContatosDataSegmentacao")[0].value != "") {
                $scope.txtNomeFiltros =
                    "Filtrado por " +
                        $("#sltDataFiltroTelaContatosDataSegmentacao")[0].value;
                contagem++;
            }
            if ($("#sltDataFiltroTelaContatosDataEnvioProgramacao")[0].value != "") {
                $scope.txtNomeFiltros =
                    "Filtrado por " +
                        $("#sltDataFiltroTelaContatosDataEnvioProgramacao")[0].value;
                contagem++;
            }
            if ($scope.contatoFiltroCad != "" && $scope.contatoFiltroCad != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomeStatusContatosFiltroSel)
                    : ($scope.txtNomeFiltros +=
                        " > " + $scope.nomeStatusContatosFiltroSel);
                contagem++;
            }
            if ($scope.usuarioFiltro != "" &&
                $rootScope.permissoes.tipoUsuario != "U" &&
                $scope.usuarioFiltro != undefined) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomeUsuarioFiltroContatos)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomeUsuarioFiltroContatos);
                contagem++;
            }
            if ($scope.segmentacaoFiltro != "" && $scope.segmentacaoFiltro != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomeSegmentacaoFiltroSelecionado)
                    : ($scope.txtNomeFiltros +=
                        " > " + $scope.nomeSegmentacaoFiltroSelecionado);
                contagem++;
            }
            if ($scope.cadenciaFiltroSeg != "" && $scope.cadenciaFiltroSeg != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomeCadenciaFiltroSeg)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomeCadenciaFiltroSeg);
                contagem++;
            }
            if ($("#cbAltaContatosInput").is(":checked") ||
                $("#cbMediaContatosInput").is(":checked") ||
                $("#cbBaixaContatosInput").is(":checked") ||
                $("#cbSemContatosInput").is(":checked")) {
                $scope.montarNomeFiltroConfiabilidades();
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.txtFiltroConfiabilidade)
                    : ($scope.txtNomeFiltros += " > " + $scope.txtFiltroConfiabilidade);
                contagem++;
            }
            if ($("#abriram")[0].value != "") {
                var txtAbriram = $("#abriram")[0].value == "n"
                    ? "Não abriram e-mail"
                    : "Abriram e-mail";
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + txtAbriram)
                    : ($scope.txtNomeFiltros += " > " + txtAbriram);
                contagem++;
            }
            if ($("#clicaram")[0].value != "") {
                var txtClicaram = $("#clicaram")[0].value == "n"
                    ? "Não clicaram e-mail"
                    : "Clicaram e-mail";
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + txtClicaram)
                    : ($scope.txtNomeFiltros += " > " + txtClicaram);
                contagem++;
            }
            if ($("#receberam")[0].value != "") {
                var txtReceberam = $("#receberam")[0].value == "n"
                    ? "Não receberam e-mail"
                    : "Receberam e-mail";
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + txtReceberam)
                    : ($scope.txtNomeFiltros += " > " + txtReceberam);
                contagem++;
            }
            if ($("#responderam")[0].value != "") {
                var txtResponderam = $("#responderam")[0].value == "n"
                    ? "Não responderam e-mail"
                    : "Responderam e-mail";
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + txtResponderam)
                    : ($scope.txtNomeFiltros += " > " + txtResponderam);
                contagem++;
            }
            if (contagem > 0) {
                $scope.escondeNomeFiltros = false;
            }
        };
        $scope.montarNomeFiltroConfiabilidades = function () {
            var contagemConf = 0;
            var txtTemporario = "Confiabilidade ";
            if ($("#cbAltaContatosInput").is(":checked")) {
                txtTemporario += "(Alta";
                contagemConf++;
            }
            if ($("#cbMediaContatosInput").is(":checked")) {
                contagemConf == 0
                    ? (txtTemporario += "(Média")
                    : (txtTemporario += ", média");
                contagemConf++;
            }
            if ($("#cbBaixaContatosInput").is(":checked")) {
                contagemConf == 0
                    ? (txtTemporario += "(Baixa")
                    : (txtTemporario += ", baixa");
                contagemConf++;
            }
            if ($("#cbSemContatosInput").is(":checked")) {
                contagemConf == 0
                    ? (txtTemporario += "(Sem")
                    : (txtTemporario += ", sem");
                contagemConf++;
            }
            if ((contagemConf = 0)) {
                $scope.txtFiltroConfiabilidade = "";
            }
            else {
                $scope.txtFiltroConfiabilidade = txtTemporario + ")";
            }
        };
        $(document).mouseup(function (e) {
            var fundoPopupNovo = $("#idFundoPopupContatos");
            var fundoPopupSegmentacao = $("#fundoPopupNovaSegmentacao");
            if (fundoPopupNovo.is(e.target)) {
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover(false);
                }
                else if ($scope.popupExportarContatos) {
                    $scope.fecharPopupExportarContatos();
                }
                else if ($scope.popupDuplicarPara) {
                    $scope.fecharPopupDuplicarPara(false);
                }
                else if ($scope.popupFiltrarCadencias) {
                    $scope.fecharPopupFiltrarCadencias();
                }
                else if ($scope.popupDominio) {
                    $scope.ficarNaTela();
                }
                else if ($scope.popupNovaSegmentacao) {
                    $scope.fecharPopupNovaSegmentacao();
                }
                else if ($scope.popupRemoveContatoSeg) {
                    $scope.fecharPopupRemoverSeg();
                }
                else if ($scope.popupOpcoesPipedrive) {
                    $scope.popupOpcoesPipedrive = false;
                    fundoPopupNovo.fadeOut();
                }
                $scope.$apply();
            }
            if (fundoPopupSegmentacao.is(e.target)) {
                if ($scope.popupDuplicarPara) {
                    $scope.fecharPopupNovaSegmentacao();
                }
                $scope.$apply();
            }
        });
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                var fundoPopupNovo = $("#idFundoPopupContatos");
                var fundoPopupSegmentacao = $("#fundoPopupNovaSegmentacao");
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover(false);
                }
                else if ($scope.popupExportarContatos) {
                    $scope.fecharPopupExportarContatos();
                }
                else if ($scope.popupDuplicarPara) {
                    $scope.fecharPopupDuplicarPara(false);
                }
                else if ($scope.popupFiltrarCadencias) {
                    $scope.fecharPopupFiltrarCadencias();
                }
                else if ($scope.popupDominio) {
                    $scope.ficarNaTela();
                }
                else if ($scope.popupNovaSegmentacao) {
                    $scope.fecharPopupNovaSegmentacao();
                }
                else if ($scope.popupRemoveContatoSeg) {
                    $scope.fecharPopupRemoverSeg();
                }
                $scope.$apply();
            }
            if (fundoPopupSegmentacao) {
                if (fundoPopupSegmentacao.is(e.target)) {
                    if ($scope.popupDuplicarPara) {
                        $scope.fecharPopupNovaSegmentacao();
                    }
                    $scope.$apply();
                }
            }
        });
        $scope.fecharSubMenu = function () {
            $("#abrirSubMenuContatos").hide();
            $scope.subMenuDeContatos = false;
            $("#abrirSubMenuContatosEspecifico").hide();
            $scope.subMenuCadenciasEspecifica = false;
        };
        $scope.montarClasses = function () {
            $scope.classeHoverCrm = "";
            $scope.classeHoverPreVendas = "";
            $scope.classeHoverInbound = "";
            if ($scope.enviarCRM) {
                if (!$scope.enviarPreVendas && !$scope.enviarInbound)
                    $scope.classeHoverCrm = "hoverDivCimaBaixo";
                else
                    $scope.classeHoverCrm = "hoverPrimeiraDiv";
            }
            if ($scope.enviarPreVendas) {
                if (!$scope.enviarCRM && !$scope.enviarInbound)
                    $scope.classeHoverPreVendas = "hoverDivCimaBaixo";
                else if (!$scope.enviarCRM && $scope.enviarInbound)
                    $scope.classeHoverPreVendas = "hoverPrimeiraDiv";
                else if ($scope.enviarCRM && !$scope.enviarInbound)
                    $scope.classeHoverPreVendas = "hoverUltimaDiv";
                else
                    $scope.classeHoverPreVendas = "";
            }
            if ($scope.enviarInbound) {
                if (!$scope.enviarCRM && !$scope.enviarPreVendas)
                    $scope.classeHoverInbound = "hoverDivCimaBaixo";
                else
                    $scope.classeHoverInbound = "hoverUltimaDiv";
            }
        };
        $("#dvAdicionarContatos").hide();
        $("#dvDetalhesGeral").hide();
        $scope.esconderDivsPopupExportacao = function () {
            $scope.escolhaExportacao = false;
            $scope.exportarEnviarEmail = false;
            $scope.baixarExportacao = false;
            $scope.exportarEnviarEmailConcluido = false;
        };
        $scope.abrirPopupExportarContatos = function () {
            $scope.esconderDivsPopupExportacao();
            $scope.escolhaExportacao = true;
            $scope.popupExportarContatos = true;
            $("#idFundoPopupContatos").fadeIn();
        };
        $scope.fazerDownloadArquivo = function () {
            window.open($scope.linkParaDownload, "_blank");
            $scope.esconderDivsPopupExportacao();
            $scope.exportarEnviarEmailConcluido = true;
        };
        $scope.baixarContatos = function () {
            $scope.esconderDivsPopupExportacao();
            $scope.baixarExportacao = true;
            $scope.processandoArquivoParaDownload = true;
            $http({
                method: "DELETE",
                url: "/alerta/removerLinkContatosExportados",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    assunto: "Contatos_Exportados",
                },
            }).then(function (response) {
                $scope.exportarContatos(false);
                $scope.baixarExportacao = true;
            });
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == "filtro") {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNav");
                    $("#menuNav").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNav");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNav");
                    $("#menuNav").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNav");
                }
            }
        };
        $scope.consultarCadenciasSimples = function (pDados) {
            var contatoSelecionado = "";
            try {
                contatoSelecionado = pDados;
            }
            catch (e) {
                contatoSelecionado = "";
            }
            $scope.listaCadencias = [];
            $http({
                method: "GET",
                url: "/disparos/consultarCadenciasSimples",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: contatoSelecionado != "" ? "U" : $rootScope.permissoes.tipoUsuario,
                    objectId: contatoSelecionado,
                },
            }).then(function (response) {
                response.data.forEach(function (item) {
                    $scope.listaCadencias.push(item);
                });
                $scope.listaCadencias.sort(function (a, b) {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                });
            });
        };
        $scope.abrirFiltroCadenciaSeg = function () {
            $scope.filtroCadenciaSegAberto = !$scope.filtroCadenciaSegAberto;
            if ($scope.filtroCadenciaSegAberto) {
                $("#idFiltroCadenciaContato").show();
                $("#idListaCadenciaContato").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("idCadenciaFiltroContato").focus();
                }, 10);
                idCadenciaFiltroContato;
            }
            else
                $("#idFiltroCadenciaContato").hide();
        };
        $scope.selecionarCadenciaSeg = function (pDados) {
            if (pDados || pDados != null) {
                $scope.cadenciaFiltroSeg = pDados._id;
                document.getElementById("idCadenciaSegFiltro").innerText = pDados.nome;
            }
            else {
                $scope.cadenciaFiltroSeg = "n/a";
                document.getElementById("idCadenciaSegFiltro").innerText =
                    "Sem cadência";
            }
            $scope.nomeCadenciaFiltroSeg = pDados.nome;
            $scope.abrirFiltroCadenciaSeg();
        };
        $scope.resetarDatapicker = function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            $("#sltDataFiltroTelaContatosDataSegmentacao").val("");
            (_b = (_a = $("#sltDataFiltroTelaContatosDataSegmentacao")) === null || _a === void 0 ? void 0 : _a.data("daterangepicker")) === null || _b === void 0 ? void 0 : _b.setStartDate(moment());
            (_d = (_c = $("#sltDataFiltroTelaContatosDataSegmentacao")) === null || _c === void 0 ? void 0 : _c.data("daterangepicker")) === null || _d === void 0 ? void 0 : _d.setEndDate(moment());
            $("#sltDataFiltroTelaContatosDataEnvioProgramacao").val("");
            (_f = (_e = $("#sltDataFiltroTelaContatosDataEnvioProgramacao")) === null || _e === void 0 ? void 0 : _e.data("daterangepicker")) === null || _f === void 0 ? void 0 : _f.setStartDate(moment());
            (_h = (_g = $("#sltDataFiltroTelaContatosDataEnvioProgramacao")) === null || _g === void 0 ? void 0 : _g.data("daterangepicker")) === null || _h === void 0 ? void 0 : _h.setEndDate(moment());
            $("#sltDataFiltroTelaContatos").val("");
            (_k = (_j = $("#sltDataFiltroTelaContatos")) === null || _j === void 0 ? void 0 : _j.data("daterangepicker")) === null || _k === void 0 ? void 0 : _k.setStartDate(moment());
            (_m = (_l = $("#sltDataFiltroTelaContatos")) === null || _l === void 0 ? void 0 : _l.data("daterangepicker")) === null || _m === void 0 ? void 0 : _m.setEndDate(moment());
        };
        $(document).mousedown(function (event) {
            var popupsAdicionar = document.getElementById("popup-adicionar-tela-contato");
            var btnAbrirAdicionar = document.getElementById("btn-abrir-adicionar");
            var clickPopupAdicionar = function (e) {
                return e && e.contains(event.target);
            };
            if (!clickPopupAdicionar(popupsAdicionar) &&
                !clickPopupAdicionar(btnAbrirAdicionar)) {
                $scope.mostraAdicionar = false;
                $scope.$apply();
            }
        });
        $scope.togglePopUpAdicionar = function () {
            $scope.mostraAdicionar = !$scope.mostraAdicionar;
        };
        $scope.adicionarSegmentacao = function () {
            $scope.mostrarAdicionarSegmentacao = true;
        };
        $scope.fecharAdicionarSegmentacao = function () {
            $scope.mostrarAdicionarSegmentacao = false;
            $scope.precisaConsularSegmentacoes = true;
        };
        $scope.abrirOutrasInformacoesForm = function () {
            document
                .querySelector(".setaOutrasInformacoes")
                .classList.toggle("setaRotate");
        };
        $scope.initLoader = function () {
            $rootScope.loaderAtivo = true;
            $("#pagContatos").addClass("blur");
            $("#menuPrincipal").addClass("blur");
        };
        $scope.selecionarUsuarioPadrao = function () {
            if ($scope.usuarioLogadoTemPermissaoAdministrador) {
                var usuarioLogado = {
                    nome: $rootScope.nomeUsuarioLogado,
                    _id: $rootScope.DadosCadastrais.objectIdLogin,
                };
                $scope.selecionarUsuarioContatos(usuarioLogado);
                $scope.filtrar();
            }
        };
        $scope.onInit = function () {
            $scope.initLoader();
            if ($rootScope.DadosCadastrais) {
                $scope.verificarIntegracao();
                $scope.consultarUsuarios();
                if (!$scope.usuarioLogadoTemPermissaoAdministrador) {
                    $scope.consultarContatos();
                    $scope.consultarCadenciasSimples();
                }
            }
        };
        $scope.onInit();
        $scope.$watch("DadosCadastrais", function (value, oldValue) {
            var objectIdLoginAtual = value && value.objectIdLogin ? value.objectIdLogin : "";
            var objectIdLoginAnterior = oldValue && oldValue.objectIdLogin ? oldValue.objectIdLogin : "";
            var houveTrocaDeConta = objectIdLoginAtual !== objectIdLoginAnterior;
            if (houveTrocaDeConta) {
                $scope.onInit();
            }
        });
    },
]);
