angular.module("ramperv3").controller("MenuController", [
    "$rootScope",
    "$scope",
    "$http",
    "$location",
    "$locale",
    "PermissionamentoService",
    "AgenciaService",
    function ($rootScope, $scope, $http, $location, $locale, PermissionamentoService, AgenciaService) {
        var _urlAcesso = document.URL.substr(7, 6);
        $locale.NUMBER_FORMATS.GROUP_SEP = ".";
        $rootScope.popupTrocarConta = false;
        $scope.mountMainMenu = function () {
            var _verificarCookie = lerCookie("login_ramperv3_1910_1989_2017");
            if ($rootScope.permissoes) {
                $scope.mainMenu = [
                    {
                        label: "Prospectar",
                        permission: $rootScope.permissoes.contatos.screen ||
                            $rootScope.permissoes.segmentacoes.screen,
                        submenus: [
                            {
                                label: "Descobrir Contatos",
                                link: "#/prospectar/descobrir-contatos",
                                onClick: function () { return $rootScope.redirectDescobrirContatos(); },
                                permission: $rootScope.permissoes.descobrirContatos.screen,
                            },
                            {
                                label: "Segmentação de Contatos",
                                link: "#/prospectar/segmentacao-de-contatos",
                                onClick: function () { return $rootScope.redirectSegmentacoes(); },
                                permission: $rootScope.permissoes.segmentacoes.screen,
                            },
                            {
                                label: "Lista de Contatos",
                                link: "#/prospectar/lista-de-contatos",
                                onClick: function () { return $rootScope.redirectContatos(); },
                                permission: $rootScope.permissoes.contatos.screen,
                            },
                        ],
                    },
                    {
                        label: "Abordar",
                        permission: $rootScope.permissoes.cadencias.screen ||
                            $rootScope.permissoes.respostas.screen ||
                            $rootScope.permissoes.templates.screen,
                        submenus: [
                            {
                                label: "Template de e-mail",
                                link: "#/abordar/templates-de-email",
                                onClick: function () { return $rootScope.redirectTemplates(); },
                                permission: $rootScope.permissoes.templates.screen,
                            },
                            {
                                label: "Cadência de envios",
                                link: "#/abordar/cadencias-de-envios",
                                onClick: function () { return $rootScope.redirectCadencias(); },
                                permission: $rootScope.permissoes.cadencias.screen,
                            },
                            {
                                label: "Respostas",
                                link: "#/abordar/respostas",
                                onClick: function () { return $rootScope.redirectRespostas(); },
                                permission: $rootScope.permissoes.respostas.screen,
                            },
                        ],
                    },
                    {
                        label: "Conectar",
                        permission: $rootScope.permissoes.ligacoes.screen ||
                            $rootScope.permissoes.chat.screen,
                        submenus: [
                            {
                                label: "Janela de ligação",
                                link: "#/conectar",
                                onClick: function () { return $rootScope.redirectConexao(); },
                                permission: $rootScope.permissoes.ligacoes.screen,
                            },
                        ],
                    },
                    {
                        label: "Analisar",
                        permission: $rootScope.permissoes.relatorios.screen ||
                            $rootScope.permissoes.times.screen,
                        submenus: [
                            {
                                label: "Time",
                                link: "#/analisar/time",
                                onClick: function () { return $rootScope.redirectTime(); },
                                permission: $rootScope.permissoes.times.screen,
                            },
                            {
                                label: "Relatório",
                                link: "#/relatorio/analise-de-produtividade",
                                onClick: function () { return $rootScope.redirectRelatorio(); },
                                permission: $rootScope.permissoes.relatorios.screen,
                            },
                        ],
                    },
                ];
                $scope.profileMenu = [
                    {
                        label: "Perfil",
                        permission: true,
                        link: "#/v2/perfil",
                        onClick: function () { return true; },
                        target: "_self",
                    },
                    {
                        label: "Configurações",
                        permission: $rootScope.permissoes.configuracoes.screen,
                        link: "".concat($location.protocol(), "://").concat($location.host(), ":").concat($location.port(), "/app2/#/inicio"),
                        onClick: function () { return $scope.redirectconfiguracoesEmail(); },
                        target: "_self",
                    },
                    {
                        label: "Alternar Conta",
                        permission: $rootScope === null || $rootScope === void 0 ? void 0 : $rootScope.mostrarTrocarConta,
                        link: "",
                        onClick: function () { return $scope.trocarContaAbrir(); },
                        target: "_self",
                    },
                    {
                        label: "Integração",
                        permission: $rootScope.permissoes.integracoes.screen,
                        link: "#/integracoes",
                        onClick: function () { return $scope.redirectIntegracoes(); },
                        target: "_self",
                    },
                    {
                        label: "EAD Ramper",
                        permission: $rootScope.permissoes.edools.access,
                        link: "",
                        onClick: function () { return $scope.sendEdoolsClickTracking(); },
                        target: "_blank",
                    },
                    {
                        label: "Base de Conhecimento",
                        permission: true,
                        link: "https://ramperprospect.movidesk.com/kb",
                        onClick: function () { return $rootScope.sendKnowledgeBaseClickTracking(); },
                        target: "_blank",
                    },
                    {
                        label: "Webinars de treinamento",
                        permission: true,
                        link: "https://ramper.com.br/webinars-exclusivos/",
                        onClick: function () { return $rootScope.sendWebinarClickTracking(); },
                        target: "_blank",
                    },
                    {
                        label: "Instalar extensão do Chrome",
                        permission: $rootScope.permissoes.tipoUsuario !== "V",
                        link: "https://chrome.google.com/webstore/detail/ramper/mkoijmjnaobkmmhlpfilggdpnjnjoebm",
                        onClick: function () { return true; },
                        target: "_blank",
                    },
                    {
                        label: "Créditos e pagamentos",
                        permission: $rootScope.permissoes.tipoUsuario === "A",
                        link: "#/v2/creditos-pagamentos",
                        onClick: function () { return true; },
                        target: "_self",
                    },
                    {
                        label: "Sair",
                        permission: true,
                        link: "",
                        onClick: function () { return $scope.logout(); },
                        target: "_self",
                    },
                ];
            }
        };
        $scope.temAcessoConectar = function () {
            return ($rootScope.Plano == "P" ||
                $rootScope.possuiConversacao ||
                $rootScope.temDireitoConexao);
        };
        $rootScope.redirectContatos = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuContatosDestaque").addClass("borderBottomNovo");
        };
        $rootScope.sendKnowledgeBaseClickTracking = function () {
            mixpanel.track("Entrou na base de conhecimento", {
                Local_IP: $rootScope.ip,
            });
        };
        $rootScope.sendWebinarClickTracking = function () {
            mixpanel.track("Clicou no link webinars", { Local_IP: $rootScope.ip });
        };
        $rootScope.sendEdoolsClickTracking = function () {
            mixpanel.track("EAD Ramper | Entrou no EAD", { Local_IP: $rootScope.ip });
            window.open("https://rampersoftware.notion.site/2ab330c373dd4c5ab7d00ff8790fb9ba?v=2acad508f1e0476faf462c02f5c487e5", "_blank");
        };
        $rootScope.redirectCadencias = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuCadenciasDestaque").addClass("borderBottomNovo");
            $location.path("/abordar/cadencias-de-envios");
        };
        $rootScope.redirectTime = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuTimesDestaque").addClass("borderBottomNovo");
            $location.path("/analisar/time");
        };
        $rootScope.redirectRelatorio = function () {
            $scope.removerSubMenus();
        };
        $scope.redirectIntegracoes = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            if ($rootScope.habilitarUpsell != null) {
                mixpanel.track("Growth001 | Clicou na janela de integração", {
                    Local_IP: $rootScope.ip,
                    NomeEmpresa: $rootScope.empresaLogado,
                    EmailUsuario: $rootScope.emailUsuario,
                });
            }
            $location.path("/integracoes");
        };
        $scope.redirectconfiguracoesEmail = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            var url = $location.protocol() +
                "://" +
                $location.host() +
                ":" +
                $location.port() +
                "/app2/#/inicio";
            window.location.href = url;
        };
        $rootScope.redirectConexao = function () {
            if (($rootScope.exibirExperimentoUpsell === null ||
                $rootScope.exibirExperimentoUpsell) &&
                !$scope.temAcessoConectar()) {
                mixpanel.track("Growth002 | Clicou na janela de ligação", {
                    Local_IP: $rootScope.ip,
                    NomeEmpresa: $rootScope.empresaLogado,
                    EmailUsuario: $rootScope.emailUsuario,
                });
            }
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuConectarDestaque").addClass("borderBottomNovo");
            $rootScope.mostrarMenu = true;
            $location.path("/conectar");
        };
        $rootScope.redirectRespostas = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuRespostasDestaque").addClass("borderBottomNovo");
            $location.path("/abordar/respostas");
        };
        $rootScope.redirectInicio = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $location.path("/inicio");
        };
        $rootScope.redirectSegmentacoes = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuSegmentacoesDestaque").addClass("borderBottomNovo");
        };
        $rootScope.redirectDescobrirContatos = function () {
            mixpanel.track("DESCOBRIR CONTATOS | Entrou na funcionalidade", {
                Local_IP: $rootScope.ip,
            });
            $scope.destroyInbox();
            $scope.removerSubMenus();
        };
        $rootScope.redirectTemplates = function () {
            $scope.destroyInbox();
            $scope.removerSubMenus();
            $("#subMenuTemplatesDestaque").addClass("borderBottomNovo");
        };
        $scope.logout = function (trocaAgencia) {
            $scope.removerSubMenus();
            var _cookie = "login_ramperv3_1910_1989_2017";
            apagarCookie(_cookie);
            if (!trocaAgencia) {
                var _cookieAgencia = "loginAgencia_ramperv3_2018";
                apagarCookie(_cookieAgencia);
            }
            var _cookieExtensao = "ramperExtensao_1910_1989_2017";
            apagarCookie(_cookieExtensao, ".ramper.com.br");
            $http({
                method: "GET",
                url: "/logout",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": "0881d3992f",
                },
            }).then(function () {
                if (!trocaAgencia) {
                    location.reload();
                }
            });
        };
        $scope.trocarContaAbrir = function () {
            $("#dvBlur").addClass("blur");
            $rootScope.popupTrocarConta = true;
        };
        $scope.trocarContaFechar = function () {
            $("#dvBlur").removeClass("blur");
            $rootScope.popupTrocarConta = false;
        };
        $scope.trocarConta = function (login) {
            $http({
                method: "POST",
                url: "/login/trocaUsuarioAgencia",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    objectIdLogin: login.objectIdLogin,
                },
            }).then(function (response) {
                var _cookieAgencia = "loginAgencia_ramperv3_2018";
                var _id = lerCookie(_cookieAgencia);
                if (_id == null || _id == "") {
                    gravarCookie(_cookieAgencia, "EMA_" +
                        $rootScope.IDCrypto +
                        "_" +
                        $rootScope.DadosCadastrais.objectIdLogin +
                        "_" +
                        $rootScope.Login.email +
                        "_" +
                        $rootScope.Login.fotoPerfil +
                        "_" +
                        $rootScope.nomeUsuarioLogado +
                        "_" +
                        $rootScope.empresaLogado, 1);
                }
                var _login = response.data.login;
                if (_login.status == "I") {
                    alert("Usuário inativo");
                }
                else {
                    var _dados = response.data.dados;
                    $rootScope.usuarioRamper =
                        _login.email.indexOf("@ramper") > -1 ||
                            _urlAcesso.indexOf("go.ramper") <= -1;
                    $rootScope.Login = _login;
                    $rootScope.IDCrypto = _login._idcrypto;
                    $rootScope.Tipo = _login.tipo;
                    $rootScope.ConfiguracoesEmail = _dados.aliasconfiguracoesemails;
                    $rootScope.TimeUsuario = _dados.aliastimeusuarios;
                    PermissionamentoService.setPermissionamento(response.data.permissoes.user);
                    if ($rootScope.loginRamperSenha != null) {
                        mixpanel.track("Login Senha Ramper | Trocou de conta Agencia", {
                            Local_IP: $rootScope.ip,
                            "Usuario Senha Ramper": $scope.emailRamper,
                            "Troca de Contas Cliente para": _login.email,
                        });
                        // como era antes da modificacao hotfix/R135
                        // mixpanel.track("Login Senha Ramper | Trocou de conta Agencia", {
                        //     "Local_IP": $rootScope.ip,
                        //     "Usuario Senha Ramper": $rootScope.loginRamperSenha.login.email,
                        //     "Troca de Contas Cliente para": _login.email
                        // });
                    }
                    if ($rootScope.Login.fotoPerfil &&
                        $rootScope.Login.fotoPerfil != null) {
                        $http({
                            method: "GET",
                            url: "/aws/downloaderAWS",
                            headers: {
                                "ramp3r-auth": "ramp3r-authorization",
                                "ramp3r-l": $rootScope.IDCrypto,
                            },
                            params: {
                                tipo: "fotos",
                                nome: $rootScope.Login.fotoPerfil,
                            },
                        }).then(function (response) {
                            $rootScope.fotoPerfilUrl = response.data;
                        });
                    }
                    else {
                        $rootScope.fotoPerfilUrl = null;
                    }
                    if ($rootScope.showBrowserLogins.length == 0) {
                        var _cookieAgencia = "loginAgencia_ramperv3_2018";
                        var _id = lerCookie(_cookieAgencia);
                        var usuario = null;
                        if (_id != null && _id != "") {
                            usuario = {
                                iDCrypto: _id.split("EMA_")[1].split("_")[0],
                                objectIdLogin: _id.split("EMA_")[1].split("_")[1],
                                email: _id.split("EMA_")[1].split("_")[2],
                                fotoPerfil: _id.split("EMA_")[1].split("_")[3],
                                nome: _id.split("EMA_")[1].split("_")[4],
                                empresa: _id.split("EMA_")[1].split("_")[5],
                            };
                            if (usuario.fotoPerfil && usuario.fotoPerfil != "") {
                                usuario.fotoPerfil =
                                    "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                        usuario.objectIdLogin.toString() +
                                        "/" +
                                        usuario.fotoPerfil;
                            }
                            else {
                                usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            }
                            if ($rootScope.Login.email !== usuario.email)
                                $rootScope.showBrowserLogins.push(usuario);
                        }
                        AgenciaService.consultarAgenciasLogins(usuario);
                    }
                    _dados.aliasconfiguracoesemails = null;
                    _dados.aliastimeusuarios = null;
                    $rootScope.DadosCadastrais = _dados;
                    $rootScope.nomeUsuarioLogado =
                        $rootScope.DadosCadastrais.nome +
                            " " +
                            $rootScope.DadosCadastrais.sobrenome;
                    $rootScope.empresaLogado = $rootScope.DadosCadastrais.empresa;
                    $rootScope.emailUsuario = _login.email.toString();
                    $rootScope.empresaUsuario = _dados.empresa;
                    $rootScope.administrativoOuVisao = $rootScope.Tipo != "U";
                    var _cookie = "login_ramperv3_1910_1989_2017";
                    apagarCookie(_cookie);
                    gravarCookie(_cookie, "EMA_" +
                        _login._idcrypto +
                        "_" +
                        _login.email.toString() +
                        "_" +
                        _login.tipo.toString(), 1);
                    var _cookieExtensao = "ramperExtensao_1910_1989_2017";
                    gravarCookie(_cookieExtensao, "USU_" +
                        $rootScope.DadosCadastrais.objectIdLogin +
                        "#EMA_" +
                        $rootScope.Login.email +
                        "#SEN_" +
                        $rootScope.Login.senha +
                        "#LIS_" +
                        "" +
                        "#NOM_" +
                        $rootScope.nomeUsuarioLogado +
                        "#TIP_" +
                        $rootScope.Tipo, 92, ".ramper.com.br");
                    $location.path("/inicio");
                    location.reload(true);
                    $rootScope.creditosInicio = null;
                    $http({
                        method: "GET",
                        url: "/v2/usuario/".concat($rootScope.DadosCadastrais.objectIdLogin, "/creditos-disponiveis")
                    }).then(function (rConsultarCreditos) {
                        $rootScope.creditosInicio = rConsultarCreditos.data;
                    });
                }
                localStorage.removeItem("user");
            });
            $scope.trocarContaFechar();
        };
        $scope.removerSubMenus = function () {
            $("#subMenuTimesDestaque").removeClass("borderBottomNovo");
            $("#subMenuRankingDestaque").removeClass("borderBottomNovo");
            $("#subMenuContatosDestaque").removeClass("borderBottomNovo");
            $("#subMenuSegmentacoesDestaque").removeClass("borderBottomNovo");
            $("#subMenuCadenciasDestaque").removeClass("borderBottomNovo");
            $("#subMenuTemplatesDestaque").removeClass("borderBottomNovo");
            $("#subMenuRespostasDestaque").removeClass("borderBottomNovo");
            $("#subMenuConectarDestaque").removeClass("borderBottomNovo");
            $("#subMenuChatDestaque").removeClass("borderBottomNovo");
        };
        $scope.destroyInbox = function () {
            if ($rootScope.inbox) {
                $rootScope.inbox.destroy();
            }
        };
        $scope.fecharAlertaConfEmail = function () {
            if ($rootScope.Plano != "B") {
                $rootScope.semConfEmail = false;
            }
            $rootScope.alertaConfEmail = false;
        };
        $scope.fecharAlertaConfAssinatura = function () {
            $rootScope.alertaConfAssinatura = false;
        };
        $scope.onNoShowAlertMfa = function () {
            $rootScope.noShowAlertMfa = true;
        };
        $scope.fecharAlertaConfEmailIntegracao = function () {
            $rootScope.ConfiguracoesEmail.erroIntegracaoStatus = false;
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        if ($rootScope.IDCrypto) {
            $scope.consultarCapturasHoje();
        }
        $scope.removerSubMenus();
        $rootScope.$watch("permissoes", function () {
            $scope.mountMainMenu();
        });
        $rootScope.$watch("mostrarTrocarConta", function () {
            $scope.mountMainMenu();
        });
    },
]);
