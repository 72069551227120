angular.module("ramperv3").service("LinkWhatsappService", [
    "$http",
    function ($http) {
        function getDadosWhatsapp() {
            return $http({
                method: "GET",
                url: "v2/dadoscadastrais/whatsapp",
            });
        }
        return { getDadosWhatsapp: getDadosWhatsapp };
    },
]);
