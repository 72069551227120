var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular.module("ramperv3").controller("MonsterCreditosPagamentosController", [
    "$rootScope",
    "$scope",
    "$filter",
    "MonsterCreditosPagamentosService",
    "MonsterToasterService",
    function ($rootScope, $scope, $filter, MonsterCreditosPagamentosService, MonsterToasterService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.monsterShowLoading = true;
        $rootScope.mostrarMenu = true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, 1000);
        /**
         * Estado inicial da página $scope
         */
        $scope.solicitarCreditoDisabled = false;
        $scope.solicitarCreditoLoading = false;
        $scope.creditosDisponiveis = null;
        $scope.historicoCreditos = [];
        $scope.faturas = [];
        $scope.dataRenovacao = $filter("date")(new Date($rootScope.Login.dataProximaRenovacao), "dd/MM/yyyy");
        $scope.calc = function (x, y) {
            return (x / y) * 100 + "%";
        };
        /**
         * Deve consumir o serviço para oter créditos disponiveis
         */
        $scope.obterCreditosDisponives = function () {
            $rootScope.monsterShowLoading = true;
            MonsterCreditosPagamentosService.obterCreditosDisponiveis().then(function (result) {
                $scope.creditosDisponiveis = result.data;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao oter os cr\u00E9ditos disponiveis. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir o serviço para oter os histórico de créditos
         */
        $scope.listarHistoricoCreditos = function () {
            $rootScope.monsterShowLoading = true;
            MonsterCreditosPagamentosService.listarHistoricoCreditos().then(function (result) {
                $scope.historicoCreditos = result.data;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao listar hist\u00F3rico de cr\u00E9ditos. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir o serviço para oter créditos disponiveis
         */
        $scope.listarFaturas = function () {
            $rootScope.monsterShowLoading = true;
            MonsterCreditosPagamentosService.listarFaturas().then(function (result) {
                $scope.faturas = result.data;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao listar faturas. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir o serviço para baixar boleto de uma fatura disponivel
         */
        $scope.onGerarBoleto = function (dataVencimento) {
            $rootScope.monsterShowLoading = true;
            var mes = dataVencimento.split("/")[1];
            MonsterCreditosPagamentosService.gerarBoleto(mes).then(function (result) {
                $rootScope.monsterShowLoading = false;
                window.open(result.data.message);
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao baixar boleto. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Callback é chamada para enviar um e-mail para solicitar credito
         */
        $scope.onSolicitarCredito = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $scope.solicitarCreditoLoading = true;
                            return [4 /*yield*/, MonsterCreditosPagamentosService.solicitarCredito().then(function () {
                                    MonsterToasterService.showSuccessToaster("", "Solicita\u00E7\u00E3o enviada com sucesso! Nosso time entrar\u00E1 em contato em breve.", 6000);
                                    $scope.solicitarCreditoLoading = false;
                                    $scope.solicitarCreditoDisabled = true;
                                }, function () {
                                    MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao solicitar novos cr\u00E1ditos. Tente novamente mais tarde.", 6000);
                                    $scope.solicitarCreditoLoading = false;
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        $scope.obterCreditosDisponives();
        $scope.listarHistoricoCreditos();
        $scope.listarFaturas();
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
