lpad = function (valor, tamanho) {
    return Array(Math.max(tamanho - String(valor).length + 1, 0)).join(0) + valor;
};
formatarData = function (data) {
    var _data = new Date(data);
    var _formatar = lpad(_data.getDate(), 2) +
        "/" +
        lpad(new Number(_data.getMonth() + 1), 2) +
        "/" +
        _data.getFullYear();
    return _formatar;
};
formatarHora = function (data) {
    var _data = new Date(data);
    var _formatar = lpad(_data.getHours(), 2) + ":" + lpad(_data.getMinutes(), 2);
    return _formatar;
};
formatarDataHora = function (data) {
    var _data = new Date(data);
    var _formatar = formatarData(_data) + " | " + formatarHora(_data);
    return _formatar;
};
formatarValor = function (valor) {
    var _auxiliar = valor;
    var tam = valor.toString().length;
    if (tam >= 4) {
        _auxiliar =
            valor.toString().substr(0, tam - 3) +
                "." +
                valor.toString().substr(tam - 3, tam);
    }
    return _auxiliar;
};
caracterRandomico = function () {
    var ascii = [
        [48, 57],
        [64, 90],
        [97, 122],
    ];
    var i = Math.floor(Math.random() * ascii.length);
    return String.fromCharCode(Math.floor(Math.random() * (ascii[i][1] - ascii[i][0])) + ascii[i][0]);
};
gravarCookie = function (nome, valor, dias, domain) {
    var _data = new Date();
    var _expirar = null;
    _data.setTime(_data.getTime() + dias * 24 * 60 * 60 * 1000);
    _expirar = "; expires=" + _data.toGMTString();
    document.cookie =
        nome +
            "=" +
            valor +
            _expirar +
            (domain ? "; domain=" + domain : "") +
            "; path=/";
};
lerCookie = function (nome) {
    var i = null;
    var c = null;
    var _cookie = null;
    var nameEQ = nome + "=";
    _cookie = document.cookie.split(";");
    for (i = 0; i < _cookie.length; i++) {
        c = _cookie[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            var resultado = c.substring(nameEQ.length, c.length);
            apagarCookie(nome, ".ramper.com.br");
            _cookie = [];
            return resultado;
        }
    }
    return "";
};
apagarCookie = function (nome, domain) {
    var _data = new Date(1970, 1, 1);
    var _expirar = null;
    _expirar = "; expires=" + _data.toGMTString();
    document.cookie =
        nome + "=" + _expirar + (domain ? "; domain=" + domain : "") + "; path=/";
};
diferencaDias = function (pDataInicial, pDataFinal) {
    var _diferenca = Math.abs(pDataInicial - pDataFinal);
    var _dia = 1000 * 60 * 60 * 24;
    var _total = Math.round(_diferenca / _dia);
    return _total;
};
getProdutoOrigemContato = function (origemContato) {
    var produto = "";
    switch (origemContato) {
        case "Descobrir contato":
            produto = "Prospect";
            break;
        case "Importação de lista":
            produto = "Prospect";
            break;
        case "Inclusão manual":
            produto = "Prospect";
            break;
        case "Extensão":
            produto = "Prospect";
            break;
        case "Inteligência comercial da Ramper":
            produto = "Prospect";
            break;
        case "Chat":
            produto = "Prospect";
            break;
        case "Engage":
            produto = "Engage";
            break;
        case "Marketing":
            produto = "Marketing";
            break;
    }
    return produto;
};
latitudeLongitude = function (callback) {
    $.ajax({
        url: "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBctLs73UkiEUOZYbLafn1LDQHT_J-QJww",
        type: "POST",
        contentType: "application/json",
        success: function (rSuccess) {
            _lat = rSuccess.location.lat;
            _lng = rSuccess.location.lng;
            callback({
                latitude: _lat,
                longitude: _lng,
            });
        },
        error: function (rError) {
            $.getJSON("http://freegeoip.net/json/", function (data) {
                _lat = data.latitude;
                _lng = data.longitude;
                callback({
                    latitude: _lat,
                    longitude: _lng,
                });
            });
        },
    });
};
identificarPCOrCelular = function () {
    /*
      var OSName="Unknown OS";
      if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
      if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
      if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
      if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";
      */
    var _pcOrCelular = "PC";
    if (navigator.userAgent.match(/Android/i))
        _pcOrCelular = "Android";
    else if (navigator.userAgent.match(/BlackBerry/i))
        _pcOrCelular = "BlackBerry";
    else if (navigator.userAgent.match(/iPhone|iPad|iPod/i))
        _pcOrCelular = "iOS";
    else if (navigator.userAgent.match(/Opera Mini/i))
        _pcOrCelular = "Opera Mini";
    else if (navigator.userAgent.match(/IEMobile/i))
        _pcOrCelular = "Windows";
    return _pcOrCelular;
};
identificarBrowser = function () {
    var _browser = "";
    if ((!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0)
        _browser = "Opera";
    else if (typeof InstallTrigger !== "undefined")
        _browser = "Firefox";
    else if (/constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || safari.pushNotification))
        _browser = "Safari";
    else if ( /*@cc_on!@*/false || !!document.documentMode)
        _browser = "IE";
    else if (!( /*@cc_on!@*/(false || !!document.documentMode)) &&
        !!window.StyleMedia)
        _browser = "Edge";
    else if (!!window.chrome && !!window.chrome.webstore)
        _browser = "Chrome";
    return _browser;
};
getTimestamp = function (_id) {
    if (_id) {
        var timestamp = _id.toString().substring(0, 8);
        var data = new Date(parseInt(timestamp, 16) * 1000);
        var dia = data.getDate();
        if (dia.toString().length == 1)
            dia = "0" + dia;
        var mes = data.getMonth() + 1;
        if (mes.toString().length == 1)
            mes = "0" + mes;
        var ano = data.getFullYear();
        return dia + "/" + mes + "/" + ano;
    }
    else
        return null;
};
identificarIP = function (callback) {
    new Promise(function (r) {
        var w = window, a = new (w.RTCPeerConnection ||
            w.mozRTCPeerConnection ||
            w.webkitRTCPeerConnection)({ iceServers: [] }), b = function () { };
        a.createDataChannel("");
        a.createOffer(function (c) {
            a.setLocalDescription(c, b, b);
        }, b);
        a.onicecandidate = function (c) {
            try {
                c.candidate.candidate
                    .match(/([0-9]{1,3}(\.[0-9]{1,3}){3}| [a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g)
                    .forEach(r);
            }
            catch (e) {
                callback(null);
            }
        };
    })
        .then(function (result) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve(result);
            }, 1000);
        });
    })
        .then(function (result) {
        callback(result);
    });
};
validatePassword = function (password) {
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W_]{8,30}$/;
    return passwordRegex.test(String(password));
};
verificaStatusContatoERetorna = function (status) {
    var statusSelecionado = "";
    if (status === "M") {
        return (statusSelecionado = ["M", "SM", "NM", "FM"]);
    }
    return (statusSelecionado = status ? status : "");
};
/**
 * Control the infinite scrolling of ContatosController, CadenciasController, RespostasController adn SegmentacoesController
 * "scrollActive" is released with a delay after the callback, because rendering the new data to the screen can take a while
 *
 * @param {*} referenceElement element reference to check scroll position
 * @param {*} callback return a callback function when scrolled to the correct position
 */
var infiniteScrolling = function (referenceElement, callback) {
    $(document).ready(function () {
        var scrollActive = true;
        $(referenceElement).scroll(function () {
            var _scrollTop = $(this).scrollTop();
            var _innerHeight = $(this).innerHeight();
            var _scrollHeight = $(this)[0].scrollHeight;
            if (_scrollTop + _innerHeight + 1 >= _scrollHeight) {
                if (!scrollActive) {
                    return;
                }
                scrollActive = false;
                setTimeout(function () {
                    callback();
                }, 500);
                setTimeout(function () {
                    scrollActive = true;
                }, 1500);
            }
        });
    });
};
