var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular
    .module("ramperv3")
    .controller("DescobrirContatosController", [
    "$rootScope",
    "$scope",
    "MonsterCapturasService",
    "EventSourceFactory",
    function ($rootScope, $scope, MonsterCapturasService, EventSourceFactory) {
        /**
         * Google Analytics
         */
        var _this = this;
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.mostrarMenu = true;
        $rootScope.monsterShowLoading = false;
        /**
         * Estado inicial da página $scope
         */
        $scope.cookieAuthGerado = false;
        $scope.selectSegmentacaoFormType = "select";
        $scope.selectSegmentacaoModalShow = false;
        $scope.creditoInsuficienteModalShow = false;
        $scope.creditoInsuficienteEmailEnviado = false;
        $scope.selectSegmentacaoContent = [];
        $scope.inputSegmentacaoCreateName = null;
        $scope.inputSegmentacaoSelectData = null;
        $scope.criarSegmentacaoLoading = false;
        $scope.solicitarCreditoLoading = false;
        /**
         * Deve limpar a segmentação selecionada
         */
        $scope.onLimparSegmentacao = function () {
            $scope.inputSegmentacaoSelectData = null;
        };
        /*
         * Deve realizar o cadastro de um nova segmentação
         */
        $scope.onCriarSegmentacao = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $scope.criarSegmentacaoLoading = true;
                MonsterCapturasService.criarSegmentacao($scope.inputSegmentacaoCreateName).then(function (response) {
                    if (response.data._id) {
                        var iframe = document.getElementById("capturas");
                        iframe.contentWindow.postMessage({
                            type: "can-contacts-capture",
                            canCaptureContacts: true,
                            extras: { idSegmentacao: response.data._id },
                        }, $rootScope.capturasBaseUrl);
                        $scope.selectSegmentacaoModalShow = false;
                    }
                    $scope.criarSegmentacaoLoading = false;
                    $scope.selectSegmentacaoModalShow = false;
                    $scope.inputSegmentacaoCreateName = null;
                    $scope.selectSegmentacaoFormType = "select";
                    $scope.listarSegmentacoes();
                }, function () {
                    $scope.criarSegmentacaoLoading = false;
                    $scope.selectSegmentacaoModalShow = false;
                    $scope.inputSegmentacaoCreateName = null;
                    $scope.selectSegmentacaoFormType = "select";
                });
                return [2 /*return*/];
            });
        }); };
        /*
         * Deve obter os dados do formulário e enviar uma mensagem do iframe
         */
        $scope.onEnviarSegmentacao = function () { return __awaiter(_this, void 0, void 0, function () {
            var idSegmentacao, iframe;
            return __generator(this, function (_a) {
                idSegmentacao = $scope.inputSegmentacaoSelectData.data;
                if (idSegmentacao) {
                    iframe = document.getElementById("capturas");
                    iframe.contentWindow.postMessage({
                        type: "can-contacts-capture",
                        canCaptureContacts: true,
                        extras: { idSegmentacao: idSegmentacao },
                    }, $rootScope.capturasBaseUrl);
                    $scope.selectSegmentacaoModalShow = false;
                    $scope.inputSegmentacaoSelectData = null;
                }
                return [2 /*return*/];
            });
        }); };
        /**
         * Callback para alterar os formulários da modal
         */
        $scope.onToggleModal = function (value) {
            $scope.selectSegmentacaoFormType = value;
            $scope.inputSegmentacaoCreateName = null;
            $scope.inputSegmentacaoSelectData = null;
        };
        /**
         * Callback para fechar a modal
         */
        $scope.onFecharModal = function () {
            var iframe = document.getElementById("capturas");
            iframe.contentWindow.postMessage({ type: "can-contacts-capture", canCaptureContacts: false }, $rootScope.capturasBaseUrl);
            $scope.selectSegmentacaoFormType = "select";
            $scope.inputSegmentacaoCreateName = null;
            $scope.inputSegmentacaoSelectData = null;
            $scope.criarSegmentacaoLoading = false;
            $scope.solicitarCreditoLoading = false;
            $scope.selectSegmentacaoModalShow = false;
            $scope.creditoInsuficienteEmailEnviado = false;
            $scope.creditoInsuficienteModalShow = false;
        };
        /**
         * Callback é chamada quando o iframe dispara um post message
         */
        $scope.onCapturasPostMessage = function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (event.origin !== $rootScope.capturasBaseUrl)
                                return [2 /*return*/];
                            $scope.mixpanel(event.data.type, event.data.data);
                            if (!(event.data.type == "can-contacts-capture")) return [3 /*break*/, 2];
                            return [4 /*yield*/, MonsterCapturasService.obterCredito()];
                        case 1:
                            result = _a.sent();
                            $scope.numberOfContacts = event.data.numberOfContacts;
                            $scope.numeroDeCapturasDisponiveis =
                                result.data.creditosDisponiveis + result.data.creditosLegadosDisponiveis;
                            if (result.data.creditosDisponiveis + result.data.creditosLegadosDisponiveis < event.data.numberOfContacts) {
                                $scope.creditoInsuficienteModalShow = true;
                                $scope.$apply();
                                return [2 /*return*/];
                            }
                            $scope.listarSegmentacoes();
                            $scope.selectSegmentacaoModalShow = true;
                            $scope.$apply();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * Callback é chamada para enviar um e-mail para solicitar credito
         */
        $scope.onSolicitarCreditoModal = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $scope.solicitarCreditoLoading = true;
                            return [4 /*yield*/, MonsterCapturasService.solicitarCredito()];
                        case 1:
                            _a.sent();
                            mixpanel.track("DESCOBRIR CONTATOS | Solicitou créditos de capturas", {
                                Local_IP: $rootScope.ip,
                            });
                            $scope.solicitarCreditoLoading = false;
                            $scope.creditoInsuficienteEmailEnviado = true;
                            $scope.$apply();
                            return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * Callback é chamada para enviar um e-mail para solicitar Upsell
         */
        $scope.onSolicitarUpsellModal = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $scope.solicitarUpsellLoading = true;
                            return [4 /*yield*/, MonsterCapturasService.solicitarUpsell()];
                        case 1:
                            _a.sent();
                            mixpanel.track("DESCOBRIR CONTATOS | Solicitou Upsell", {
                                Local_IP: $rootScope.ip,
                            });
                            $scope.solicitarUpsellLoading = false;
                            $scope.upsellEmailEnviado = true;
                            $scope.$apply();
                            return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * Callback deve enviar um evento para o iframe com o contato capturado
         */
        $scope.onSyncContactCaptured = function (event) {
            var iframe = document.getElementById("capturas");
            iframe.contentWindow.postMessage({
                type: "sync-contact-captured",
                contactId: JSON.parse(event.data).idContato,
                clientName: JSON.parse(event.data).nomeLogin,
            }, $rootScope.capturasBaseUrl);
        };
        /**
         * Callback é chamada quando o iframe termina de carregar o conteúdo
         */
        $scope.onIframeLoad = function () {
            var iframe = document.getElementById("capturas");
            iframe.contentWindow.postMessage({ type: "contacts-capture-mode", validateContactsExternally: true }, $rootScope.capturasBaseUrl);
            iframe.contentWindow.postMessage({
                type: "contacts-cache-unique-key",
                contactCacheUniqueKey: $rootScope.DadosCadastrais.objectIdLogin,
            }, $rootScope.capturasBaseUrl);
            window.addEventListener("message", $scope.onCapturasPostMessage);
            EventSourceFactory.addEventListener("sync-contact-captured", $scope.onSyncContactCaptured);
        };
        /**
         * Deve consumir o serviço de capturas para realizar o login
         * os dados de acesso retornados devem ser salvos em um cookie de 1h
         * o gerenciamento do refresh token será feito pelo capturas-front
         */
        $scope.login = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if ($rootScope.permissoes.descobrirContatos.seeBanner)
                        return [2 /*return*/];
                    $rootScope.monsterShowLoading = true;
                    MonsterCapturasService.login().then(function (response) {
                        var accessToken = encodeURIComponent(response.data.accessToken);
                        var refreshToken = encodeURIComponent(response.data.refreshToken);
                        var expires = new Date();
                        expires.setHours(expires.getHours() + 1);
                        if (document.domain.indexOf("localhost") !== -1) {
                            document.cookie = "capturas_localhost_auth_token=".concat(accessToken, "|~|").concat(refreshToken, ";expires=").concat(expires.toGMTString(), ";domain=localhost;path=/");
                        }
                        if (document.domain.indexOf("test.ramper.com.br") !== -1) {
                            document.cookie = "capturas_staging_auth_token=".concat(accessToken, "|~|").concat(refreshToken, ";expires=").concat(expires.toGMTString(), ";domain=ramper.com.br;path=/");
                        }
                        if (document.domain.indexOf("go.ramper.com.br") !== -1) {
                            document.cookie = "capturas_auth_token=".concat(accessToken, "|~|").concat(refreshToken, ";expires=").concat(expires.toGMTString(), ";domain=ramper.com.br;path=/");
                        }
                        $rootScope.monsterShowLoading = false;
                        $scope.cookieAuthGerado = true;
                    }, function () {
                        $rootScope.monsterShowLoading = false;
                        $scope.cookieAuthGerado = false;
                    });
                    return [2 /*return*/];
                });
            });
        };
        /**
         * Deve consumir o serviço de capturas para listar as segmentações do usuário
         */
        $scope.listarSegmentacoes = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    $scope.selectSegmentacaoContent = [];
                    MonsterCapturasService.listarSegmentacoes().then(function (response) {
                        response.data.forEach(function (seguitacao) {
                            $scope.selectSegmentacaoContent.push({
                                label: seguitacao.nome,
                                data: seguitacao._id,
                            });
                        });
                    }, function () { });
                    return [2 /*return*/];
                });
            });
        };
        /**
         * Deve disparar eventos para mixpanel com as ações do usuário dentro do descobrir contatos
         */
        $scope.mixpanel = function (type, data) {
            // NOME DA EMPRESA
            if (type == "USER_ACTION_FILTER_COMPANY_NAME") {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por NOME DA EMPRESA ", {
                    Local_IP: $rootScope.ip,
                });
            }
            if (type == "USER_ACTION_FILTER_COMPANY_NAME_AND_ROLE") {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por NOME DA EMPRESA E CARGO", {
                    Local_IP: $rootScope.ip,
                });
            }
            // SETOR/CNAE
            if (type == "USER_ACTION_FILTER_CNAE") {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE", {
                    Local_IP: $rootScope.ip,
                });
            }
            if (type == "USER_ACTION_FILTER_CNAE" &&
                data &&
                data.cnaes &&
                data.cnaes.length &&
                (!data.states || data.states.length <= 0)) {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE", { Local_IP: $rootScope.ip });
            }
            if (type == "USER_ACTION_FILTER_CNAE" &&
                data &&
                data.cnaes &&
                data.cnaes.length &&
                data.states &&
                data.states.length) {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE e LOCALIDADE", { Local_IP: $rootScope.ip });
            }
            if (type == "USER_ACTION_CAPTURE_CONTACT" && data && data.companyId) {
                mixpanel.track("DESCOBRIR CONTATOS | Capturou contatos filtrando por SETOR/CNAE", { Local_IP: $rootScope.ip });
            }
            // CARGO
            if (type == "USER_ACTION_FILTER_ROLE") {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por CARGO", {
                    Local_IP: $rootScope.ip,
                });
            }
            if (type == "USER_ACTION_CAPTURE_CONTACT" &&
                data &&
                data.roles &&
                data.roles.length &&
                (!data.cnaes || data.cnaes.length <= 0)) {
                mixpanel.track("DESCOBRIR CONTATOS | Capturou contatos filtrando por CARGO", { Local_IP: $rootScope.ip });
            }
            // EMPRESAS E PESSOAS
            if (type == "USER_ACTION_FILTER_CNAE_AND_ROLE") {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE e CARGO", { Local_IP: $rootScope.ip });
            }
            if (type == "USER_ACTION_FILTER_CNAE_AND_ROLE" &&
                data &&
                data.cnaes &&
                data.cnaes.length &&
                data.roles &&
                data.roles.length &&
                (data.states || data.states.length <= 0)) {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE e CARGO", { Local_IP: $rootScope.ip });
            }
            if (type == "USER_ACTION_FILTER_CNAE_AND_ROLE" &&
                data &&
                data.cnaes &&
                data.cnaes.length &&
                data.roles &&
                data.roles.length &&
                data.states &&
                data.states.length) {
                mixpanel.track("DESCOBRIR CONTATOS | Filtrou contatos por SETOR/CNAE, LOCALIDADE e CARGO", { Local_IP: $rootScope.ip });
            }
            if (type == "USER_ACTION_CAPTURE_CONTACT" &&
                data &&
                data.cnaes &&
                data.cnaes.length &&
                data.roles &&
                data.roles.length) {
                mixpanel.track("DESCOBRIR CONTATOS | Capturou contatos filtrando por SETOR/CNAE e CARGO", { Local_IP: $rootScope.ip });
            }
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            window.removeEventListener("message", $scope.onCapturasPostMessage);
            EventSourceFactory.removeEventListener("sync-contact-captured", $scope.onSyncContactCaptured);
        });
        $scope.login();
    },
])
    .filter("trusted", [
    "$sce",
    function ($sce) {
        return $sce.trustAsResourceUrl;
    },
])
    .directive("iframeOnload", [
    function () {
        return {
            scope: {
                callBack: "&iframeOnload",
            },
            link: function (scope, element, attrs) {
                element.on("load", function () {
                    return scope.callBack();
                });
            },
        };
    },
]);
