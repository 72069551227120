angular.module("ramperv3").service("LoginService", [
    "$rootScope",
    "$http",
    "$location",
    "AcessosService",
    "$q",
    "AgenciaService",
    "VoipService",
    function ($rootScope, $http, $location, AcessosService, $q, AgenciaService, VoipService) {
        var _cookie = "login_ramperv3_1910_1989_2017";
        var _id = lerCookie(_cookie);
        var _urlAcesso = document.URL.substr(7, 6);
        var _integracaoCRMLogin = "";
        var _integracaoPreVendaLogin = "";
        var _integracaoInboundLogin = "";
        var _emailCookie = "";
        $rootScope.showBrowserLogins = [];
        $rootScope.CsResponsavel = null;
        $rootScope.codigoSuperlogica = "";
        $rootScope.semConfEmail = false;
        $rootScope.alertaConfEmail = false;
        $rootScope.mostrarMensagemErroConfigEmail = true;
        $rootScope.alertaConfAssinatura = false;
        if (_id != null && _id != "") {
            var _idCookie = _id.split("EMA_")[1].split("_")[0];
            var _tipoCookie = _id.split("EMA_")[1].split("_")[2];
            _emailCookie = _id.split("EMA_")[1].split("_")[1];
            $rootScope.IDCrypto = _idCookie;
            $rootScope.Tipo = _tipoCookie;
            $rootScope.Login = { _id: _idCookie };
        }
        $rootScope.popupInfo = false;
        $rootScope.estouLogado = false;
        window.movideskChatWidget.hide();
        function showLoginPage() {
            $("body").css("visibility", "visible");
        }
        function redirectToLogin() {
            if ($location.$$path.indexOf("/renovacaoSenha") == -1 &&
                $location.$$path.indexOf("/novaConta") == -1 &&
                $location.$$path.indexOf("/ativarSenha") == -1 &&
                $location.$$path.indexOf("/sso") == -1 &&
                $location.$$path.indexOf("/politica-de-privacidade") == -1 &&
                $location.$$path.indexOf("/v2/alterar-minha-senha") == -1 &&
                $location.$$path.indexOf("/v2/dupla-autenticacao") == -1) {
                $location.path("/v2/login");
            }
        }
        /**
         * Limpa os dados globais, pois, eles podem se manter como "sujeira" em uma sessão diferente.
         *
         * TODO: Melhoria técnica: Analisar demais dados globais registrados no $rootScope:
         * 1. Analisar uma melhor maneira de destruir o estado e os listeners associonados.
         */
        function clearGlobalData() {
            $rootScope.DadosCadastrais = null;
        }
        function restoreSession() {
            clearGlobalData();
            if (!_emailCookie) {
                redirectToLogin();
                showLoginPage();
            }
            else {
                $http({
                    method: "GET",
                    url: "/login/getDadosSession",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                    params: {
                        email: _emailCookie,
                    },
                }).then(function (response) {
                    if (response.data != null) {
                        if (response.data.msgErro === "Usuário não encontrado") {
                            logout();
                            return location.reload();
                        }
                        if (response.data.login.login.configuracoesemails.length > 0) {
                            $rootScope.ServidorEmailUsuarioLogado =
                                response.data.login.login.configuracoesemails[0].servidor;
                        }
                        else {
                            $rootScope.ServidorEmailUsuarioLogado = "";
                        }
                        $rootScope.Plano = response.data.login.login.times[0].plano;
                        $rootScope.codigoSuperlogica = response.data.login.login.times[0]
                            .codigoEmpresaSuperlogica
                            ? response.data.login.login.times[0].codigoEmpresaSuperlogica
                            : "";
                        if (response.data.dados.habilitarUpsell !== null) {
                            $rootScope.habilitarUpsell = response.data.dados.habilitarUpsell;
                        }
                        else {
                            $rootScope.habilitarUpsell = null;
                        }
                        if (response.data.dados.exibirExperimentoUpsell !== null) {
                            $rootScope.exibirExperimentoUpsell =
                                response.data.dados.exibirExperimentoUpsell;
                        }
                        else {
                            $rootScope.exibirExperimentoUpsell = null;
                        }
                        var _login = response.data.login;
                        var _dados = response.data.dados;
                        var _status = _login.login.status;
                        if (!_login.login.acessaV3 || _status != "A") {
                            if (_status == "I") {
                                var _cookie = "login_ramperv3_1910_1989_2017";
                                apagarCookie(_cookie);
                                var _cookieExtensao = "ramperExtensao_1910_1989_2017";
                                apagarCookie(_cookieExtensao, ".ramper.com.br");
                            }
                            $location.path("/v2/login");
                        }
                        else {
                            if (_login && _login.login.configuracoesemails.length > 0) {
                                $rootScope.configuracaoEmailValida =
                                    _login.login.configuracoesemails[0].configuracaoEmailValida;
                                if (!_login.login.configuracoesemails[0].configuracaoEmailValida) {
                                    $rootScope.mensagemErroConfiguracaoEmail =
                                        _login.login.configuracoesemails[0].mensagemErroConfiguracaoEmail;
                                }
                            }
                            $rootScope.usuarioRamper =
                                _login.login.email.indexOf("@ramper") > -1 ||
                                    (document.URL.indexOf("go.ramper") <= -1 &&
                                        document.URL.indexOf("beta.ramper") <= -1 &&
                                        document.URL.indexOf("new.ramper") <= -1);
                            $rootScope.Login = _login.login;
                            $rootScope.creditosRenovados =
                                new Date($rootScope.Login.dataProximaRenovacao).toDateString() == new Date().toDateString();
                            $rootScope.IDCrypto = _login._idcrypto;
                            $rootScope.Tipo = _login.login.tipo;
                            $rootScope.ConfiguracoesEmail =
                                _login.login.configuracoesemails[0];
                            $rootScope.TimeUsuario = _login.login.timeusuarios.objectIdTime;
                            $rootScope.estouLogado = true;
                            try {
                                $rootScope.usuarioInadimplente =
                                    _login.login.inadimplente.status;
                            }
                            catch (e) {
                                $rootScope.usuarioInadimplente = false;
                            }
                            try {
                                $rootScope.diasUsuarioEstaInadimplente =
                                    $rootScope.Login.inadimplente.diasInadimplente;
                            }
                            catch (e) {
                                $rootScope.diasUsuarioEstaInadimplente = "";
                            }
                            if ((!$rootScope.ConfiguracoesEmail ||
                                !$rootScope.ConfiguracoesEmail.servidor) &&
                                _login.login.tipo != "V") {
                                $rootScope.semConfEmail = true;
                                $rootScope.alertaConfEmail = true;
                            }
                            if ($rootScope.ConfiguracoesEmail &&
                                $rootScope.ConfiguracoesEmail.email) {
                                $rootScope.alertaConfAssinatura =
                                    !$rootScope.ConfiguracoesEmail.assinaturaSimplificada;
                            }
                            AcessosService.setAcessos(response.data.login.login.times[0]);
                            var possuiConversacao = response.data.login.login.times[0].possuiConversacao;
                            var expiracaoTrialConversacao = response.data.login.login.times[0].expiracaoTrialConversacao;
                            var possuiConexao = response.data.login.login.times[0].possuiConexao;
                            if (possuiConversacao && possuiConversacao === true) {
                                $rootScope.possuiConversacao = true;
                            }
                            else {
                                if (expiracaoTrialConversacao && possuiConexao === true) {
                                    $rootScope.possuiConversacao = true;
                                }
                                else {
                                    $rootScope.possuiConversacao = false;
                                }
                            }
                            $rootScope.temDireitoChat =
                                $rootScope.possuiConversacao || $rootScope.Plano == "P";
                            if ($rootScope.Plano == "B") {
                                // $rootScope.temDireitoIntegracao = false;
                                $rootScope.temDireitoConexao = false;
                            }
                            else {
                                switch ($rootScope.Plano) {
                                    case "P":
                                        $rootScope.temDireitoConexao = true;
                                        break;
                                    case "C":
                                        if (response.data.login.login.times[0].possuiConexao) {
                                            $rootScope.temDireitoConexao = true;
                                        }
                                        else {
                                            $rootScope.temDireitoConexao = false;
                                        }
                                        break;
                                }
                            }
                            $http({
                                method: "GET",
                                url: "/integracoes/consultarIntegracoes",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": $rootScope.IDCrypto,
                                },
                            }).then(function (rConsultaIntegracoes) {
                                if (rConsultaIntegracoes.data) {
                                    rConsultaIntegracoes.data.forEach(function (rIntegracoes) {
                                        if (rIntegracoes.application == "E") {
                                            _integracaoPreVendaLogin = rIntegracoes.nomeAplicao;
                                            $rootScope.intPreVendaLogin = rIntegracoes;
                                        }
                                        if (rIntegracoes.application == "R") {
                                            _integracaoInboundLogin = rIntegracoes.nomeAplicao;
                                            $rootScope.intInboundLogin = rIntegracoes;
                                        }
                                        if (rIntegracoes.application != "R" &&
                                            rIntegracoes.application != "E") {
                                            _integracaoCRMLogin = rIntegracoes.nomeAplicao;
                                            $rootScope.intCrmLogin = rIntegracoes;
                                        }
                                    });
                                }
                            });
                            if ($rootScope.Login.fotoPerfil &&
                                $rootScope.Login.fotoPerfil != null) {
                                $http({
                                    method: "GET",
                                    url: "/aws/downloaderAWS",
                                    headers: {
                                        "ramp3r-auth": "ramp3r-authorization",
                                        "ramp3r-l": $rootScope.IDCrypto,
                                    },
                                    params: {
                                        tipo: "fotos",
                                        nome: $rootScope.Login.fotoPerfil,
                                    },
                                }).then(function (response) {
                                    $rootScope.fotoPerfilUrl = response.data;
                                });
                            }
                            if ($rootScope.showBrowserLogins.length == 0) {
                                var _cookieAgencia = "loginAgencia_ramperv3_2018";
                                var _idAgencia = lerCookie(_cookieAgencia);
                                var usuario = null;
                                if (_idAgencia != null && _idAgencia != "") {
                                    usuario = {
                                        iDCrypto: _idAgencia.split("EMA_")[1].split("_")[0],
                                        objectIdLogin: _idAgencia.split("EMA_")[1].split("_")[1],
                                        email: _idAgencia.split("EMA_")[1].split("_")[2],
                                        fotoPerfil: _idAgencia.split("EMA_")[1].split("_")[3],
                                        nome: _idAgencia.split("EMA_")[1].split("_")[4],
                                        empresa: _idAgencia.split("EMA_")[1].split("_")[5],
                                    };
                                    if (usuario.fotoPerfil && usuario.fotoPerfil != "") {
                                        usuario.fotoPerfil =
                                            "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                                usuario.objectIdLogin.toString() +
                                                "/" +
                                                usuario.fotoPerfil;
                                    }
                                    else {
                                        usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                                    }
                                    if ($rootScope.Login.email !== usuario.email) {
                                        $rootScope.showBrowserLogins.push(usuario);
                                    }
                                }
                                $rootScope.creditosInicio = null;
                                $http({
                                    method: "GET",
                                    url: "/agencias/consultarCsResponsavel",
                                    headers: {
                                        "ramp3r-auth": "ramp3r-authorization",
                                        "ramp3r-l": $rootScope.IDCrypto,
                                    },
                                    params: {
                                        objectIdTime: $rootScope.TimeUsuario
                                            ? $rootScope.TimeUsuario
                                            : null,
                                    },
                                }).then(function (rConsultarCsResponsavel) {
                                    $http({
                                        method: "GET",
                                        url: "/logins/consultarLoginPorId",
                                        headers: {
                                            "ramp3r-auth": "ramp3r-authorization",
                                            "ramp3r-l": $rootScope.IDCrypto,
                                        },
                                        params: {
                                            objectIdLogin: rConsultarCsResponsavel.data
                                                ? rConsultarCsResponsavel.data.objectIdLogin
                                                : null,
                                        },
                                    }).then(function (rConsultarLogin) {
                                        $rootScope.CsResponsavel = rConsultarLogin.data
                                            ? rConsultarLogin.data.email
                                            : "";
                                        $http({
                                            method: "GET",
                                            url: "/v2/usuario/".concat($rootScope.DadosCadastrais.objectIdLogin, "/creditos-disponiveis")
                                        }).then(function (rConsultarCreditos) {
                                            $http({
                                                method: "GET",
                                                url: "/login/consultarContatosMixpanel",
                                                headers: {
                                                    "ramp3r-auth": "ramp3r-authorization",
                                                    "ramp3r-l": $rootScope.IDCrypto,
                                                },
                                            }).then(function (rConsultarContatosMixpanel) {
                                                $rootScope.creditosInicio = rConsultarCreditos.data;
                                                $rootScope.semCreditos = $rootScope.creditosInicio.creditosLegadoDisponiveis + $rootScope.creditosInicio.creditosDisponiveis <= 0;
                                                if (_urlAcesso != "localh") {
                                                    identificarIP(function (rIP) {
                                                        $rootScope.ip = rIP;
                                                        mixpanel.identify(_login._idcrypto);
                                                        mixpanel.people.set({
                                                            $email: _login.login.email,
                                                            $name: _dados.nome,
                                                            $created: _login.login.dataContratacao,
                                                            Status: _login.login.status,
                                                            TipoUsuario: _login.login.tipo,
                                                            Envios: $rootScope.creditosInicio.creditosEnvios,
                                                            EnviosDisponiveis: $rootScope.creditosInicio.creditosEnviosDisponiveis,
                                                            CreditoLegado: $rootScope.creditosInicio.creditosLegado,
                                                            CreditoLegadoDisponiveis: $rootScope.creditosInicio.creditosLegadosDisponiveis,
                                                            Credito: $rootScope.creditosInicio.creditos,
                                                            CreditoDisponiveis: $rootScope.creditosInicio.creditosDisponiveis,
                                                            SuperLogica: _login.login.codigoEmpresaSuperlogica,
                                                            ServidorEmail: $rootScope.ServidorEmailUsuarioLogado,
                                                            Versao: "V3",
                                                            CRM: _integracaoCRMLogin,
                                                            "Pre Vendas": _integracaoPreVendaLogin,
                                                            Inbound: _integracaoInboundLogin,
                                                            Inadimplente: $rootScope.usuarioInadimplente,
                                                            "Dias inadimplente": $rootScope.diasUsuarioEstaInadimplente,
                                                            "Cs Responsável": $rootScope.CsResponsavel,
                                                            UltimoLogin: _login.login.dataUltimoLogin,
                                                            UltimaCaptura: _dados.ultimosRegistros.captura,
                                                            UltimoDisparo: _dados.ultimosRegistros.disparo,
                                                            UltimaResposta: _dados.ultimosRegistros.resposta,
                                                            UltimaCadenciaCriada: _dados.ultimosRegistros.cadencia,
                                                            QuantidadeCadenciaAtiva: _dados.ultimosRegistros.qtdCadenciaAtiva,
                                                            Plano: $rootScope.Plano,
                                                        });
                                                        if ($rootScope.Login.beta) {
                                                            beta = $rootScope.Login.beta.filter(function (obj) {
                                                                return obj.tela == "Dashboard";
                                                            })[0];
                                                            if (beta && beta.usuarioBeta) {
                                                                mixpanel.people.set_once("Beta Dashboard", true);
                                                            }
                                                            else {
                                                                mixpanel.people.set_once("Beta Dashboard", false);
                                                            }
                                                        }
                                                        if ($rootScope.loginRamperSenha != null &&
                                                            $rootScope.loginRamperSenha.login.email !=
                                                                "undefined" &&
                                                            $rootScope.loginRamperSenha.login.email !=
                                                                undefined) {
                                                            mixpanel.track("Login Senha Ramper | Abriu Ramper (já logado) Senha Ramper", {
                                                                Local_IP: $rootScope.ip,
                                                                "Usuario Ramper": $rootScope.loginRamperSenha.login.email,
                                                            });
                                                        }
                                                        mixpanel.track("Login | Abriu Ramper (já logado)", {
                                                            Local_IP: $rootScope.ip,
                                                        });
                                                        if (rConsultarContatosMixpanel.data) {
                                                            rConsultarContatosMixpanel.data = false;
                                                            mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                                                        }
                                                    });
                                                }
                                                if ($rootScope.loginRamperSenha
                                                    ? !$rootScope.loginRamperSenha.login
                                                    : true) {
                                                    $productFruits.push(['init', 'XcqFZfBKmERhjgEd', 'pt', {
                                                            username: $rootScope.Login.email,
                                                            email: $rootScope.Login.email,
                                                            firstname: $rootScope.Login.nome,
                                                            lastname: $rootScope.Login.sobrenome,
                                                            props: {
                                                                Plano: $rootScope.Plano,
                                                                Superlogica: $rootScope.codigoSuperlogica,
                                                                Empresa: $rootScope.empresaLogado,
                                                            }
                                                        }]);
                                                }
                                            });
                                        });
                                    });
                                });
                                AgenciaService.consultarAgenciasLogins(usuario);
                            }
                            _dados.aliasconfiguracoesemails = null;
                            $rootScope.DadosCadastrais = _dados;
                            $rootScope.nomeUsuarioInicio = $rootScope.DadosCadastrais.nome;
                            $rootScope.nomeUsuarioLogado =
                                $rootScope.DadosCadastrais.nome +
                                    " " +
                                    $rootScope.DadosCadastrais.sobrenome;
                            $rootScope.empresaLogado = $rootScope.DadosCadastrais.empresa;
                            $rootScope.emailUsuario = _login.login.email.toString();
                            VoipService.getAccountAndExtension();
                            $rootScope.empresaUsuario = _dados.empresa;
                            $rootScope.administrativoOuVisao = $rootScope.Tipo != "U";
                            $rootScope.programaIndicacoes =
                                _login.login.email == "hugo.oliveira@ramper.com.br" ||
                                    _login.login.email == "amanda.passamani@superlogica.com" ||
                                    _login.login.email == "bruno.scaravelli@mega.com.br" ||
                                    _login.login.email == "ana.machado@superlogica.com" ||
                                    _login.login.email == "everton.braga@traycorp.com.br" ||
                                    _login.login.email == "giovanni.scaravelli@mega.com.br" ||
                                    _login.login.email == "lidia.theophilo@mega.com.br" ||
                                    _login.login.email == "wilson.junior@traycorp.com.br" ||
                                    _login.login.email == "alef.zati@mega.com.br" ||
                                    _login.login.email == "larissa.arruda@mega.com.br" ||
                                    _login.login.email == "sabrina.oliveira@superlogica.com" ||
                                    _login.login.email == "vanessa.placona@traycorp.com.br" ||
                                    _login.login.email == "mirian.grilo@traycorp.com.br" ||
                                    _login.login.email == "carloseduardo@cestaincentivo.com.br" ||
                                    _login.login.email == "suely@cestaincentivo.com.br" ||
                                    _login.login.email == "jeferson.santos@cestaincentivo.com.br" ||
                                    _login.login.email == "daiana@cestaincentivo.com.br" ||
                                    _login.login.email == "adriana@cestaincentivo.com.br" ||
                                    _login.login.email == "fabiana@cestaincentivo.com.br" ||
                                    _login.login.email == "rose@cestaincentivo.com.br" ||
                                    _login.login.email == "marcelo.mello@synergie.com.br" ||
                                    _login.login.email == "marcio.melo@synergie.com.br" ||
                                    _login.login.email == "luciana@v2integradora.com.br" ||
                                    _login.login.email == "william.batista@mega.com.br" ||
                                    _login.login.email == "cintia.tonoli@superlogica.com" ||
                                    _login.login.email == "giovanna.vallin@superlogica.com" ||
                                    _login.login.email == "leonardo.pinheiro@mega.com.br" ||
                                    _login.login.email == "bruna.gallo@mega.com.br" ||
                                    _login.login.email == "lais.goncalez@mega.com.br" ||
                                    _login.login.email == "monica.marcelino@mega.com.br" ||
                                    _login.login.email == "lucio.biasini@mega.com.br" ||
                                    _login.login.email == "gilmara.pereira@mega.com.br" ||
                                    _login.login.email == "leticia.santos@mega.com.br" ||
                                    _login.login.email == "daniela.moraes@mega.com.br" ||
                                    _login.login.email == "lucas.monteiro@mega.com.br" ||
                                    _login.login.email == "vinicius.trujillo@cabify.com" ||
                                    _login.login.email == "vanessa.mattos@traycorp.com.br" ||
                                    _login.login.email == "mkt@zenvia.com" ||
                                    _login.login.email == "jeyciane.taimara@zenvia.com" ||
                                    _login.login.email == "christiane.yumi@zenvia.com" ||
                                    _login.login.email == "rogerio@cestaincentivo.com.br" ||
                                    _login.login.email == "nataly.nunes@traycorp.com.br" ||
                                    _login.login.email == "marilia.losito@traycorp.com.br" ||
                                    _login.login.email == "fernando.roberti@traycorp.com.br" ||
                                    _login.login.email == "negocios.ge@zenvia.com" ||
                                    _login.login.email == "wmoreira@bwg.com.br" ||
                                    _login.login.email == "correa@gruposicuressa.com.br" ||
                                    _login.login.email == "apaschoal@bwg.com.br" ||
                                    _login.login.email == "mbrancacci@bwg.com.br" ||
                                    _login.login.email == "erick.santos@traycorp.com.br" ||
                                    _login.login.email == "jraio@bwg.com.br" ||
                                    _login.login.email == "caroline.santos@cabify.com" ||
                                    _login.login.email == "mariane.santos@cabify.com" ||
                                    _login.login.email == "bruna.estaropolis@mega.com.br" ||
                                    _login.login.email == "gabriel.leite@mega.com.br" ||
                                    _login.login.email == "eduardo.pereira@traycorp.com.br";
                            if (!response.data.sramper) {
                                if ($location.$$path == "/v2/login") {
                                    $location.path("/inicio");
                                }
                                var _cookie = "login_ramperv3_1910_1989_2017";
                                apagarCookie(_cookie);
                                gravarCookie(_cookie, "EMA_" +
                                    _login._idcrypto +
                                    "_" +
                                    _login.login.email.toString() +
                                    "_" +
                                    _login.login.tipo.toString(), 1);
                            }
                            var _cookieExtensao = "ramperExtensao_1910_1989_2017";
                            apagarCookie(_cookieExtensao, ".ramper.com.br");
                            gravarCookie(_cookieExtensao, "USU_" +
                                $rootScope.DadosCadastrais.objectIdLogin +
                                "#EMA_" +
                                $rootScope.Login.email +
                                "#SEN_" +
                                $rootScope.Login.senha +
                                "#LIS_" +
                                "" +
                                "#NOM_" +
                                $rootScope.nomeUsuarioLogado +
                                "#TIP_" +
                                $rootScope.Tipo +
                                "#CON_" +
                                ($rootScope.Login.parametrosConexao ? true : false), 92, ".ramper.com.br");
                            window.movideskChatWidget.show();
                        }
                    }
                    else {
                        redirectToLogin();
                    }
                    showLoginPage();
                });
            }
        }
        $rootScope.consultarStatusUsuario = function () {
            if ($rootScope.estouLogado) {
                $http({
                    method: "GET",
                    url: "/login/getDadosSession",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                }).then(function (response) {
                    if (response.data != null) {
                        var _login = response.data.login;
                        var _status = _login.login.status;
                        if (!_login.login.acessaV3 || _status != "A") {
                            if (_status == "I") {
                                var _cookie = "login_ramperv3_1910_1989_2017";
                                apagarCookie(_cookie);
                                var _cookieExtensao = "ramperExtensao_1910_1989_2017";
                                apagarCookie(_cookieExtensao, ".ramper.com.br");
                            }
                            $location.path("/v2/login");
                        }
                        if ($rootScope.mostrarMensagemErroConfigEmail === true) {
                            if (_login && _login.login.configuracoesemails.length > 0) {
                                $rootScope.configuracaoEmailValida =
                                    _login.login.configuracoesemails[0].configuracaoEmailValida;
                                if (!_login.login.configuracoesemails[0].configuracaoEmailValida) {
                                    $rootScope.mensagemErroConfiguracaoEmail =
                                        _login.login.configuracoesemails[0].mensagemErroConfiguracaoEmail;
                                }
                            }
                        }
                    }
                });
            }
        };
        $rootScope.fPercentual = function (valorTotal, valorGasto) {
            var _perc = "100%";
            if (valorGasto && valorGasto > 0) {
                _perc = ((valorGasto * 100) / valorTotal).toString() + "%";
            }
            else {
                _perc = 0;
            }
            return _perc;
        };
        restoreSession();
        $rootScope.$on("acessos-time-salvos", function (event, acessos) {
            $rootScope.acessosTime = acessos;
            if (acessos != null) {
                $rootScope.cancelouAgenda = acessos.agenda.data_inativacao != null;
                $rootScope.temAcessoCloseIO =
                    acessos.integracao != null && acessos.integracao.closeio;
                $rootScope.temAcessoRandstad =
                    acessos.integracao != null && acessos.integracao.randstad;
            }
            else {
                $rootScope.cancelouAgenda = false;
            }
        });
        this.logout = function () {
            return $q(function (resolve) {
                $http({
                    method: "GET",
                    url: "/logout",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                }).finally(function () {
                    var _cookie = "login_ramperv3_1910_1989_2017";
                    apagarCookie(_cookie);
                    var _cookieAgencia = "loginAgencia_ramperv3_2018";
                    apagarCookie(_cookieAgencia);
                    var _cookieExtensao = "ramperExtensao_1910_1989_2017";
                    apagarCookie(_cookieExtensao, ".ramper.com.br");
                    $rootScope.permissoes = {};
                    $location.path("/v2/login");
                });
            });
        };
        this.isAuthenticated = function () {
            var _cookie = "login_ramperv3_1910_1989_2017";
            var _verificarCookie = lerCookie(_cookie);
            if (_verificarCookie) {
                return $q.resolve(true);
            }
            else {
                return $q.reject("Not Authenticated");
            }
        };
    },
]);
