var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").controller("TemplatesController", [
    "$rootScope",
    "$scope",
    "$http",
    "$locale",
    "DateRangePicker",
    "LinkWhatsappService",
    function ($rootScope, $scope, $http, $locale, DateRangePicker, LinkWhatsappService) {
        $scope.temAcessoChat = function () {
            return ($rootScope.Plano == "P" ||
                $rootScope.possuiConversacao ||
                $rootScope.temDireitoConexao);
        };
        $scope.getLinkWhatsapp = function () {
            LinkWhatsappService.getDadosWhatsapp().then(function (result) {
                $scope.whatsapp = result.data;
            });
        };
        $scope.getLinkWhatsapp();
        $scope.temAcessoAgenda = false;
        verificaAcessoAgenda = function (objectIdLogin) {
            $http({
                method: "GET",
                url: "/agenda/config",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    idUsuario: objectIdLogin,
                },
            })
                .then(function (response) {
                if (response.data.erro) {
                    $scope.temAcessoAgenda = false;
                }
                else {
                    $scope.temAcessoAgenda = true;
                }
            })
                .catch(function (error) {
                $scope.temAcessoAgenda = false;
            });
        };
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $rootScope.consultarStatusUsuario();
        _filtros = {
            buscar: "",
            objectId: "",
            tipoDaChave: "",
            objectIdPastaTemplate: "",
            dataInicio: "",
            dataFim: "",
            campoOrdem: "templateNome",
            ordem: "1",
        };
        $locale.NUMBER_FORMATS.GROUP_SEP = ".";
        $scope.menuNavAberto = true;
        var _templateSelecionado = "";
        var _pastaSelecionada = "";
        var _countDiv = 1;
        var _conteudoTemplate = [];
        var _clickCheckTodos = false;
        var _campoSelecionadoOrdenacao = "";
        var _inptAssunto = true;
        $scope.nomeBtnAvancar = "AVANÇAR";
        $("#spanEnvioComSucesso").hide();
        $scope.popupNovaPasta = false;
        $scope.popupRenomearPasta = false;
        $rootScope.subMenuTemplatesEspecifico = false;
        $scope.filtroTemplateHabilitado = false;
        $scope.ordemPasta = "templateNome";
        $scope.pastaFiltroDuplicar = null;
        $scope.nomeSelecionado = "";
        $scope.totalTemplatesSelecionados = 0;
        $scope.totalPastasSelecionadas = 0;
        $scope.nomeTemplateDuplicar = "";
        $rootScope.redirectTemplates();
        $scope.dvMensagemPasta = false;
        $scope.trocarOrdem = true;
        $scope.listaCadencias = [];
        $rootScope.Tipo == "A"
            ? $(".divCentralEtapa1Templates").css("height", "370px")
            : $(".divCentralEtapa1Templates").css("height", "250px");
        $("#idFundoPopupTemplates").hide();
        $("#formNovoTemplate").hide();
        $("#dvMensagemNomeTemplate").hide();
        $("#dvMensagemDefinicaoTemplate").hide();
        $("#dvMensagemDefinicaoCorpoTemplate").hide();
        $("#dvMensagemEmailTeste").hide();
        $("#dvMensagemNomePasta").hide();
        $scope.msgChat = { texto: "", chatChecked: false };
        $scope.msgAgenda = { texto: "", agendaChecked: false };
        $scope.filtroStatus = "-";
        $scope.filtroConfiabilidade = "T";
        if ($rootScope.administrativoOuVisao)
            $scope.filtroUsuario = "-";
        else
            $scope.filtroUsuario = $rootScope.Login._id;
        $scope.filtroSegmentacao = "-";
        $scope.filtroContato = "-";
        $scope.botaoBuscarVisivel = false;
        $scope.buscarTemplates = "";
        $scope.acao = "a";
        $scope.destino = "d";
        $scope.especificacao = "e";
        $rootScope.mostrarMenu = true;
        $rootScope.subMenuTemplates = false;
        $scope.popupFiltrarTemplates = false;
        $rootScope.popupCompararTemplates = false;
        $scope.popupInformacaoNaoExlcuir = false;
        $scope.semTemplates = false;
        $scope.listaPastas = [];
        $scope.listaPastasMover = [];
        $scope.listaPastasAdicionarTemplate = [];
        $scope.listaUsuarios = [];
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.verificarFiltros = function () {
            if ($scope.filtroTemplateHabilitado == false) {
                $scope.removerFiltros();
            }
            else {
                $scope.recarregarPagina();
            }
        };
        $scope.removerFiltros = function () {
            var _a, _b, _c, _d;
            $scope.dataInicioFiltro = null;
            $scope.dataFinalFiltro = null;
            $scope.pastaFiltro = null;
            $scope.usuarioFiltro = null;
            $scope.nomeUsuarioFiltroTemp = "";
            $scope.buscarUsuariosTemp = "";
            $scope.buscarPastaFiltroTemp = "";
            $scope.nomePastaFiltroTemp = "";
            document.getElementById("idPastaTempFiltro").innerText = "Pasta";
            document.getElementById("idUsuariosTempFiltro").innerText = "Usuários";
            $scope.consultaTemplatesPorPastaFiltro();
            $("#sltDataTemplates").val("");
            (_b = (_a = $("#sltDataTemplates")) === null || _a === void 0 ? void 0 : _a.data("daterangepicker")) === null || _b === void 0 ? void 0 : _b.setStartDate(moment());
            (_d = (_c = $("#sltDataTemplates")) === null || _c === void 0 ? void 0 : _c.data("daterangepicker")) === null || _d === void 0 ? void 0 : _d.setEndDate(moment());
            _filtros = {
                buscar: "",
                objectId: "",
                tipoDaChave: "",
                objectIdPastaTemplate: "",
                dataInicio: "",
                dataFim: "",
                campoOrdem: "templateNome",
                ordem: "1",
            };
        };
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == "template") {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNavFiltro");
                    $("#menuNavs").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNavFiltro");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNavFiltro");
                    $("#menuNavs").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNavFiltro");
                }
            }
        };
        $scope.consultarPastasDisponiveis = function (pFiltros, pObjectIdPasta) {
            $rootScope.loaderAtivo = true;
            $("#pagTemplates").addClass("blur");
            $("#menuPrincipal").addClass("blur");
            $http({
                method: "GET",
                url: "/templates/consultarPastasComTemplates",
                params: {
                    objectIdPastaTemplate: pFiltros.objectIdPastaTemplate,
                    buscar: pFiltros.buscar,
                    ordem: "templateNome",
                    ordemAscDesc: 1,
                    dataInicio: "",
                    dataFim: "",
                    origem: pFiltros.origem,
                },
            }).then(function (response) {
                $scope.listaPastas = [];
                if (response.data.length > 0) {
                    response.data.forEach(function (pastas) {
                        $scope.listaPastas.push(pastas);
                        if (response.data.length == $scope.listaPastas.length) {
                            $rootScope.loaderAtivo = false;
                            $scope.semTemplates = false;
                            $("#pagTemplates").removeClass("blur");
                            $("#menuPrincipal").removeClass("blur");
                        }
                    });
                }
                setTimeout(function () {
                    $scope.listaPastasAdicionarTemplate = $scope.listaPastas;
                    $scope.$apply();
                    if (pObjectIdPasta) {
                        setTimeout(function () {
                            for (var i = 0; i < $("#listaPastasContato")[0].options.length; i++) {
                                try {
                                    var objetoTemp = $("#listaPastasContato")[0].options[i].value;
                                    if (objetoTemp == pObjectIdPasta) {
                                        $("#listaPastasContato")[0].selectedIndex = i;
                                        break;
                                    }
                                }
                                catch (e) { }
                            }
                        }, 500);
                    }
                }, 500);
                $scope.ordenarTemplates();
            });
        };
        $scope.consultarPastasComTemplates = function (pFiltros, pObjectIdPasta) {
            $rootScope.loaderAtivo = true;
            $("#pagTemplates").addClass("blur");
            $("#menuPrincipal").addClass("blur");
            $http({
                method: "GET",
                url: "/templates/consultarPastasComTemplates",
                params: {
                    objectIdPastaTemplate: pFiltros.objectIdPastaTemplate,
                    buscar: pFiltros.buscar,
                    ordem: "templateNome",
                    ordemAscDesc: 1,
                    dataInicio: pFiltros.dataInicio,
                    dataFim: pFiltros.dataFim,
                    origem: pFiltros.origem,
                },
            }).then(function (response) {
                $scope.listaPastas = [];
                $scope.total = 0;
                if (response.data.length > 0) {
                    response.data.forEach(function (pastas) {
                        pastas.txtMais = "mais";
                        pastas.pastaSelecionada = "";
                        $scope.listaPastas.push(pastas);
                        $scope.total += pastas.objectIdTemplates.length;
                        if (pastas.objectIdTemplates.length > 0) {
                            pastas.objectIdTemplates.forEach(function (template) {
                                template.tempSelecionado = "";
                                template.controleInfos = 0;
                            });
                        }
                    });
                    if (response.data.length == $scope.listaPastas.length) {
                        $rootScope.loaderAtivo = false;
                        $scope.semTemplates = false;
                        $("#pagTemplates").removeClass("blur");
                        $("#menuPrincipal").removeClass("blur");
                    }
                    $scope.contadorSelecionados();
                }
                else {
                    $rootScope.loaderAtivo = false;
                    $scope.semTemplates = false;
                    $("#pagTemplates").removeClass("blur");
                    $("#menuPrincipal").removeClass("blur");
                    if ($scope.listaPastas.length < 0) {
                        switch (response.config.params.origem) {
                            case "campoBuscar":
                                $scope.semTemplatesBusca = true;
                                break;
                            case "campoFiltrar":
                                $scope.semTemplatesFiltro = true;
                                break;
                            case "":
                            case null:
                                $scope.semTemplates = true;
                                break;
                            default:
                                break;
                        }
                    }
                    $scope.buscaOuFiltro = "Seu filtro";
                    $scope.semTemplatesFiltro = true;
                }
            });
        };
        $scope.recarregarTelaTemplates = function () {
            $scope.semTemplatesFiltro = false;
            $scope.semTemplatesBusca = false;
            $scope.recarregarPagina();
        };
        $scope.consultarTemplatesEstatisticas = function (pFiltros, callback) {
            _templateSelecionado.controleInfos = 0;
            $http({
                method: "GET",
                url: "/templates/consultarTemplatesEstatisticas",
                params: {
                    objectId: pFiltros.objectId,
                },
            }).then(function (rConsultarTemplatesEstatisticas) {
                $scope.listaPastas.forEach(function (templates) {
                    templates.objectIdTemplates.forEach(function (template) {
                        if (template.objectIdTemplate ==
                            rConsultarTemplatesEstatisticas.data.objectIdTemplate) {
                            template.template.disparosNome =
                                rConsultarTemplatesEstatisticas.data.disparosNome;
                            template.template.infoDisparos =
                                rConsultarTemplatesEstatisticas.data.infoDisparos;
                            template.controleInfos = 1;
                        }
                    });
                });
                callback("Ok");
            });
        };
        $scope.consultarUsuarios = function (pTemplate) {
            if ($scope.listaUsuarios.length <= 1 ||
                ($scope.listaUsuarios.length > 1 && pTemplate)) {
                $scope.listaUsuarios = [];
                if ($rootScope.Tipo != "U") {
                    $http({
                        method: "GET",
                        url: "/filtros/usuarios",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": $rootScope.IDCrypto,
                        },
                    }).then(function (response) {
                        response.data.forEach(function (time) {
                            time.loginsAtivos.forEach(function (usuario) {
                                if (!pTemplate)
                                    $scope.listaUsuarios.push(usuario);
                                else if (usuario._id ==
                                    (pTemplate.template.objectIdLogin
                                        ? pTemplate.template.objectIdLogin
                                        : _templateSelecionado.objectIdLogin))
                                    $scope.listaUsuarios.push(usuario);
                                if (usuario.fotoPerfil)
                                    usuario.fotoPerfil =
                                        "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                            usuario._id.toString() +
                                            "/" +
                                            usuario.fotoPerfil;
                                else
                                    usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            });
                        });
                        $scope.listaUsuarios.sort(function (a, b) {
                            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        });
                    });
                }
                else {
                    var usuario = {
                        nome: $rootScope.nomeUsuarioLogado,
                        _id: "",
                    };
                    $scope.listaUsuarios.push(usuario);
                }
                if (pTemplate)
                    setTimeout(function () {
                        for (var i = 0; i < $("#usuarioCriacaoTemplate")[0].options.length; i++) {
                            try {
                                var objetoTemp = $("#usuarioCriacaoTemplate")[0].options[i]
                                    .value;
                                if (objetoTemp ==
                                    (pTemplate.template.objectIdLogin
                                        ? pTemplate.template.objectIdLogin
                                        : _templateSelecionado.objectIdLogin)) {
                                    $("#usuarioCriacaoTemplate")[0].selectedIndex = i;
                                    return;
                                }
                            }
                            catch (e) { }
                        }
                    }, 500);
            }
            else if (pTemplate)
                for (var i = 0; i < $("#usuarioCriacaoTemplate")[0].options.length; i++) {
                    try {
                        var objetoTemp = $("#usuarioCriacaoTemplate")[0].options[i].value;
                        if (objetoTemp ==
                            (pTemplate.template.objectIdLogin
                                ? pTemplate.template.objectIdLogin
                                : _templateSelecionado.objectIdLogin)) {
                            $("#usuarioCriacaoTemplate")[0].selectedIndex = i;
                            return;
                        }
                    }
                    catch (e) { }
                }
        };
        $scope.consultarPastasTemplatesPorUsuario = function () {
            var template = [];
            $scope.listaPastas.forEach(function (pasta) {
                if (template.length == 0)
                    template = pasta.objectIdTemplates.filter(function (obj) {
                        return obj.checkSelecionado;
                    });
            });
            $http({
                method: "GET",
                url: "/pastas/consultarPastasTemplatesPorUsuario",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectIdLogin: template.map(function (m) {
                        return m.template.objectIdLogin;
                    })[0],
                },
            }).then(function (response) {
                $scope.listaPastasMover = response.data;
                $scope.listaPastasMover.sort(function (a, b) {
                    return a.nomePasta < b.nomePasta
                        ? -1
                        : a.nomePasta > b.nomePasta
                            ? 1
                            : 0;
                });
            });
        };
        $scope.consultarPastasPorUsuario = function (pPasta, pSkip) {
            var usuarioSelecionado = $("#usuarioCriacaoTemplate")[0]
                .selectedOptions[0].value;
            if (!usuarioSelecionado && !pSkip) {
                setTimeout(function () {
                    $scope.consultarPastasPorUsuario(pPasta, true);
                }, 600);
            }
            else {
                $http({
                    method: "GET",
                    url: "/pastas/consultarPastasTemplatesPorUsuario",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        objectIdLogin: usuarioSelecionado,
                    },
                }).then(function (response) {
                    $scope.listaPastasAdicionarTemplate = response.data;
                    $scope.listaPastasAdicionarTemplate.sort(function (a, b) {
                        return a.nomePasta < b.nomePasta
                            ? -1
                            : a.nomePasta > b.nomePasta
                                ? 1
                                : 0;
                    });
                    if (pPasta)
                        setTimeout(function () {
                            //tempo de carregamento do objeto
                            for (var i = 0; i < $("#listaPastasContato")[0].options.length; i++) {
                                try {
                                    var objetoTemp = $("#listaPastasContato")[0].options[i].value;
                                    if (objetoTemp == pPasta) {
                                        $("#listaPastasContato")[0].selectedIndex = i;
                                    }
                                }
                                catch (e) { }
                            }
                        }, 300);
                });
            }
        };
        $("#usuarioCriacaoTemplate").on("change", function () {
            $scope.consultarPastasPorUsuario();
        });
        $scope.consultarCadencias = function (pBuscar) {
            $scope.listaCadencias = [];
            $rootScope.loaderAtivo = true;
            $(".dvDetalhesCadencias").addClass("blur");
            $scope.semCadenciasBusca = false;
            $scope.buscarCadenciaPorTemplate = "";
            $http({
                method: "GET",
                url: "/disparos/consultarCadencias",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectId: _templateSelecionado.objectIdTemplate,
                    buscar: pBuscar ? pBuscar : "",
                    ordem: "empresa",
                    ordemAscDesc: 1,
                    tipoDaChave: "P",
                    tipoDaConsulta: "P",
                    dataInicio: "",
                    dataFim: "",
                    status: "",
                    segmentacao: "",
                    origem: undefined,
                },
            }).then(function (rConsultaCadencias) {
                $scope.listaCadencias = [];
                $rootScope.loaderAtivo = false;
                $(".dvDetalhesCadencias").removeClass("blur");
                $scope.semCadencias = false;
                $scope.semCadenciasBusca = false;
                if (rConsultaCadencias.data.length > 0) {
                    $scope.semCadenciasBusca = false;
                    $scope.semCadencias = false;
                    rConsultaCadencias.data.forEach(function (cadencia) {
                        cadencia.checkSelecionado = false;
                        $scope.listaCadencias.push(cadencia);
                    });
                }
                else {
                    $rootScope.loaderAtivo = false;
                    $(".dvDetalhesCadencias").removeClass("blur");
                    if ($scope.listaCadencias.length <= 0) {
                        if ($scope.campoHabilitadoBuscar == true) {
                            $scope.semCadenciasBusca = true;
                            $scope.buscaOuFiltro = "Sua busca";
                            $scope.semCadencias = false;
                        }
                        else {
                            $scope.semCadencias = true;
                            $scope.semCadenciasBusca = false;
                        }
                    }
                }
            });
        };
        $scope.identifInputVazio = function (keyEvent) {
            var input = keyEvent.target.value.trim();
            $scope.botaoBuscarVisivel = Boolean(input);
        };
        $scope.localizarSequenciaDoTemplate = function (pCadencia) {
            var resultado = "";
            var item = $scope.listaCadencias
                .find(function (w) {
                return w._id == pCadencia._id;
            })
                .status.find(function (x) {
                return (x._id.objectIdTemplate == _templateSelecionado.objectIdTemplate);
            });
            if (item)
                resultado =
                    item._id.sequencia +
                        (item._id.testeAB ? "/" + item._id.testeAB : "") +
                        " de " +
                        item._id.qtdTemplates;
            return resultado;
        };
        $scope.novaPastaTemplate = function () {
            $scope.nomePasta = "";
            $scope.popupNovaPasta = true;
            $("#idFundoPopupTemplates").fadeIn();
            setTimeout(function () {
                $("#idInptNovaPasta").focus();
            }, 10);
        };
        $scope.renomearPastaTemplate = function (pDados) {
            $scope.objectIdPastaTemplateSelecionado = pDados._id;
            $scope.popupRenomearPasta = true;
            $("#idFundoPopupTemplates").fadeIn();
            setTimeout(function () {
                $("#idInptRenomearPasta").focus();
            }, 90);
            $scope.renomearPasta = pDados.nomePasta;
        };
        $scope.fecharRenomearPastaTemplate = function () {
            $scope.popupRenomearPasta = false;
            $("#idFundoPopupTemplates").fadeOut();
        };
        $scope.excluirTemplate = function (pDados) {
            var qtdCadencias = 0;
            var qtdDisparosJaUsados = 0;
            var qtdTemplatesAssociados = 0;
            var qtdAutomacao = 0;
            var varControle = 0;
            if (pDados == "G") {
                if ($scope.totalTemplatesSelecionados == 0) {
                    $scope.popupRemove = true;
                    $("#idFundoPopupTemplates").fadeIn();
                    setTimeout(function () {
                        document.getElementById("idInptExcluirTemplate").focus();
                    }, 10);
                }
                else {
                    $("#pagTemplates").addClass("blur");
                    $("#menuPrincipal").addClass("blur");
                    $scope.loaderExcluirTemplate = true;
                    $scope.listaPastas.forEach(function (pasta) {
                        pasta.objectIdTemplates
                            .filter(function (obj) {
                            return obj.checkSelecionado;
                        })
                            .forEach(function (pTemplate) {
                            _filtrosEstatisticas = { objectId: pTemplate.objectIdTemplate };
                            $scope.consultarTemplatesEstatisticas(_filtrosEstatisticas, function () {
                                qtdDisparosJaUsados +=
                                    pTemplate.template.infoDisparos.totalEnvios;
                                qtdCadencias += pTemplate.template.disparosNome.length;
                                qtdAutomacao += pTemplate.template.infoDisparos.automacoes;
                                varControle++;
                                if (pTemplate.template.infoDisparos.totalEnvios > 0 ||
                                    pTemplate.template.disparosNome.length > 0)
                                    qtdTemplatesAssociados++;
                                if (varControle == $scope.totalTemplatesSelecionados) {
                                    $scope.txtNaoPodeExcluir =
                                        qtdTemplatesAssociados > 1
                                            ? "Não foi possível excluir estes templates pois, já foram ou estão associados a uma cadência."
                                            : "Não foi possível excluir este template pois, já foi ou está associado a uma cadência.";
                                    $("#pagTemplates").removeClass("blur");
                                    $("#menuPrincipal").removeClass("blur");
                                    $scope.loaderExcluirTemplate = false;
                                    if (qtdCadencias > 0 ||
                                        qtdDisparosJaUsados != 0 ||
                                        qtdAutomacao > 0) {
                                        $scope.popupInformacaoNaoExlcuir = true;
                                        $scope.txtNaoPodeExcluir;
                                    }
                                    else {
                                        $scope.popupRemove = true;
                                        setTimeout(function () {
                                            document
                                                .getElementById("idInptExcluirTemplate")
                                                .focus();
                                        }, 10);
                                    }
                                    $("#idFundoPopupTemplates").fadeIn();
                                }
                            });
                        });
                    });
                }
                _templateSelecionado = "";
            }
            else {
                $("#idFundoPopupTemplates").fadeIn();
                if ($scope.qtdCadenciasTemplateSelecionado > 0) {
                    $scope.txtNaoPodeExcluir =
                        "Não foi possível excluir este template pois, já foi ou está associado a uma cadência.";
                    $scope.popupInformacaoNaoExlcuir = true;
                }
                else {
                    $scope.popupRemove = true;
                    setTimeout(function () {
                        document.getElementById("idInptExcluirTemplate").focus();
                    }, 10);
                }
            }
        };
        $scope.fecharPopupInformacaoNaoExlcuir = function () {
            $scope.popupInformacaoNaoExlcuir = false;
            $("#idFundoPopupTemplates").fadeOut();
        };
        $scope.fecharPopupRemover = function (pFechar) {
            if (pFechar) {
                var templates = [];
                var nomeTemplates = [];
                var nomePastas = [];
                if (_templateSelecionado == "") {
                    $scope.listaPastas.forEach(function (pasta) {
                        pasta.objectIdTemplates
                            .filter(function (obj) {
                            return obj.checkSelecionado;
                        })
                            .forEach(function (template) {
                            templates.push(template);
                            nomeTemplates.push(template.template.templateNome);
                            nomePastas.push(pasta.nomePasta);
                        });
                        if (pasta.checkSelecionado && !pasta.pastaPadrao) {
                            nomePastas.push(pasta.nomePasta);
                            $http({
                                method: "POST",
                                url: "/pastas/apagar",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": $rootScope.IDCrypto,
                                },
                                params: {
                                    objectIdPastaTemplate: pasta._id,
                                },
                            }).then(function () { });
                        }
                    });
                }
                else {
                    templates.push(_templateSelecionado);
                    nomeTemplates = _templateSelecionado.templateNome;
                    nomePastas = _pastaSelecionada;
                }
                $http({
                    method: "POST",
                    url: "/templates/apagarVarios",
                    params: {
                        objectIdTemplate: templates.map(function (m) {
                            return m.objectIdTemplate;
                        }),
                    },
                }).then(function () {
                    _filtros = {
                        buscar: "",
                        objectId: "",
                        tipoDaChave: "",
                        objectIdPastaTemplate: "",
                        dataInicio: "",
                        dataFim: "",
                        campoOrdem: "templateNome",
                        ordem: "1",
                    };
                    if (templates.length == 0)
                        $scope.templateOuPasta = "pasta";
                    else
                        $scope.templateOuPasta = "template";
                    var texto = "Templates | Excluiu template";
                    mixpanel.track(texto, {
                        Pasta: nomePastas,
                        "Nome template": nomeTemplates,
                        "Total templates": templates.length,
                        Local_IP: $rootScope.ip,
                    });
                    $scope.consultarPastasComTemplates(_filtros);
                    $scope.fecharDetalhes();
                });
            }
            $scope.popupRemove = false;
            $("#idFundoPopupTemplates").fadeOut();
            $("#dvDetalhesGeral").removeClass("blur");
            $scope.digiteExcluir = "";
            $scope.verificarFiltros();
        };
        $scope.filtrarTemplate = function () {
            if ($rootScope.Tipo != "U")
                $scope.consultarUsuarios();
            $scope.popupFiltrarTemplates = true;
            $("#popUpFiltroTemplateGeral").fadeIn();
            $scope.consultaTemplatesPorPastaFiltro();
        };
        filtrarTemplateNovo = function () {
            if ($rootScope.Tipo != "U")
                $scope.consultarUsuarios();
            $scope.popupFiltrarTemplates = true;
            $("#popUpFiltroTemplateGeral").fadeIn();
            $scope.consultaTemplatesPorPastaFiltro();
        };
        $scope.filtrarTemplatefechar = function (pFechar) {
            _filtros = {
                buscar: "",
                objectId: "",
                tipoDaChave: "",
                objectIdPastaTemplate: "",
                dataInicio: "",
                dataFim: "",
                campoOrdem: "templateNome",
                ordem: "1",
            };
            if (pFechar) {
                $scope.botaoBuscarVisivel = false;
                $scope.buscarTemplates = "";
                $scope.filtroTemplateHabilitado = true;
                var pastaSelecionada = $scope.pastaFiltro ? $scope.pastaFiltro : "";
                var usuarioSelecionado = $scope.usuarioFiltro
                    ? $scope.usuarioFiltro
                    : "";
                _filtros = {
                    buscar: "",
                    objectId: usuarioSelecionado,
                    tipoDaChave: usuarioSelecionado ? "U" : "",
                    objectIdPastaTemplate: pastaSelecionada,
                    dataInicio: $scope.dataInicioFiltro
                        ? new Date($scope.dataInicioFiltro)
                        : "",
                    dataFim: $scope.dataFinalFiltro
                        ? new Date($scope.dataFinalFiltro)
                        : "",
                    campoOrdem: "templateNome",
                    ordem: "1",
                    origem: "campoFiltrar",
                };
                $scope.consultarPastasComTemplates(_filtros);
                $scope.zerarOrdenacao();
                if ($("#sltDataTemplates")[0].value == "" &&
                    ($scope.pastaFiltro == null || $scope.pastaFiltro == undefined) &&
                    ($scope.usuarioFiltro == null || $scope.usuarioFiltro == undefined)) {
                    $("#btnFiltrarTemplate")
                        .removeClass()
                        .addClass("btnVoltarPadroNovoFiltro");
                    $("#idImgFiltrarTemp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
                    $scope.filtroTemplateHabilitado = false;
                }
                else {
                    $("#btnFiltrarTemplate")
                        .removeClass()
                        .addClass("btnFiltroTempNovoHabilitado");
                    $("#idImgFiltrarTemp").attr("src", "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
                }
                $scope.montarNomeFiltros();
            }
            $scope.popupFiltrarTemplates = false;
            $("#popUpFiltroTemplateGeral").fadeOut();
        };
        $("#sltDataTemplates").daterangepicker(__assign(__assign({}, DateRangePicker.config), { ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataTemplates").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltro = picker.startDate.toDate();
            $scope.dataFinalFiltro = picker.endDate.toDate();
        });
        $("#sltDataTemplates").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $scope.ordecacaoPasta = function () {
            if ($("#ordemPasta")[0].selectedOptions[0].text == "A-Z" ||
                $("#ordemPasta")[0].selectedOptions[0].text == "Menor data")
                $scope.reverse = false;
            else
                $scope.reverse = true;
            $scope.propertyName = $("#ordemPasta")[0].selectedOptions[0].value;
            $scope.propertyNameTemplate =
                $("#ordemPasta")[0].selectedOptions[0].value == "nomePasta"
                    ? "template.templateNome"
                    : "template.objectIdTemplate";
        };
        $("#ordemCadencia").on("change", function () {
            if ($("#ordemCadencia")[0].selectedOptions[0].text == "A-Z" ||
                $("#ordemCadencia")[0].selectedOptions[0].text == "Menor data")
                $scope.reverseDetalhes = false;
            else
                $scope.reverseDetalhes = true;
            $scope.propertyNameDetalhes =
                $("#ordemCadencia")[0].selectedOptions[0].value;
            $scope.$apply();
        });
        $scope.abrirSalvarTemplates = function () {
            $("#abrirSubMenuTemplates").show();
            $rootScope.subMenuTemplates = true;
        };
        $(document).mouseup(function (e) {
            var subMenu = $("#abrirSubMenuTemplates");
            var filtroUsuario = $("#idFiltroUsuariosTemp");
            var inputFiltroUsuario = $("#buscarUsuariosTemp");
            var filtroPasta = $("#idFiltroPastaTemp");
            if (!filtroPasta.is(e.target) && filtroPasta.has(e.target).length === 0) {
                $scope.filtroPastaTempAberto = false;
                filtroPasta.hide();
            }
            if (!filtroUsuario.is(e.target) &&
                filtroUsuario.has(e.target).length === 0) {
                $scope.filtroUsuariosTempAberto = false;
                filtroUsuario.hide();
            }
            if (inputFiltroUsuario.is(e.target)) {
                $scope.filtroUsuariosTempAberto = true;
            }
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $scope.clickCheckPasta = function (pPasta) {
            $scope.nomePastaSelecionado = pPasta.nomePasta;
            $scope.objectIdPastaTemplateSelecionado = pPasta._id;
            if (!pPasta.checkSelecionado) {
                pPasta.checkSelecionado = false;
                pPasta.objectIdTemplates.forEach(function (template) {
                    template.checkSelecionado = false;
                });
            }
            else {
                pPasta.checkSelecionado = true;
                pPasta.objectIdTemplates.forEach(function (template) {
                    template.checkSelecionado = true;
                });
            }
            $scope.contadorSelecionados();
        };
        $scope.clickCheckTodos = function () {
            _clickCheckTodos = !_clickCheckTodos;
            $scope.listaPastas.forEach(function (item) {
                item.checkSelecionado = _clickCheckTodos;
                item.objectIdTemplates.forEach(function (template) {
                    template.checkSelecionado = item.checkSelecionado;
                });
            });
            $scope.contadorSelecionados();
        };
        $scope.limparTodosSelecionados = function () {
            $scope.listaPastas.forEach(function (item) {
                item.checkSelecionado = false;
                item.objectIdTemplates.forEach(function (template) {
                    template.checkSelecionado = item.checkSelecionado;
                });
            });
            $scope.contadorSelecionados();
        };
        $scope.clickCheckTemplate = function (pObjectIdPasta, pTemplate) {
            if (!pTemplate.checkSelecionado) {
                pTemplate.checkSelecionado = false;
            }
            else {
                pTemplate.checkSelecionado = true;
                $rootScope.templateSelecionadoCheck = pTemplate.template;
                $rootScope.pastaDoTemplateSelecionadoCheck = pObjectIdPasta;
            }
            $scope.contadorSelecionados();
        };
        $scope.fecharTodasDivs = function () {
            $("#detalhesTemplatesCriado").fadeOut();
            $("#nomedotemplateprincipal").fadeIn();
            $("#numeroDeTemplates").fadeIn();
            $("#nomeDosTemplates").fadeIn();
            $("#mouseHover").removeClass("borderWhite");
            $("#listradoTemplate").removeClass("backgroundColorTemplateBranco");
        };
        $scope.abrirdetalhesPastas = function (pId) {
            $scope.fecharTodasDivs();
            if ($("#listradoTemplate" + pId).hasClass("backgroundColorTemplateBranco")) {
                $("#detalhesTemplatesCriado" + pId).fadeOut();
                $("#numeroDeTemplates" + pId).fadeIn();
                $("#nomeDosTemplates" + pId).fadeIn();
                $("#nomedotemplateprincipal" + pId).fadeIn();
                $("#mouseHover" + pId).removeClass("borderWhite");
                $("#mouseHover" + pId).addClass("bordaEsquerdaListaTemplate");
                $("#listradoTemplate" + pId).removeClass("backgroundColorTemplateBranco");
            }
            else {
                $("#detalhesTemplatesCriado" + pId).fadeIn();
                $("#nomedotemplateprincipal" + pId).fadeOut();
                $("#numeroDeTemplates" + pId).fadeOut();
                $("#nomeDosTemplates" + pId).fadeOut();
                $("#mouseHover" + pId).addClass("borderWhite");
                $("#mouseHover" + pId).removeClass("bordaEsquerdaListaTemplate");
                $("#listradoTemplate" + pId).addClass("backgroundColorTemplateBranco");
            }
        };
        $scope.coresPassoPassoInicial = function () {
            $("#dvTraco1")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoAzulPassoPasso");
            $("#spanInicio")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtAzulPassoPasso");
            $("#imgPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $("#dvTraco2")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#dvTraco2")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanCriacao")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#spanCriacao")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#dvTraco3")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#dvTraco3")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanTeste")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#spanTeste")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $("#dvTraco4")
                .removeClass("dvTracoVerdePassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#dvTraco4")
                .removeClass("dvTracoAzulPassoPasso")
                .addClass("dvTracoCinzaPassoPasso");
            $("#spanFinalizacao")
                .removeClass("txtVerdePassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#spanFinalizacao")
                .removeClass("txtAzulPassoPasso")
                .addClass("txtCinzaPassoPasso");
            $("#imgPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
        };
        $scope.limparVariaveis = function () {
            $scope.nomeNovoTemplate = "";
            $scope.assuntoNovoTemplate = "";
            $scope.nomeEmailTeste = "";
            $scope.nomePasta = "";
            $("#listaPastasContato")[0].selectedIndex = 0;
            $scope.nomeBtnAvancar = "AVANÇAR";
            _conteudoTemplate = [];
            $("#listaPastasContato").removeClass("corTextoErro");
            $scope.dvMensagemPasta = false;
            $(".inptNomeTemplate").removeClass("corTextoErro");
            $("#dvMensagemNomeTemplate").fadeOut();
            $("#usuarioCriacaoTemplate")[0].selectedIndex = 0;
            $scope.templateSendoEditado = false;
            if (tinymce.activeEditor.initialized) {
                tinymce.editors["txtAreaTemplate"].setContent("");
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
            }
            else {
                tinymce.get("txtAreaTemplate").on("init", function (e) {
                    tinymce.editors["txtAreaTemplate"].setContent("");
                    $rootScope.loaderAtivo = false;
                    $("#dvBlur").removeClass("blur");
                });
            }
        };
        $scope.abrirFormNovoTemplate = function (edicao) {
            _countDiv = 1;
            showEtapa(_countDiv);
            $("#formNovoTemplate").fadeIn();
            $scope.nomeAcaoTemplate = "editado";
            $scope.renomeieOuNovoTemplate = "Renomeie o";
            $scope.crieOuEdite = "Edite o";
            $scope.criacaoOuEdicaoPasso = "Edição";
            $scope.novo = "";
            tinymce.editors["txtAreaTemplate"].on("focus", function (e) {
                _inptAssunto = false;
            });
            setTimeout(function () {
                $("#idNomeNovoTemplate").focus();
            }, 90);
            if (!edicao) {
                $scope.limparVariaveis();
                $scope.nomeAcaoTemplate = "criado";
                $scope.renomeieOuNovoTemplate = "Nomeie o novo";
                $scope.crieOuEdite = "Crie um novo";
                $scope.criacaoOuEdicaoPasso = "Criação";
                $scope.novo = "novo";
                $scope.templateSendoEditado = false;
                if ($rootScope.Tipo == "A")
                    $scope.consultarUsuarios();
            }
        };
        $scope.fecharFormNovoTemplate = function () {
            $rootScope.loaderAtivo = true;
            $("#dvBlur").addClass("blur");
            $scope.limparVariaveis();
            $scope.esconderEtapas();
            $("#formNovoTemplate").fadeOut();
        };
        $scope.atualizarNomeTemplate = function () {
            if ($scope.nomeNovoTemplate != null || $scope.nomeNovoTemplate) {
                $(".inptNomeTemplate").removeClass("corTextoErro");
                $("#dvMensagemNomeTemplate").fadeOut();
                _conteudoTemplate.nomeTemplate = $scope.nomeNovoTemplate;
            }
        };
        $scope.inptCorAssuntoNovoTemplate = function () {
            _inptAssunto = true;
            if ($scope.assuntoNovoTemplate ||
                $scope.assuntoNovoTemplate != null ||
                $scope.assuntoNovoTemplate != "") {
                $("#divTxtAssuntoBounce").removeClass("corTextoErro");
                $("#dvMensagemDefinicaoTemplate").fadeOut();
                _conteudoTemplate.assuntoNovoTemplate = $scope.assuntoNovoTemplate;
            }
        };
        $("#inptAssuntoNovoTemplate").focusin(function () {
            _inptAssunto = true;
        });
        $scope.avancar = function () {
            var qtdErros = 0;
            if (_countDiv == 1) {
                if (tinymce.activeEditor.initialized) {
                    if (!$scope.nomeNovoTemplate ||
                        $scope.nomeNovoTemplate == null ||
                        $scope.nomeNovoTemplate == "") {
                        $(".inptNomeTemplate").addClass("corTextoErro");
                        $("#dvMensagemNomeTemplate").fadeIn();
                        qtdErros++;
                    }
                    if ($rootScope.Tipo == "U") {
                        verificaAcessoAgenda(null);
                    }
                    else {
                        verificaAcessoAgenda($scope.usuarioCriacaoTemplate);
                    }
                    if ($("#listaPastasContato")[0].selectedIndex == 0) {
                        $("#listaPastasContato").addClass("corTextoErro");
                        $scope.dvMensagemPasta = true;
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        _countDiv++;
                        $("#btnVoltar").show();
                        setTimeout(function () {
                            $("#inptAssuntoNovoTemplate").focus();
                        }, 90);
                    }
                    showEtapa(_countDiv);
                    if (!_conteudoTemplate._id) {
                        $scope.salvarTemplate(_conteudoTemplate);
                    }
                    else {
                        var conteudo = tinymce.editors["txtAreaTemplate"].getContent();
                        var listaLinks = conteudo.split('href="');
                        var listaLinksSalvar = [];
                        if (listaLinks.length > 1) {
                            var _i = 0;
                            listaLinks.forEach(function (iLink) {
                                if (_i > 0)
                                    listaLinksSalvar.push(iLink.split('"')[0]);
                                _i++;
                            });
                        }
                        _conteudoTemplate.corpoNovoTemplate = encodeURI(conteudo);
                        _conteudoTemplate.textCorpoNovoTemplate = tinymce.editors["txtAreaTemplate"].getContent({ format: "text" });
                        _conteudoTemplate.listaLinksSalvar = listaLinksSalvar;
                    }
                    $scope.getInfoChat();
                    $scope.getInfoAgenda();
                }
                else {
                    $rootScope.loaderAtivo = true;
                    $("#dvBlur").addClass("blur");
                    tinymce.get("txtAreaTemplate").on("init", function (e) {
                        _countDiv = 1;
                        $rootScope.loaderAtivo = false;
                        $("#dvBlur").removeClass("blur");
                        $scope.avancar();
                    });
                }
            }
            else if (_countDiv == 2) {
                var qtdErrosSegundtaTela = 0;
                if (!$scope.assuntoNovoTemplate ||
                    $scope.assuntoNovoTemplate == null ||
                    $scope.assuntoNovoTemplate == "") {
                    $("#divTxtAssuntoBounce").addClass("corTextoErro");
                    $("#dvMensagemDefinicaoTemplate").fadeIn();
                    qtdErrosSegundtaTela++;
                }
                if (tinymce.editors["txtAreaTemplate"].getContent() == null ||
                    tinymce.editors["txtAreaTemplate"].getContent() == "<div>&nbsp;</div>") {
                    $("#dvMensagemDefinicaoCorpoTemplate").fadeIn();
                    $(".mce-tinymce").css("border", "1px solid #E04747");
                    qtdErrosSegundtaTela++;
                }
                else {
                    var conteudo = tinymce.editors["txtAreaTemplate"].getContent();
                    var listaLinks = conteudo.split('href="');
                    var listaLinksSalvar = [];
                    if (listaLinks.length > 1) {
                        var _i = 0;
                        listaLinks.forEach(function (iLink) {
                            if (_i > 0)
                                listaLinksSalvar.push(iLink.split('"')[0]);
                            _i++;
                        });
                    }
                    _conteudoTemplate.assuntoNovoTemplate = $scope.assuntoNovoTemplate;
                    _conteudoTemplate.corpoNovoTemplate = encodeURI(conteudo);
                    _conteudoTemplate.textCorpoNovoTemplate = tinymce.editors["txtAreaTemplate"].getContent({ format: "text" });
                    _conteudoTemplate.listaLinksSalvar = listaLinksSalvar;
                    $("#dvMensagemDefinicaoCorpoTemplate").fadeOut();
                    $(".mce-tinymce").css("border", "1px solid rgba(0,0,0,0.2)");
                }
                if (qtdErrosSegundtaTela == 0) {
                    _countDiv++;
                    showEtapa(_countDiv);
                }
                if (!_conteudoTemplate._id) {
                    $scope.salvarTemplate(_conteudoTemplate);
                }
            }
            else {
                if (_countDiv < 4) {
                    _countDiv++;
                    $("#btnVoltar").show();
                    if (_countDiv == 4) {
                        _conteudoTemplate.tipoTemplate = "A";
                        if (!_conteudoTemplate._id) {
                            $scope.salvarTemplate(_conteudoTemplate);
                        }
                        else {
                            $scope.atualizarTemplate(_conteudoTemplate);
                        }
                        $("#btnVoltar").hide();
                        $scope.nomeBtnAvancar = "FECHAR";
                    }
                    showEtapa(_countDiv);
                }
                else {
                    if (!$scope.templateSendoEditado) {
                        mixpanel.track("Templates | Criou um template", {
                            "ID pasta": $("#listaPastasContato")[0].value,
                            "Nome template": _conteudoTemplate.nomeTemplate,
                            Local_IP: $rootScope.ip,
                        });
                    }
                    else {
                        mixpanel.track("Templates | Editou um template", {
                            "ID pasta": $("#listaPastasContato")[0].value,
                            "Nome template": _conteudoTemplate.nomeTemplate,
                            Local_IP: $rootScope.ip,
                        });
                    }
                    $scope.limparDadosTemplate();
                    $scope.nomeTemplateSelecionado = _conteudoTemplate.nomeTemplate;
                    $scope.assuntoTemplateSelecionado =
                        _conteudoTemplate.assuntoNovoTemplate;
                    $scope.textoTemplateSelecionado =
                        _conteudoTemplate.textCorpoNovoTemplate;
                    document.getElementById("areaMsgTemplate").innerHTML = decodeURI(_conteudoTemplate.corpoNovoTemplate);
                    $scope.fecharFormNovoTemplate();
                    $scope.recarregarTelaTemplates();
                    $scope.consultarPastasComTemplates(_filtros);
                }
            }
        };
        $scope.voltar = function () {
            if (_countDiv == 4 && $scope.novaCadencia.disparoSequencias.sequencias[0])
                _countDiv--;
            if (_countDiv == 6)
                $scope.fecharFormNovaCadencia();
            else if (_countDiv > 1)
                _countDiv--;
            if (_countDiv == 1)
                $("#btnVoltar").hide();
            showEtapa(_countDiv);
        };
        $scope.validarPasta = function () {
            if ($scope.listaPastasContato != null || $scope.listaPastasContato) {
                $("#listaPastasContato").removeClass("corTextoErro");
                $scope.dvMensagemPasta = false;
            }
        };
        $scope.criarNovoTemplate = function () {
            $scope.fecharFormNovoTemplate();
            $scope.abrirFormNovoTemplate();
        };
        $scope.esconderEtapas = function () {
            $("#dvEtapa1").hide();
            $("#dvEtapa2").hide();
            $("#dvEtapa3").hide();
            $("#dvEtapa4").hide();
        };
        showEtapa = function (etapa) {
            $scope.esconderEtapas();
            var x = etapa + 1;
            switch (etapa) {
                case 1:
                    $scope.coresPassoPassoInicial();
                    $("#btnVoltar").hide();
                    break;
                case 2:
                    $("#dvTraco1")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoVerdePassoPasso");
                    $("#spanInicio")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtVerdePassoPasso");
                    $("#imgPasso1").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#dvTraco2")
                        .removeClass("dvTracoVerdePassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#dvTraco2")
                        .removeClass("dvTracoCinzaPassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanCriacao")
                        .removeClass("txtVerdePassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#spanCriacao")
                        .removeClass("txtCinzaPassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $("#dvTraco3")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoCinzaPassoPasso");
                    $("#spanTeste")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtCinzaPassoPasso");
                    $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    $("#btnVoltar").show();
                    break;
                case 3:
                    $("#dvTraco2")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoVerdePassoPasso");
                    $("#spanCriacao")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtVerdePassoPasso");
                    $("#imgPasso2").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#dvTraco3")
                        .removeClass("dvTracoCinzaPassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#dvTraco3")
                        .removeClass("dvTracoVerdePassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanTeste")
                        .removeClass("txtCinzaPassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#spanTeste")
                        .removeClass("txtVerdePassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $("#dvTraco4")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoCinzaPassoPasso");
                    $("#spanFinalizacao")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtCinzaPassoPasso");
                    $("#imgPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 4:
                    $("#dvTraco3")
                        .removeClass("dvTracoAzulPassoPasso")
                        .addClass("dvTracoVerdePassoPasso");
                    $("#spanTeste")
                        .removeClass("txtAzulPassoPasso")
                        .addClass("txtVerdePassoPasso");
                    $("#imgPasso3").attr("src", "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $("#dvTraco4")
                        .removeClass("dvTracoCinzaPassoPasso")
                        .addClass("dvTracoAzulPassoPasso");
                    $("#spanFinalizacao")
                        .removeClass("txtCinzaPassoPasso")
                        .addClass("txtAzulPassoPasso");
                    $("#imgPasso4").attr("src", "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    break;
                default:
                    return;
            }
            $("#dvEtapa" + etapa.toString()).fadeIn();
        };
        $scope.criarNovaPasta = function () {
            $scope.nomePasta = "";
            $scope.popupNovaPasta = true;
            if (!$scope.popupDuplicar) {
                $("#idFundoPopupTemplates").fadeIn();
            }
            else {
                $("#idPopupDuplicar").hide();
            }
            setTimeout(function () {
                $("#idInptNovaPasta").focus();
            }, 10);
        };
        $scope.fecharPopupNovaPasta = function () {
            $scope.popupNovaPasta = false;
            if (!$scope.popupDuplicar) {
                $("#idFundoPopupTemplates").fadeOut();
            }
            else {
                $("#idPopupDuplicar").show();
                setTimeout(function () {
                    $("#idInptNomeDuplicar").focus();
                }, 10);
            }
            $scope.nomePasta = "";
        };
        $scope.salvarNovaPasta = function () {
            if (!$scope.nomePasta ||
                $scope.nomePasta == null ||
                $scope.nomePasta == "") {
                $("#dvMensagemNomePasta").fadeIn();
            }
            else {
                $http({
                    method: "POST",
                    url: "/pastas/salvar",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        nomePasta: $scope.nomePasta,
                        objectIdLogin: $scope.usuarioCriacaoTemplate,
                    },
                }).then(function (rRetorno) {
                    _filtros = {
                        buscar: "",
                        objectId: "",
                        tipoDaChave: "",
                        objectIdPastaTemplate: "",
                        dataInicio: "",
                        dataFim: "",
                        campoOrdem: "templateNome",
                        ordem: "1",
                    };
                    mixpanel.track("Templates | Criou nova pasta", {
                        "Nome pasta": $scope.nomePasta,
                        Local_IP: $rootScope.ip,
                    });
                    $scope.consultarPastasDisponiveis(_filtros, rRetorno.data._id);
                    $scope.fecharPopupNovaPasta();
                    $scope.recarregarTelaTemplates();
                    if ($scope.popupDuplicar) {
                        document.getElementById("idSpanPastaDuplicar").innerText =
                            rRetorno.data.nomePasta;
                        $scope.pastaFiltroDuplicar = rRetorno.data._id;
                    }
                });
            }
        };
        $scope.renomearPastaTemplateSalvar = function () {
            if (!$scope.renomearPasta ||
                $scope.renomearPasta == null ||
                $scope.renomearPasta == "") {
                $scope.fecharRenomearPastaTemplate();
            }
            else {
                $http({
                    method: "POST",
                    url: "/pastas/renomearPastaTemplate",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        objectIdPastaTemplate: $scope.objectIdPastaTemplateSelecionado,
                        nomePasta: $scope.renomearPasta,
                    },
                }).then(function () {
                    _filtros = {
                        buscar: "",
                        objectId: "",
                        tipoDaChave: "",
                        objectIdPastaTemplate: "",
                        dataInicio: "",
                        dataFim: "",
                        campoOrdem: "templateNome",
                        ordem: "1",
                    };
                    mixpanel.track("Templates | Renomeou uma pasta", {
                        "Nome antigo": $scope.nomePastaSelecionado,
                        "Nome novo": $scope.renomearPasta,
                        Local_IP: $rootScope.ip,
                    });
                    $scope.consultarPastasComTemplates(_filtros);
                    $scope.fecharRenomearPastaTemplate();
                });
            }
        };
        $scope.atualizarNomePasta = function () {
            if ($scope.nomePasta ||
                $scope.nomePasta != null ||
                $scope.nomePasta != "")
                $("#dvMensagemNomePasta").fadeOut();
        };
        $(document).ready(function () {
            tinymce.editors = [];
            setTimeout(function () {
                tinymce.init({
                    selector: "#txtAreaDetalheTemplate",
                    language: "pt_BR",
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: "div",
                    width: 970,
                    height: 390,
                    invalid_elements: "img",
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                        "textcolor",
                        "preview",
                        "table",
                    ],
                    toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ",
                    target_list: false,
                    content_css: ["//www.tinymce.com/css/codepen.min.css"],
                });
            }, 500);
        });
        $scope.detalhesTemplates = function (pObjectIdPasta, pTemplate) {
            $scope.limparTodosSelecionados();
            _filtrosEstatisticas = {
                objectId: pTemplate.objectIdTemplate,
            };
            $scope.totalTemplatesSelecionados = 1;
            $scope.esteplural = "este";
            $scope.templatesplural = "template";
            _templateSelecionado = pTemplate.template;
            _pastaSelecionada = pObjectIdPasta;
            $scope.limparDadosTemplate();
            $scope.consultarTemplatesEstatisticas(_filtrosEstatisticas, function () {
                $scope.qtdCadenciasTemplateSelecionado =
                    _templateSelecionado.disparosNome.length;
                $scope.nomeTemplateSelecionado = _templateSelecionado.templateNome;
                $scope.assuntoTemplateSelecionado = _templateSelecionado.assunto;
                $scope.textoTemplateSelecionado = _templateSelecionado.templateText;
                $scope.assinaturaTemplateSelecionado = _templateSelecionado.assinatura;
            });
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            $scope.clickMenuDetalhes("d");
            $("#dvDetalhesGeral").fadeIn();
        };
        esconderTodasDivsDetalhes = function () {
            $("#dvDetalhesCadencias").hide();
            $("#dvDetalhesDados").hide();
            $("#dvDetalhesMetricas").hide();
            $("#dvDetalhesResumo").hide();
        };
        removerDestaqueMenu = function () {
            $("#liDetalhesCadencias").removeClass("liMenuDetalhesActive");
            $("#liDetalhesDados").removeClass("liMenuDetalhesActive");
            $("#liDetalhesMetricas").removeClass("liMenuDetalhesActive");
            $("#liDetalhesResumoTemplates").removeClass("liMenuDetalhesActive");
        };
        $scope.fecharDetalhes = function () {
            _templateSelecionado = "";
            _pastaSelecionada = "";
            $("#dvDetalhesGeral").fadeOut();
            $scope.semCadenciasBusca = false;
            $scope.campoHabilitadoBuscar = false;
            $scope.totalTemplatesSelecionados = 0;
        };
        $scope.clickMenuDetalhes = function (pDetalhe, pTemplate) {
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            if (pDetalhe == "d") {
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
                document.getElementById("areaMsgTemplate").innerHTML =
                    _templateSelecionado.template;
                document.getElementById("areaMsgTemplateAssinatura").innerHTML =
                    _templateSelecionado.assinatura;
                $("#liDetalhesDados").addClass("liMenuDetalhesActive");
                $("#dvDetalhesDados").fadeIn();
            }
            else if (pDetalhe == "m") {
                $rootScope.loaderAtivo = true;
                $("#dvBlur").addClass("blur");
                $("#liDetalhesMetricas").addClass("liMenuDetalhesActive");
                $("#dvDetalhesMetricas").fadeIn();
                $("#labe1").addClass("active");
                $("#labe2").removeClass("active");
                $("#labe3").removeClass("active");
                $scope.$broadcast("getMetricas", {
                    tela: "T",
                    objectId: _templateSelecionado.objectIdTemplate,
                });
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
                $("#sltDataMetricas").hide();
                $("#sequenciaFiltro").hide();
            }
            else if (pDetalhe == "c") {
                $scope.consultarCadencias();
                $("#liDetalhesCadencias").addClass("liMenuDetalhesActive");
                $("#dvDetalhesCadencias").fadeIn();
                $scope.semCadenciasBusca = false;
            }
            else if (pDetalhe == "r") {
                $("#liDetalhesResumoTemplates").addClass("liMenuDetalhesActive");
                $("#dvDetalhesResumo").fadeIn();
            }
        };
        $scope.avancarTemplatePg1 = function () {
            $("#dvCamposAdicionarTemplatesPg1").hide();
            $("#dvCamposAdicionarTemplatesPg2").fadeIn();
        };
        $scope.voltarTemplatePg2 = function () {
            $("#dvCamposAdicionarTemplatesPg1").fadeIn();
            $("#dvCamposAdicionarTemplatesPg2").hide();
        };
        $scope.avancarTemplatePg2 = function () {
            $("#dvCamposAdicionarTemplatesPg2").hide();
            $("#dvCamposAdicionarTemplatesPg3").fadeIn();
        };
        $scope.voltarTemplatePg3 = function () {
            $("#dvCamposAdicionarTemplatesPg2").fadeIn();
            $("#dvCamposAdicionarTemplatesPg3").hide();
        };
        $scope.fecharAdicionar = function () {
            $("#dvDetalhesGeral").fadeOut();
            $("#dvAdicionarTemplates").fadeOut();
        };
        $scope.salvarTemplate = function () {
            if ($("#usuarioCriacaoTemplate")[0].selectedOptions[0].value)
                _conteudoTemplate.objectIdLogin = $("#usuarioCriacaoTemplate")[0].selectedOptions[0].value;
            $http({
                method: "POST",
                url: "/templates/salvar",
                data: {
                    objectIdLogin: _conteudoTemplate.objectIdLogin,
                    nome: _conteudoTemplate.nomeTemplate
                        ? _conteudoTemplate.nomeTemplate
                        : " ",
                    assunto: _conteudoTemplate.assuntoNovoTemplate
                        ? _conteudoTemplate.assuntoNovoTemplate
                        : " ",
                    template: _conteudoTemplate.corpoNovoTemplate
                        ? _conteudoTemplate.corpoNovoTemplate
                        : " ",
                    templateText: _conteudoTemplate.textCorpoNovoTemplate
                        ? _conteudoTemplate.textCorpoNovoTemplate
                        : " ",
                    listaLinks: _conteudoTemplate.listaLinksSalvar
                        ? _conteudoTemplate.listaLinksSalvar
                        : null,
                    tipoTemplate: _conteudoTemplate.tipoTemplate
                        ? _conteudoTemplate.tipoTemplate
                        : "R",
                    objectIdPastaTemplate: $("#listaPastasContato")[0].selectedOptions[0].value != ""
                        ? $("#listaPastasContato")[0].selectedOptions[0].value
                        : null,
                    dataInclusao: new Date(),
                },
            }).then(function (rRetorno) {
                _conteudoTemplate._id = rRetorno.data._id;
            });
            $("#dvCamposAdicionarTemplates").hide();
            $("#dvFinalizarTemplates").fadeIn();
        };
        $scope.atualizarTemplate = function () {
            $http({
                method: "POST",
                url: "/templates/atualizar",
                data: {
                    _id: _conteudoTemplate._id,
                    objectIdLogin: _conteudoTemplate.objectIdLogin,
                    nome: _conteudoTemplate.nomeTemplate
                        ? _conteudoTemplate.nomeTemplate
                        : " ",
                    assunto: _conteudoTemplate.assuntoNovoTemplate
                        ? _conteudoTemplate.assuntoNovoTemplate
                        : " ",
                    template: _conteudoTemplate.corpoNovoTemplate
                        ? _conteudoTemplate.corpoNovoTemplate
                        : " ",
                    templateText: _conteudoTemplate.textCorpoNovoTemplate
                        ? _conteudoTemplate.textCorpoNovoTemplate
                        : " ",
                    listaLinks: _conteudoTemplate.listaLinksSalvar
                        ? _conteudoTemplate.listaLinksSalvar
                        : " ",
                    tipoTemplate: _conteudoTemplate.tipoTemplate
                        ? _conteudoTemplate.tipoTemplate
                        : "R",
                    objectIdPastaTemplate: $("#listaPastasContato")[0].selectedOptions[0].value != ""
                        ? $("#listaPastasContato")[0].selectedOptions[0].value
                        : null,
                    dataAlteracao: new Date(),
                },
            }).then(function () { });
            $scope.camposAdicionar = false;
            $("#dvCamposDetalhesTemplatesPg1").hide();
            $("#dvFinalizarTemplatesDetalhes").fadeIn();
        };
        $scope.carregarTemplateEdicao = function (pDados, editarTemplate, editarPasta) {
            $scope.consultarUsuarios(editarTemplate ? editarTemplate : _templateSelecionado);
            $scope.consultarPastasPorUsuario(pDados == "TE" ? editarPasta._id : _pastaSelecionada);
            var templateSelecionaParaEdicao = null;
            var objectIdPastaTempSel = null;
            $scope.templateSendoEditado = true;
            if (pDados == "TI") {
                templateSelecionaParaEdicao = _templateSelecionado;
                objectIdPastaTempSel = _pastaSelecionada;
            }
            else if (pDados == "TE") {
                templateSelecionaParaEdicao = editarTemplate;
                objectIdPastaTempSel = editarPasta._id;
            }
            if (templateSelecionaParaEdicao != null) {
                $rootScope.loaderAtivo = true;
                $http({
                    method: "GET",
                    url: "/templates/consultarTemplatesPorID",
                    params: {
                        objectIdTemplate: [templateSelecionaParaEdicao.objectIdTemplate],
                        limit: 1,
                        skip: 0,
                    },
                })
                    .then(function (retorno) {
                    _conteudoTemplate._id = retorno.data[0]._id;
                    _conteudoTemplate.objectIdLogin = retorno.data[0].objectIdLogin;
                    _conteudoTemplate.nomeTemplate = retorno.data[0].nome;
                    _conteudoTemplate.assuntoNovoTemplate = retorno.data[0].assunto;
                    _conteudoTemplate.corpoNovoTemplate = retorno.data[0].template;
                    _conteudoTemplate.textCorpoNovoTemplate =
                        retorno.data[0].templateText;
                    _conteudoTemplate.tipoTemplate = retorno.data[0].tipoTemplate;
                    $scope.nomeNovoTemplate = _conteudoTemplate.nomeTemplate;
                    $scope.assuntoNovoTemplate = _conteudoTemplate.assuntoNovoTemplate;
                    tinymce.editors["txtAreaTemplate"].setContent(_conteudoTemplate.corpoNovoTemplate);
                    $scope.abrirFormNovoTemplate(true);
                })
                    .finally(function () { return ($rootScope.loaderAtivo = false); });
            }
        };
        $scope.limparDadosTemplate = function () {
            $scope.nomeTemplate = "";
            $scope.usuarioCriacaoTemplate = "";
        };
        $("#dvDetalhesGeral").hide();
        $("#dvAdicionarTemplates").hide();
        $scope.consultarPastasDisponiveis(_filtros);
        $scope.consultarPastasComTemplates(_filtros);
        $(document).ready(function () {
            tinymce.editors = [];
            setTimeout(function () {
                tinymce.init({
                    selector: "#txtAreaTemplate",
                    language: "pt_BR",
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: "div",
                    height: window.screen.height / 3,
                    width: 702,
                    left: 4,
                    invalid_elements: "img",
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                        "textcolor",
                        "preview",
                        "table",
                    ],
                    toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ",
                    target_list: false,
                    content_css: ["//www.tinymce.com/css/codepen.min.css"],
                });
            }, 500);
        });
        $scope.clickMetadadoTemplate = function () {
            if (_inptAssunto) {
                $scope.assuntoNovoTemplate +=
                    " /*" + $scope.metadadoSelecionadoTemplate + "*/";
            }
            else {
                var newNode = tinymce.activeEditor.getDoc().createElement("span");
                newNode.textContent = "/*" + $scope.metadadoSelecionadoTemplate + "*/";
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
            }
            $scope.metadadoSelecionadoTemplate = undefined;
        };
        $scope.enviarEmailTeste = function () {
            if (!$scope.nomeEmailTeste ||
                $scope.nomeEmailTeste == null ||
                $scope.nomeEmailTeste == "") {
                $(".inptEmailTeste").addClass("corTextoErro");
                $("#dvMensagemEmailTeste").fadeIn();
            }
            else {
                $scope.mensagemRetornoEmailTeste = "";
                $("#bttnEnviarEmailTeste")[0].innerText = "Aguarde...";
                $("#bttnEnviarEmailTeste").prop("disabled", true);
                $http({
                    method: "POST",
                    url: "/email/enviarEmailPost",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        emailRecebeTeste: $scope.nomeEmailTeste,
                        tipo: "P",
                        assunto: $scope.assuntoNovoTemplate,
                        mensagem: encodeURI(tinymce.editors["txtAreaTemplate"].getContent()),
                    },
                }).then(function (rEnviarEmailTeste) {
                    $scope.mensagemRetornoEmailTeste = rEnviarEmailTeste.data.mensagem;
                    $("#bttnEnviarEmailTeste")[0].innerText = "TESTAR";
                    $("#bttnEnviarEmailTeste").prop("disabled", false);
                    $("#spanEnvioComSucesso").fadeIn();
                    if (rEnviarEmailTeste.data.envio) {
                        $("#spanEnvioComSucesso").fadeIn();
                        $("#spanEnvioComSucesso")
                            .removeClass("spanEmailTesteNaoEnviado")
                            .addClass("spanEmailTesteEnviado");
                    }
                    else {
                        $("#spanEnvioComSucesso").fadeIn();
                        $("#spanEnvioComSucesso")
                            .removeClass("spanEmailTesteEnviado")
                            .addClass("spanEmailTesteNaoEnviado");
                    }
                    setTimeout(function () {
                        $("#spanEnvioComSucesso").fadeOut();
                    }, 5000);
                });
            }
        };
        $scope.atualizarEmailTeste = function () {
            if ($scope.nomeEmailTeste ||
                $scope.nomeEmailTeste != null ||
                $scope.nomeEmailTeste != "") {
                $(".inptEmailTeste").removeClass("corTextoErro");
                $("#dvMensagemEmailTeste").fadeOut();
            }
        };
        $scope.contadorSelecionados = function () {
            var count = 0;
            $scope.listaPastas.forEach(function (pasta) {
                count += pasta.objectIdTemplates.filter(function (obj) {
                    return obj.checkSelecionado;
                }).length;
            });
            $scope.totalTemplatesSelecionados = count;
            if ($scope.totalTemplatesSelecionados > 1) {
                $scope.nomeSelecionado = "selecionados";
                $scope.esteplural = "estes";
                $scope.templatesplural = "templates";
            }
            else {
                $scope.nomeSelecionado = "selecionado";
                $scope.esteplural = "este";
                $scope.templatesplural = "template";
            }
            var countExterno = 0;
            var countInterno = 0;
            $scope.escondeRenomearPasta = false;
            var arrayVariasPastas = [];
            $scope.listaPastas.forEach(function (itemPasta) {
                countInterno = 0;
                itemPasta.objectIdTemplates.forEach(function (itemTemplate) {
                    countInterno += itemTemplate.checkSelecionado ? 1 : 0;
                });
                arrayVariasPastas.push(countInterno);
                itemPasta.checkSelecionado =
                    (countInterno == itemPasta.objectIdTemplates.length &&
                        countInterno != 0) ||
                        (itemPasta.checkSelecionado &&
                            itemPasta.objectIdTemplates.length == 0);
                countExterno += itemPasta.checkSelecionado ? 1 : 0;
            });
            if (arrayVariasPastas.filter(function (x) {
                return x > 0;
            }).length > 1)
                $scope.escondeRenomearPasta = true;
            $scope.totalPastasSelecionadas = countExterno;
            if ($scope.totalTemplatesSelecionados == 0 &&
                $scope.totalPastasSelecionadas > 0) {
                $scope.nomeSelecionado =
                    $scope.totalPastasSelecionadas == 1
                        ? "pasta selecionada"
                        : "pastas selecionadas";
            }
            if (countExterno < $scope.listaPastas.length) {
                $("#checkTemplates").prop("checked", false);
                _clickCheckTodos = false;
            }
            else {
                $("#checkTemplates").prop("checked", true);
                _clickCheckTodos = true;
            }
        };
        $scope.abrirSubMenu = function (pDados) {
            if (pDados == "G") {
                $("#excluirTemplateSubMenu").addClass("dividerSubMenuExcluir");
                $("#abrirSubMenuTemplates").show();
                $rootScope.subMenuTemplates = true;
                if ($scope.totalPastasSelecionadas > 1 &&
                    $scope.totalTemplatesSelecionados == 0) {
                    $("#excluirTemplateSubMenu").removeClass("dividerSubMenuExcluir");
                }
                var objectIdLoginTemp = undefined;
                $scope.objectIdLoginDiferente = false;
                $scope.totalPastasSelecionadas = 0;
                $scope.listaPastas.forEach(function (item) {
                    $scope.totalPastasSelecionadas += item.checkSelecionado ? 1 : 0;
                    item.objectIdTemplates.forEach(function (itemTemplate) {
                        if (itemTemplate.checkSelecionado) {
                            if (objectIdLoginTemp &&
                                objectIdLoginTemp != item.objectIdLogin) {
                                $scope.objectIdLoginDiferente = true;
                                $("#excluirTemplateSubMenu").removeClass("dividerSubMenuExcluir");
                            }
                            objectIdLoginTemp = item.objectIdLogin;
                        }
                    });
                });
            }
            else if (pDados == "E") {
                $("#subMenuTemplatesEspecifico").show();
                $rootScope.subMenuTemplatesEspecifico = true;
            }
        };
        $scope.tirarBlur = function () {
            $("#pagTemplates").removeClass("blur");
            $("#menuPrincipal").removeClass("blur");
            $("#dvDetalhesGeral").removeClass("blur");
        };
        $(document).mouseup(function (e) {
            var container = $("#subMenuTemplatesEspecifico");
            if (!container.is(e.target) && container.has(e.target).length === 0)
                container.hide();
        });
        $(document).mouseup(function (e) {
            var fundoPopupNovo = $("#idFundoPopupTemplates");
            var filtroPastaDuplicar = $("#idPastaDuplicar");
            var filtroPastaMover = $("#idPastaMover");
            var popUpFiltroTemplate = $("#popUpFiltroTemplateGeral");
            var popUpCompararTemplate = $("#popupCompararTemplatesGeral");
            var data = $(".dr-picker");
            if (popUpFiltroTemplate.has(e.target).length === 0 &&
                data.has(e.target).length === 0) {
                popUpFiltroTemplate.fadeOut();
                $scope.popupFiltrarTemplates = false;
            }
            if (fundoPopupNovo.is(e.target)) {
                if ($scope.popupNovaPasta) {
                    $scope.fecharPopupNovaPasta();
                }
                else if ($scope.popupRemove) {
                    $scope.fecharPopupRemover(false);
                }
                else if ($scope.popupInformacaoNaoExlcuir) {
                    $scope.fecharPopupInformacaoNaoExlcuir();
                }
                else if ($scope.popupRenomearPasta) {
                    $scope.fecharRenomearPastaTemplate();
                }
                else if ($scope.popupDuplicar) {
                    $scope.fecharPopupDuplicar(false);
                }
                else if ($scope.popupMover) {
                    $scope.fecharPopupMover(false);
                }
                $scope.$apply();
            }
            if (!filtroPastaDuplicar.is(e.target) &&
                filtroPastaDuplicar.has(e.target).length === 0) {
                $scope.pastaDuplicarAberto = false;
                filtroPastaDuplicar.hide();
            }
            if (!filtroPastaMover.is(e.target) &&
                filtroPastaMover.has(e.target).length === 0) {
                $scope.pastaMoverAberto = false;
                filtroPastaMover.hide();
            }
            if (popUpCompararTemplate.is(e.target)) {
                popUpCompararTemplate.fadeOut();
                $scope.tirarBlur();
                $rootScope.popupCompararTemplates = false;
            }
        });
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                if ($scope.popupNovaPasta) {
                    $scope.fecharPopupNovaPasta();
                }
                else if ($scope.popupRemove) {
                    $scope.fecharPopupRemover(false);
                }
                else if ($scope.popupInformacaoNaoExlcuir) {
                    $scope.fecharPopupInformacaoNaoExlcuir();
                }
                else if ($scope.popupRenomearPasta) {
                    $scope.fecharRenomearPastaTemplate();
                }
                else if ($scope.popupDuplicar) {
                    $scope.fecharPopupDuplicar(false);
                }
                else if ($scope.popupMover) {
                    $scope.fecharPopupMover(false);
                }
                $scope.$apply();
            }
        });
        $scope.duplicarTemplate = function (pDuplicar, pDuplicarNome) {
            $scope.nomeTemplateDuplicar =
                pDuplicarNome.template.templateNome + " - Cópia";
            $scope.listaDeTemplates = pDuplicarNome;
            $scope.pastaFiltroDuplicar = pDuplicar._id;
            document.getElementById("idSpanPastaDuplicar").innerText =
                pDuplicar.nomePasta;
            $scope.popupDuplicar = true;
            $("#idFundoPopupTemplates").fadeIn();
            setTimeout(function () {
                $("#idInptNomeDuplicar").focus();
            }, 90);
        };
        $scope.fecharPopupDuplicar = function (pFechar) {
            if (pFechar) {
                var template = [];
                $scope.listaPastas.forEach(function (pasta) {
                    template.push($scope.listaDeTemplates);
                });
                var pastaSelecionada = $scope.pastaFiltroDuplicar;
                $http({
                    method: "POST",
                    url: "/templates/duplicar",
                    params: {
                        objectIdPastaTemplate: pastaSelecionada,
                        objectIdTemplate: template.map(function (m) {
                            return m.objectIdTemplate;
                        })[0],
                        nomeTamplate: $scope.nomeTemplateDuplicar,
                    },
                }).then(function () {
                    _filtros = {
                        buscar: "",
                        objectId: "",
                        tipoDaChave: "",
                        objectIdPastaTemplate: "",
                        dataInicio: "",
                        dataFim: "",
                        campoOrdem: "templateNome",
                        ordem: "1",
                    };
                    mixpanel.track("Templates | Duplicou um template", {
                        "Nome template": $scope.nomeTemplateDuplicar,
                        Pasta: pastaSelecionada,
                        Local_IP: $rootScope.ip,
                    });
                    $scope.consultarPastasComTemplates(_filtros);
                });
            }
            $("#idFundoPopupTemplates").fadeOut();
            $scope.popupDuplicar = false;
        };
        $scope.enviarTemplatePara = function (mover) {
            $scope.consultarPastasTemplatesPorUsuario();
            $scope.templateMoverSelecionado = mover;
            document.getElementById("idSpanPastaMover").innerText =
                "Selecione uma pasta";
            $scope.nomePastaMover = "";
            $scope.pastaFiltroMover = "";
            $scope.popupMover = true;
            $("#idFundoPopupTemplates").fadeIn();
        };
        $scope.fecharPopupMover = function (pFechar) {
            if (pFechar) {
                var templates = [];
                var nomeTemplates = "";
                templates.push($scope.templateMoverSelecionado);
                var pastaSelecionada = $scope.pastaFiltroMover;
                var nomePasta = $scope.nomePastaMover;
                nomeTemplates = templates[0].template.templateNome;
                $http({
                    method: "POST",
                    url: "/templates/mover",
                    params: {
                        objectIdPastaTemplate: pastaSelecionada,
                        objectIdTemplate: templates.map(function (m) {
                            return m.objectIdTemplate;
                        }),
                    },
                }).then(function () {
                    _filtros = {
                        buscar: "",
                        objectId: "",
                        tipoDaChave: "",
                        objectIdPastaTemplate: "",
                        dataInicio: "",
                        dataFim: "",
                        campoOrdem: "templateNome",
                        ordem: "1",
                    };
                    $scope.consultarPastasComTemplates(_filtros);
                });
                mixpanel.track("Templates | Moveu o template", {
                    Pasta: pastaSelecionada,
                    "Nome template": nomeTemplates,
                    "Total templates": templates.length,
                    Local_IP: $rootScope.ip,
                });
            }
            $("#idFundoPopupTemplates").fadeOut();
            $scope.popupMover = false;
        };
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                if ($scope.popupRemove) {
                    if ($scope.digiteExcluir == "EXCLUIR")
                        $scope.fecharPopupRemover(true);
                }
                else if ($scope.popupRenomearPasta) {
                    if ($scope.renomearPasta != "")
                        $scope.renomearPastaTemplateSalvar();
                }
                else if ($scope.popupNovaPasta) {
                    if ($scope.nomePasta != "")
                        $scope.salvarNovaPasta();
                }
                else if ($scope.popupDuplicar) {
                    if ($scope.nomeTemplateDuplicar != "" && !$scope.popupNovaPasta)
                        $scope.fecharPopupDuplicar(true);
                }
                else {
                    $scope.campoHabilitadoBuscar = true;
                    $scope.semCadencias = false;
                    if ($("#dvDetalhesCadencias").is(":visible")) {
                        $scope.consultarCadencias($scope.buscarCadenciaPorTemplate);
                    }
                    else {
                        _filtros = {
                            buscar: $scope.buscarTemplates,
                            objectId: "",
                            tipoDaChave: "",
                            objectIdPastaTemplate: "",
                            dataInicio: "",
                            dataFim: "",
                            status: "",
                            segmentacao: "",
                            campoOrdem: "templateNome",
                            ordem: "1",
                            origem: "campoBuscar",
                        };
                        $scope.consultarPastasComTemplates(_filtros);
                        $scope.zerarOrdenacao();
                        $scope.removerFiltros();
                        $scope.filtroTemplateHabilitado = false;
                        $("#btnFiltrarTemplate")
                            .removeClass()
                            .addClass("btnVoltarPadroNovoFiltro");
                        $("#idImgFiltrarTemp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
                    }
                }
            }
        };
        $scope.abrirPopupComparar = function () {
            $("#menuPrincipal").addClass("blur");
            $("#pagTemplates").addClass("blur");
            $rootScope.popupCompararTemplates = true;
            $("#popupCompararTemplatesGeral").fadeIn();
        };
        $scope.fecharPopupComparar = function () {
            $("#menuPrincipal").removeClass("blur");
            $("#pagTemplates").removeClass("blur");
            $rootScope.popupCompararTemplates = false;
        };
        $scope.recarregarTela = function () {
            $scope.botaoBuscarVisivel = false;
            $scope.buscarTemplates = "";
            $scope.consultarPastasComTemplates(_filtros);
            $scope.consultarPastasPorUsuario();
        };
        $scope.mostrarSegundaLinha = function (pDados) {
            _pastaSelecionada = pDados;
            if (_pastaSelecionada.pastaSelecionada == "Selecionada") {
                _pastaSelecionada.pastaSelecionada = "";
                _pastaSelecionada.txtMais = "mais";
                $("#linha" + pDados._id).addClass("subirLinhaTemplate");
                $("#img" + pDados._id).removeClass("classeVirarImagem");
                _pastaSelecionada.objectIdTemplates.forEach(function (templates) {
                    if ((templates.tempSelecionado = "Selecionada")) {
                        $scope.mostrarTerceiraLinha(templates);
                    }
                });
                setTimeout(function () {
                    $("#linha" + pDados._id)
                        .removeClass("subirLinhaTemplate")
                        .addClass("classeInvisivelCad");
                }, 300);
            }
            else {
                _pastaSelecionada.pastaSelecionada = "Selecionada";
                _pastaSelecionada.txtMais = "menos";
                $("#linha" + pDados._id)
                    .removeClass("classeInvisivelCad")
                    .addClass("descerLinhaTemplate");
                $("#img" + pDados._id).addClass("classeVirarImagem");
                setTimeout(function () {
                    $("#linha" + pDados._id).removeClass("descerLinhaTemplate");
                }, 500);
            }
        };
        $scope.mostrarTerceiraLinha = function (pDados) {
            _templateSelecionado = pDados;
            _filtrosEstatisticas = {
                objectId: _templateSelecionado.objectIdTemplate,
            };
            if (_templateSelecionado.tempSelecionado == "Selecionada") {
                _templateSelecionado.tempSelecionado = "";
                $("#linha" + pDados.objectIdTemplate).addClass("subirLinhaTemplate");
                $("#img" + pDados.objectIdTemplate).removeClass("classeVirarImagem");
                setTimeout(function () {
                    $("#linha" + pDados.objectIdTemplate)
                        .removeClass("subirLinhaTemplate")
                        .addClass("classeInvisivelCad");
                }, 300);
            }
            else {
                _templateSelecionado.tempSelecionado = "Selecionada";
                $("#linha" + pDados.objectIdTemplate)
                    .removeClass("classeInvisivelCad")
                    .addClass("descerLinhaTemplate");
                $("#img" + pDados.objectIdTemplate).addClass("classeVirarImagem");
                setTimeout(function () {
                    $("#linha" + pDados.objectIdTemplate).removeClass("descerLinhaTemplate");
                }, 500);
                if (_templateSelecionado.controleInfos == 0)
                    $scope.consultarTemplatesEstatisticas(_filtrosEstatisticas, function () { });
            }
        };
        $scope.abrirOrdenarTemplates = function () {
            $scope.ordenarTempAberto = !$scope.ordenarTempAberto;
            if ($scope.ordenarTempAberto) {
                $("#idOrdenarTemp").show();
            }
            else
                $("#idOrdenarTemp").hide();
        };
        $scope.ordenarTemplates = function (pDados) {
            if (pDados) {
                _campoSelecionadoOrdenacao = pDados;
                $scope.trocarOrdem = false;
                if ($scope.reverse == undefined || $scope.reverse == null) {
                    if (pDados == "nomePasta") {
                        document.getElementById("idTxtOrdenarTemplate").innerText = "A-Z";
                        document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText = "Z-A";
                        document.getElementById("txtOrdenacaoDataTemplate").innerText =
                            "Data";
                        $scope.ordenacoTemp = "Crescente";
                        $scope.reverse = false;
                    }
                    else {
                        document.getElementById("idTxtOrdenarTemplate").innerText =
                            "Antigos primeiro";
                        document.getElementById("txtOrdenacaoDataTemplate").innerText =
                            "Novos";
                        document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText = "A-Z";
                        $scope.ordenacoTemp = "Crescente";
                        $scope.reverse = false;
                    }
                }
                else {
                    if (pDados == "nomePasta") {
                        if (document.getElementById("txtOrdenacaoAlfabeticaTemplate")
                            .innerText == "Z-A") {
                            document.getElementById("idTxtOrdenarTemplate").innerText = "Z-A";
                            document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText = "A-Z";
                            $scope.ordenacoTemp = "Decrescente";
                            $scope.reverse = true;
                        }
                        else {
                            document.getElementById("idTxtOrdenarTemplate").innerText = "A-Z";
                            document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText = "Z-A";
                            $scope.ordenacoTemp = "Crescente";
                            $scope.reverse = false;
                        }
                        document.getElementById("txtOrdenacaoDataTemplate").innerText =
                            "Data";
                    }
                    else if (pDados == "_id") {
                        if (document.getElementById("txtOrdenacaoDataTemplate").innerText ==
                            "Novos") {
                            document.getElementById("idTxtOrdenarTemplate").innerText =
                                "Novos primeiro";
                            document.getElementById("txtOrdenacaoDataTemplate").innerText =
                                "Antigos";
                            $scope.ordenacoTemp = "Decrescente";
                            $scope.reverse = true;
                        }
                        else {
                            document.getElementById("idTxtOrdenarTemplate").innerText =
                                "Antigos primeiro";
                            document.getElementById("txtOrdenacaoDataTemplate").innerText =
                                "Novos";
                            $scope.ordenacoTemp = "Crescente";
                            $scope.reverse = false;
                        }
                        document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText = "A-Z";
                    }
                }
                $scope.abrirOrdenarTemplates();
                setTimeout(function () {
                    $scope.$apply();
                }, 100);
            }
            $scope.propertyName = pDados ? pDados : "A-Z";
            $scope.propertyNameTemplate =
                pDados == "nomePasta"
                    ? "template.templateNome"
                    : "template.objectIdTemplate";
        };
        $scope.alterarOrdenacaoTemplates = function () {
            $scope.ordenarTemplates(_campoSelecionadoOrdenacao);
            $scope.abrirOrdenarTemplates();
        };
        $(document).mouseup(function (e) {
            var divOrdenar = $("#idOrdenarTemp");
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarTempAberto = false;
                divOrdenar.hide();
            }
        });
        $scope.zerarOrdenacao = function () {
            $scope.trocarOrdem = true;
            document.getElementById("idTxtOrdenarTemplate").innerText = "Ordenar";
            document.getElementById("txtOrdenacaoAlfabeticaTemplate").innerText =
                "A-Z";
            document.getElementById("txtOrdenacaoDataTemplate").innerText = "Data";
            $scope.reverse = false;
        };
        $scope.abrirFiltroPastaTemp = function () {
            filtrarTemplateNovo();
            $scope.filtroPastaTempAberto = !$scope.filtroPastaTempAberto;
            if ($scope.filtroPastaTempAberto) {
                $("#idFiltroPastaTemp").show();
                $("#idListaPastaTemp").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("idPastaFiltroTemp").focus();
                }, 10);
            }
            else
                $("#idFiltroPastaTemp").hide();
        };
        $scope.selecionarPastaTemp = function (pDados) {
            if (pDados || pDados != null) {
                $scope.pastaFiltro = pDados._id;
                document.getElementById("idPastaTempFiltro").innerText =
                    pDados.nomePasta;
            }
            else {
                $scope.pastaFiltro = null;
                document.getElementById("idPastaTempFiltro").innerText = "Sem pasta";
            }
            $scope.nomePastaFiltroTemp = pDados.nomePasta;
            $scope.abrirFiltroPastaTemp();
        };
        $scope.abrirFiltroUsuariosTemp = function () {
            filtrarTemplateNovo();
            $scope.filtroUsuariosTempAberto = !$scope.filtroUsuariosTempAberto;
            if ($scope.filtroUsuariosTempAberto) {
                $("#idFiltroUsuariosTemp").show();
                $("#idListaUsuTemp").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarUsuariosTemp").focus();
                }, 10);
            }
            else
                $("#idFiltroUsuariosTemp").hide();
        };
        $scope.selecionarUsuarioTemp = function (pDados) {
            $scope.usuarioFiltro = pDados._id;
            document.getElementById("idUsuariosTempFiltro").innerText = pDados.nome;
            $scope.nomeUsuarioFiltroTemp = pDados.nome;
            $scope.filtroUsuariosTempAberto = false;
            $("#idFiltroUsuariosTemp").hide();
            $scope.listaPastasFiltro = [];
            $scope.consultaTemplatesPorPastaFiltro(pDados._id);
            $scope.nomePastaFiltroTemp = "";
            $scope.pastaFiltro = null;
            document.getElementById("idPastaTempFiltro").innerText = "Pasta";
        };
        $scope.consultaTemplatesPorPastaFiltro = function (pDados) {
            $scope.listaPastasFiltro = [];
            var _filtrosUsuario = {};
            if (pDados || $scope.usuarioFiltro) {
                if (pDados)
                    _filtrosUsuario = { objectIdLogin: pDados, tipoDaChave: "U" };
                else
                    _filtrosUsuario = {
                        objectIdLogin: $scope.usuarioFiltro,
                        tipoDaChave: "U",
                    };
            }
            else {
                _filtrosUsuario = { objectIdLogin: "", tipoDaChave: $rootScope.Tipo };
            }
            $http({
                method: "GET",
                url: "/pastas/consultarPastasTemplatesPorUsuario",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectIdLogin: _filtrosUsuario.objectIdLogin,
                    tipoDaChave: _filtrosUsuario.tipoDaChave,
                },
            }).then(function (response) {
                if (response.data.length > 0) {
                    response.data.forEach(function (pastas) {
                        $scope.listaPastasFiltro.push(pastas);
                    });
                }
            });
        };
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = "";
            if ($("#sltDataTemplates")[0].value != "" &&
                $("#sltDataTemplates")[0].value != null) {
                $scope.txtNomeFiltros =
                    "Filtrado por " + $("#sltDataTemplates")[0].value;
                contagem++;
            }
            if ($scope.nomeUsuarioFiltroTemp != "" &&
                $scope.nomeUsuarioFiltroTemp != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomeUsuarioFiltroTemp)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomeUsuarioFiltroTemp);
                contagem++;
            }
            if ($scope.nomePastaFiltroTemp != "" &&
                $scope.nomePastaFiltroTemp != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $scope.nomePastaFiltroTemp)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomePastaFiltroTemp);
                contagem++;
            }
        };
        $scope.recarregarPagina = function () {
            _filtros = {
                buscar: "",
                objectId: "",
                tipoDaChave: "",
                objectIdPastaTemplate: "",
                dataInicio: "",
                dataFim: "",
                campoOrdem: "templateNome",
                ordem: "1",
            };
            $scope.removerFiltros();
            $scope.consultarPastasComTemplates(_filtros);
            $scope.zerarOrdenacao();
            $scope.montarNomeFiltros();
            $("#btnFiltrarTemplate")
                .removeClass()
                .addClass("btnVoltarPadroNovoFiltro");
            $("#idImgFiltrarTemp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
            $scope.filtroTemplateHabilitado = false;
        };
        $scope.abrirPastaDuplicar = function () {
            $scope.pastaDuplicarAberto = !$scope.pastaDuplicarAberto;
            if ($scope.pastaDuplicarAberto) {
                $("#idPastaDuplicar").show();
                $("#idListaPastaDuplicar").animate({ scrollTop: 0 }, 10);
                $scope.buscarPastaDuplicar = "";
                setTimeout(function () {
                    document.getElementById("idInptPastaDuplicar").focus();
                }, 10);
            }
            else
                $("#idPastaDuplicar").hide();
        };
        $scope.selecionarPastaDuplicar = function (pDados) {
            if (pDados || pDados != null) {
                document.getElementById("idSpanPastaDuplicar").innerText =
                    pDados.nomePasta;
                $scope.pastaFiltroDuplicar = pDados._id;
            }
            else {
                $scope.pastaFiltroDuplicar = "";
                document.getElementById("idSpanPastaDuplicar").innerText = "Sem pasta";
            }
            $scope.abrirPastaDuplicar();
        };
        $scope.abrirPastaMover = function () {
            $scope.pastaMoverAberto = !$scope.pastaMoverAberto;
            if ($scope.pastaMoverAberto) {
                $("#idPastaMover").show();
                $("#idListaPastaMover").animate({ scrollTop: 0 }, 10);
                $scope.buscarPastaMover = "";
                setTimeout(function () {
                    document.getElementById("idInptPastaMover").focus();
                }, 10);
            }
            else
                $("#idPastaMover").hide();
        };
        $scope.selecionarPastaMover = function (pDados) {
            if (pDados || pDados != null) {
                document.getElementById("idSpanPastaMover").innerText =
                    pDados.nomePasta;
                $scope.nomePastaMover = pDados.nomePasta;
                $scope.pastaFiltroMover = pDados._id;
            }
            else {
                $scope.pastaFiltroMover = "";
                document.getElementById("idSpanPastaMover").innerText = "Sem pasta";
            }
            $scope.abrirPastaMover();
        };
        $scope.inserirLinkChat = function (chatChecked) {
            if (chatChecked) {
                mixpanel.track("Templates | Inseriu link do whatsapp", {
                    "ID pasta": $("#listaPastasContato")[0].value,
                    "ID template": _conteudoTemplate._id,
                    "Nome template": _conteudoTemplate.nomeTemplate,
                    Local_IP: $rootScope.ip,
                });
                if (!$scope.msgChat.texto) {
                    $scope.msgChat.texto =
                        "Este é um link para falar direto comigo via Whatsapp";
                }
                if ($scope.whatsapp) {
                    var newNode = tinymce.activeEditor.getDoc().createElement("a");
                    newNode.setAttribute("id", "msgChat");
                    newNode.href = "//*whatsapp*/";
                    var range = tinymce.activeEditor.selection.getRng();
                    range.insertNode(newNode);
                    $scope.inserirMsgChat(chatChecked, $scope.msgChat.texto);
                }
            }
            else {
                var child = tinymce.activeEditor.getDoc().getElementById("msgChat");
                if (child) {
                    child.parentNode.removeChild(child);
                }
            }
        };
        $scope.inserirMsgChat = function (chatChecked, texto) {
            if (chatChecked) {
                var child = tinymce.activeEditor.getDoc().getElementById("msgChat");
                if (child === null) {
                    $scope.inserirLinkChat(true);
                    child = tinymce.activeEditor.getDoc().getElementById("msgChat");
                }
                child.textContent = texto;
                $scope.msgChat.texto = texto;
            }
        };
        $scope.getInfoChat = function () {
            var chat = tinymce.activeEditor.getDoc().getElementById("msgChat");
            if (chat) {
                $scope.msgChat.chatChecked = true;
                $scope.msgChat.texto = chat.textContent;
            }
            else {
                $scope.msgChat.chatChecked = false;
                $scope.msgChat.texto = "";
            }
        };
        $scope.inserirLinkAgenda = function (agendaChecked) {
            if (agendaChecked) {
                mixpanel.track("Janela de conversação  | Inseriu link da Agenda Inteligente", {
                    "ID pasta": $("#listaPastasContato")[0].value,
                    "ID template": _conteudoTemplate._id,
                    "Nome template": _conteudoTemplate.nomeTemplate,
                    Local_IP: $rootScope.ip,
                });
                if (!$scope.msgAgenda.texto) {
                    $scope.msgAgenda.texto = "Este é o link da minha agenda";
                }
                var newNode = tinymce.activeEditor.getDoc().createElement("a");
                newNode.setAttribute("id", "msgAgenda");
                newNode.href = "//*agenda*/";
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
                $scope.inserirMsgAgenda(agendaChecked, $scope.msgAgenda.texto);
            }
            else {
                var child = tinymce.activeEditor.getDoc().getElementById("msgAgenda");
                if (child)
                    child.parentNode.removeChild(child);
            }
        };
        $scope.inserirMsgAgenda = function (agendaChecked, texto) {
            if (agendaChecked) {
                var child = tinymce.activeEditor.getDoc().getElementById("msgAgenda");
                if (child === null) {
                    $scope.inserirLinkAgenda(true);
                    child = tinymce.activeEditor.getDoc().getElementById("msgAgenda");
                }
                child.textContent = texto;
                $scope.msgAgenda.texto = texto;
            }
        };
        $scope.getInfoAgenda = function () {
            var agenda = tinymce.activeEditor.getDoc().getElementById("msgAgenda");
            if (agenda) {
                $scope.msgAgenda.agendaChecked = true;
                $scope.msgAgenda.texto = agenda.textContent;
            }
            else {
                $scope.msgAgenda.agendaChecked = false;
                $scope.msgAgenda.texto = "";
            }
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
    },
]);
